import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import LancamentoService from '../lancamento-service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { take } from 'rxjs';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';
import { Lancamento } from '../models/lancamento';
import { PlanoConta } from '../../planoconta/models/planoconta';
import { SocioResumo } from '../../associados/models/associados';
import PlanoContaService from '../../planoconta/planoconta-service';
import { AssociadosService } from '../../associados/associados.service';
import { TipoLancamento } from '../models/tipoLancamento';


@Component({
  selector: 'app-lancamento-edicao',
  templateUrl: './lancamento-edicao.component.html',
  styleUrls: ['./lancamento-edicao.component.css'],
})
export class LancamentoEdicaoComponent implements OnInit {
  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  descricaoPlanoConta: string = '';
  form: FormGroup;
  dataLancamento: Date;
  valorLancamento: string = '';
  lancamento: Lancamento;
  listaPlanoConta: PlanoConta[] = [];
  planoContaOriginal: PlanoConta[] = [];
  planoContaArray: {}[];
  complemento: string = '';
  numeroLancamento: number;
  numeroParcelas: number;
  recebeuBoleto: boolean = false;
  compensado: boolean = false;
  estoqueAntigo: boolean = false;
  listaFornecedorOriginal: SocioResumo[] = [];
  listaAssociadoOriginal: SocioResumo[] = [];
  listaTipoLancamentoOriginal: TipoLancamento[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private lancamentoService: LancamentoService,
    private planoContaService: PlanoContaService,
    private associadosService: AssociadosService,
    private utilsservice: UtilsService
  ) {}

  configPlanoConta = {
    search: true,
    placeholder: 'Selecione o plano de conta', //TROCA MENSAGEM
    noResultsFound: 'Nenhum registro encontrado!',
    searchPlaceholder: 'Pesquise...',
    moreText: 'Mais...',
    displayFn:(item: any) => { return item.CodigoPlanoConta + ' - ' + item.DescricaoPlanoConta; },
    defaultValue:'IdPlanoConta',
    height: '220px',
    limitTo: 0,
    searchOnKey: 'DescricaoSelect',
    
  };

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.spinner.show();
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.BuscaAssociado();
    this.BuscaPlanoConta();  
    this.BuscaFornecedor();
    this.BuscaTipoLancamento();
    const { lancamento } = window.history.state;
    this.lancamento = lancamento;
    this.recebeuBoleto = lancamento.RecebeuBoleto === 'S' ? true : false;
    this.compensado = lancamento.Compensado === 'Sim' ? true : false;
    this.estoqueAntigo = lancamento.Estoque;
    this.criarForm();
    this.spinner.hide();

  }
  BuscaPlanoConta(): void {
    try {
      this.planoContaService
        .GetListaGeral()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                response.PlanoContaLista.forEach((item: any) => {
                    const tempList = new PlanoConta();
                    tempList.carregar(item);
                    this.planoContaOriginal.push(tempList);
                  });

            }
          },
          error: (error) => {
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.utilsservice.showHttpError(error);
    }
  }

  BuscaAssociado(): void {
    try {
      this.associadosService
        .ListaTodosSocios()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                response.SociosResumo.forEach((item: any) => {
                    const tempList = new SocioResumo();
                    tempList.carregar(item);
                    this.listaAssociadoOriginal.push(tempList);
                  });

            }
          },
          error: (error) => {
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.utilsservice.showHttpError(error);
    }
  }

  BuscaFornecedor(): void {
    try {
      this.associadosService
        .ListaFornecedores()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                response.SociosResumo.forEach((item: any) => {
                    const tempList = new SocioResumo();
                    tempList.carregar(item);
                    this.listaFornecedorOriginal.push(tempList);
                  });

            }
          },
          error: (error) => {
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.utilsservice.showHttpError(error);
    }
  }

  BuscaTipoLancamento(): void {
    try {
      this.lancamentoService
        .GetTipoLancamento()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                response.TipoLancamentoLista.forEach((item: any) => {
                    const tempList = new TipoLancamento();
                    tempList.carregar(item);
                    this.listaTipoLancamentoOriginal.push(tempList);
                  });

            }
          },
          error: (error) => {
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.utilsservice.showHttpError(error);
    }
  }

  SalvaLancamento(): void {
    let requestLancamento: Lancamento = new Lancamento();
    requestLancamento.CodigoLancamento = this.lancamento.CodigoLancamento;
    requestLancamento.CodigoPlanoConta = this.form.get("formContaPlano")?.value;
    requestLancamento.CodigoFornecedor = this.form.get("formFornecedor")?.value;
    requestLancamento.CodigoSocioLancamento = this.form.get("formAssociado")?.value;
    requestLancamento.CodigoTipoLancamento = this.form.get("formTipoLancamento")?.value;
    requestLancamento.DescExtraPlanoconta = this.form.get("formComplemento")?.value;
    requestLancamento.DataLancamento = this.lancamento.DataLancamento;
    requestLancamento.ValorLancamento = this.form.get("formValorLancamento")?.value;
    requestLancamento.NroCheque = this.form.get("formNumeroLancamento")?.value;
    requestLancamento.OrigemLancamento = 'LA';
    requestLancamento.Compensado = this.compensado === true ? 'Sim' : 'Não';
    requestLancamento.RecebeuBoleto = this.recebeuBoleto === true ? 'S' : 'N';
    requestLancamento.Estoque = this.estoqueAntigo;

    if (!requestLancamento.CodigoPlanoConta) 
        this.messageService.showAlertDanger("Plano de Contas deve ser informado.");     
    
    else if (!requestLancamento.CodigoFornecedor && !requestLancamento.CodigoSocioLancamento) 
        this.messageService.showAlertDanger("Fornecedor ou Associado precisa ser informado.");     
    
    else if (!requestLancamento.DataLancamento) 
      this.messageService.showAlertDanger("Data precisa ser informado.");  

    else if (!requestLancamento.ValorLancamento) 
      this.messageService.showAlertDanger("Valor precisa ser informado.");  

    else if (!requestLancamento.CodigoTipoLancamento) 
      this.messageService.showAlertDanger("Tipo Lançamento precisa ser informado.");  

    else if (!Lancamento.saoDiferentes(requestLancamento, this.lancamento)) {
        this.messageService.showAlertDanger("Nenhum campo novo detectado para alteração.");     
        }

    else {
      this.spinner.show();
      try {
        this.lancamentoService
          .SalvaLancamento(
            requestLancamento
          )
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              this.spinner.hide();
              if (response.Error) {
                this.messageService.showAlertDanger(response.ErrorMessage);
              } else {
                this.voltar();
              }
            },
            error: (error) => {
              this.spinner.hide();
              this.utilsservice.showHttpError(error);
            },
          });
      } catch (error) {
        this.spinner.hide();
        this.utilsservice.showHttpError(error);
      }
    }
  }

  AlteraData($event: any, data: string)
  {
    this.lancamento.DataLancamento = new Date($event);
  }

  toggleRecebeuBoleto() {
    this.recebeuBoleto = !this.recebeuBoleto;
 }   
  toggleCompensado() {
    this.compensado = !this.compensado;
  }   
  toggleEstoqueAntigo() {
    this.estoqueAntigo = !this.estoqueAntigo;
  }   
  criarForm() {
    const plano = new PlanoConta
    this.planoContaArray = plano.toDict(this.planoContaOriginal)
    this.form = this.formBuilder.group({
      formDataLancamento: [this.lancamento.DataLancamento, Validators.required],
      formValorLancamento: [this.lancamento.ValorLancamento || '', [Validators.maxLength(255)]],
      formContaPlano: [this.lancamento.CodigoPlanoConta],
      formFornecedor: [this.lancamento.CodigoFornecedor],
      formTipoLancamento: [{value:this.lancamento.CodigoTipoLancamento, disabled:this.lancamento.CodigoLancamento > 0}],
      formAssociado: [this.lancamento.CodigoSocioLancamento],
      formComplemento: [this.lancamento.DescExtraPlanoconta],
      formNumeroLancamento: [this.lancamento.NroCheque],
      formNumeroParcela: [{value:1, disabled:this.lancamento.CodigoLancamento > 0}],
      formRecebeuBoleto: [this.recebeuBoleto],
      formCompensado: [this.compensado],
      formEstoqueAntigo: [this.estoqueAntigo],

    });
}

voltar() {
    this.router.navigateByUrl('/administrativo/lancamentos');
  }
}
