<div class="content-wrapper">
  <div class="container-fluid">

      <div class="card-body-form">
        <div class="table-responsive table-responsive-overflow-450">
          <form [formGroup]="form">

            <div class="row">
              <span class="w-100 card-title-form-menor-FB" style="margin-left: 0.8%;">Identificação</span>
              <div class="col-lg-4">
                <label class="form-label">Nome</label>
                <input type="text" class="form-control form-control-lg"
                (change)="changeNome();" maxlength="80"
                name="NomeSocio" id="NomeSocio" formControlName="NomeSocio" [value]="form.get('NomeSocio')" />
              </div>
              <div class="col-lg-2">
                <label class="form-label" style="width: 100%;">Nascimento</label>
                <input style="display: block"  class="form-control form-control-lg" bsDatepicker type="text"
                (bsValueChange)="AlteraData($event , 'DataNascimentoSocio');"
                [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false }"
                name="DataNascimentoSocio" id="DataNascimentoSocio" formControlName="DataNascimentoSocio" [value]="form.get('DataNascimentoSocio')">
              </div>
              <span class="col-lg-6 row">
                <div class="col-lg-2">
                  <label class="form-label">Ativo?</label>
                  <span class="d-block"> <ui-switch name="AtivoSocio" id="AtivoSocio" formControlName="AtivoSocio"
                    (change)="changeAtivo($event);"></ui-switch></span>
                </div>
                <div class="col-lg-2">
                  <label class="form-label">Desconto Sala?</label>
                  <span class="d-block"> <ui-switch name="DescontoSalaSocio" id="DescontoSalaSocio" formControlName="DescontoSalaSocio"
                    (change)="changeDesconto($event);" ></ui-switch></span>
                </div>
                <div class="col-lg-2">
                  <label class="form-label">Bolsista?</label>
                  <span class="d-block"> <ui-switch name="BolsistaSocio" id="BolsistaSocio" formControlName="BolsistaSocio"
                    (change)="changeBolsista($event);" ></ui-switch></span>
                </div>
                <div class="col-lg-2">
                  <label class="form-label">Bolsa Suspensa?</label>
                  <span class="d-block"> <ui-switch name="BolsaSuspensaSocio" id="BolsaSuspensaSocio" formControlName="BolsaSuspensaSocio"
                    (change)="changeBolsaSuspensa($event);" ></ui-switch></span>
                </div>
                <div class="col-lg-3">
                  <label class="form-label">Ações Afirmativas?</label>
                  <span class="d-block"> <ui-switch name="AcoesAfirmativasSocio" id="AcoesAfirmativasSocio" formControlName="AcoesAfirmativasSocio"
                    (change)="changeAcoesAfirmativas($event);" ></ui-switch></span>
                </div>
              </span>
            </div>

            <div class="row top02">
              <span class="col-lg-3">
                <label class="form-label">Categoria</label>
                <app-categoria-pesquisa
                  (CategoriaOut)="changeCategoria($event);" [CodigoCategoriaPreench]="this.associado.CodigoCategoria">
                </app-categoria-pesquisa>
              </span>
              <span class="col-lg-3">
                <label class="form-label">Categoria Extra Profissionais/Estudantes</label>
                <app-categoria-pesquisa
                  (CategoriaOut)="changeCategoria2($event);" [CodigoCategoriaPreench]="this.associado.CodigoCategoria2">
                </app-categoria-pesquisa>
              </span>
              <span class="col-lg-3">
                <label class="form-label">Área Atuação</label>
                <app-area-atuacao-pesquisa
                  name="CodigoAreaAtuacao" id="CodigoAreaAtuacao" formControlName="CodigoAreaAtuacao"
                  (AreaAtuacaoOut)="changeArea($event);" [CodigoAreaAtuacaoPreench]="this.associado.CodigoAreaAtuacao">
                </app-area-atuacao-pesquisa>
              </span>
              <span class="col-lg-3">
                <label class="form-label" style="width: 100%;">Perfil</label>
                <ngx-select-dropdown name="PerfilSocioDescr" id="PerfilSocioDescr" formControlName="PerfilSocioDescr"
                  (change)="changePerfil($event)"
                  [config]="configPerfil" [options]="Perfil" class="form_control_ngx_select_dropdown">
                </ngx-select-dropdown>
              </span>
            </div>

            <div class="row top02">
              <span class="col-lg-2">
                <label class="form-label">Inscrição CRP:</label>
                <input type="text" class="form-control form-control-lg" name="NrInscrProfSocio" id="NrInscrProfSocio" formControlName="NrInscrProfSocio" [value]="form.get('NrInscrProfSocio')"
                maxlength="50" (change)="changeNI();">
              </span>
              <span class="col-lg-2">
                <label class="form-label">CPF/CNPJ:</label>
                <input type="text" class="form-control form-control-lg" mask="CPF_CNPJ"  name="CPF_CNPJSocio" id="CPF_CNPJSocio" formControlName="CPF_CNPJSocio" [value]="form.get('CPF_CNPJSocio')"
                maxlength="20" (change)="changeCPFCNPJ();">
              </span>
              <span class="col-lg-2">
                <label class="form-label">RG:</label>
                <input type="text" class="form-control form-control-lg" mask="0000000000"  name="RGSocio" id="RGSocio" formControlName="RGSocio" [value]="form.get('RGSocio')"
                maxlength="20" (change)="changeRG();">
              </span>
              <span class="col-lg-2">
                <label class="form-label" for="ingresso" style="width: 100%;">Ingresso Formação:</label>
                <input type="text" class="form-control form-control-lg" mask="00/0000"  name="IngressoFormacaoSocio" id="IngressoFormacaoSocio" formControlName="IngressoFormacaoSocio" [value]="form.get('IngressoFormacaoSocio')"
                (change)="changeIngressoFormacaoSocio();">
              </span>
              <span class="col-lg-2">
                <label class="form-label" for="DataAfastIni" style="width: 100%;">Data Desligamento:</label>
                <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text"
                (bsValueChange)="AlteraData($event , 'DataAfastIni');"
                [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false, showTodayButton: true, todayPosition: 'center', todayButtonLabel: 'Hoje' }" [value]="form.get('DataAfastIni')"
                name="DataAfastIni" id="DataAfastIni" formControlName="DataAfastIni">
              </span>
              <span class="col-lg-2">
                <label class="form-label" for="DataAfastFim" style="width: 100%;">Data Retorno:</label>
                <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text"
                (bsValueChange)="AlteraData($event , 'DataAfastFim');"
                [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false, showTodayButton: true, todayPosition: 'center', todayButtonLabel: 'Hoje' }" [value]="form.get('DataAfastFim')"
                name="DataAfastFim" id="DataAfastFim" formControlName="DataAfastFim">
              </span>
            </div>

            <div class="row top1">
              <span class="w-100 card-title-form-menor-FB" style="margin-left: 0.8%;">Contatos Principais</span>
                <span class="col-lg-3">
                  <label class="form-label">E-mail Principal:</label>
                  <input type="text" class="form-control form-control-lg"  name="EmailSocio" id="EmailSocio" formControlName="EmailSocio" [value]="form.get('EmailSocio')"
                  maxlength="100" (change)="validarEmail($event,'P');">
                </span>
                <span class="col-lg-3">
                  <label class="form-label">E-mail Alternativo:</label>
                  <input type="text" class="form-control form-control-lg"  name="EmailAltSocio" id="EmailAltSocio" formControlName="EmailAltSocio" [value]="form.get('EmailAltSocio')"
                  maxlength="100" (change)="validarEmail($event,'A');">
                </span>
                <span class="col-lg-2">
                  <label class="form-label">Celular/Fone 1:</label>
                  <input type="text" class="form-control form-control-lg" mask="(00) 00000-0000"  name="Telefone1Socio" id="Telefone1Socio" formControlName="Telefone1Socio" [value]="form.get('Telefone1Socio')"
                  maxlength="15" (change)="changeFone1();">
                </span>
                <span class="col-lg-2">
                  <label class="form-label">Celular/Fone 2:</label>
                  <input type="text" class="form-control form-control-lg" mask="(00) 00000-0000"  name="Telefone2Socio" id="Telefone2Socio" formControlName="Telefone2Socio" [value]="form.get('Telefone2Socio')"
                  maxlength="15" (change)="changeFone2()">
                </span>
                <span class="col-lg-2">
                  <label class="form-label">Celular/Fone 3:</label>
                  <input type="text" class="form-control form-control-lg" mask="(00) 00000-0000"   name="Telefone3Socio" id="Telefone3Socio" formControlName="Telefone3Socio" [value]="form.get('Telefone3Socio')"
                  maxlength="15" (change)="changeFone3()">
                </span>
            </div>

            <div class="row top1">
                <div class="row">
                  <div class="col-lg-6"><span class="card-title-form-menor-FB">Contato Emergencial:</span></div>
                  <div class="col-lg-6"><span class="card-title-form-menor-FB" style="margin-left: 0.8%;">Observações:</span></div>
                </div>
                <div class="col-lg-6 row">
                  <span class="col-lg-8">
                    <label class="form-label">Pessoa:</label>
                    <input type="text" class="form-control form-control-lg"  name="NomeEmerSocio" id="NomeEmerSocio" formControlName="NomeEmerSocio" [value]="form.get('NomeEmerSocio')"
                    maxlength="80" (change)="changeNomeEmer();">
                  </span>
                  <span class="col-lg-4">
                    <label class="form-label">Telefone:</label>
                    <input type="text" class="form-control form-control-lg" mask="(00) 00000-0000"  name="TelefoneEmerSocio" id="TelefoneEmerSocio" formControlName="TelefoneEmerSocio" [value]="form.get('TelefoneEmerSocio')"
                    maxlength="15" (change)="changeFoneEmer();">
                  </span>
                  <span class="w-100 card-title-form-menor-FB top1" style="margin-left: 1.5%;">Acesso Sistema</span>
                  <span class="col-lg-3">
                    <label class="form-label">Usuário:</label>
                    <input type="text" class="form-control form-control-lg"  name="UsuarioSocio" id="UsuarioSocio" formControlName="UsuarioSocio" [value]="form.get('UsuarioSocio')"
                    maxlength="30" (change)="changeUsuario();">
                  </span>
                  <span class="col-lg-3">
                    <label class="form-label">Senha Atual:</label>
                    <input type="password" class="form-control form-control-lg"  name="SenhaSocio" id="SenhaSocio" formControlName="SenhaSocio" [value]="form.get('SenhaSocio')"
                    maxlength="32" (change)="changeSenha();">
                  </span>
                  <span class="col-lg-3">
                    <label class="form-label">Senha Nova:</label>
                    <input type="password" [disabled]="associado.CodigoSocio == -1" class="form-control form-control-lg"  name="NovaSenhaSocio" id="NovaSenhaSocio" formControlName="NovaSenhaSocio" [value]="form.get('NovaSenhaSocio')"
                    maxlength="32" (change)="changeNovaSenha();">
                  </span>
                  <span class="col-lg-3">
                    <label class="form-label">Confirma Senha Nova:</label>
                    <input type="password" [disabled]="associado.CodigoSocio == -1" class="form-control form-control-lg"  name="ConfirmaSenhaSocio" id="ConfirmaSenhaSocio" formControlName="ConfirmaSenhaSocio" [value]="form.get('ConfirmaSenhaSocio')"
                    maxlength="32" (change)="changeConfirmaSenha();">
                  </span>
                </div>
                <div class="col-lg-6" style="margin-left: 24px;">
                  <span class="w-100">
                      <label class="form-label">Observações diversas:</label>
                      <textarea class="form-control form-control-lg" rows="7" name="ObservacoesSocio" id="ObservacoesSocio" formControlName="ObservacoesSocio" [value]="form.get('ObservacoesSocio')"
                      maxlength="1500" (change)="changeObs();"></textarea>
                  </span>
                </div>
            </div>

          </form>
        </div>
      </div>

  </div>
</div>
