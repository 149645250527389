<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-lg-6">
          <div class="card-title-form">Área de Atuação
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
              [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
              aria-controls="offcanvasExample">
              <i class="fa-solid fa-circle-question"></i>
            </span>
          </div>

          <div class="card-body-form">
            <form [formGroup]="form">
              <div class="row">
                <div class="form-outline">
                  <label class="form-label" for="formNomeAreaAtuacao">Nome da Área de Atuação</label>
                  <input type="text" id="formNomeAreaAtuacao" formControlName="formNomeAreaAtuacao" [value]="form.get('formNomeAreaAtuacao')"
                    name="formNomeAreaAtuacao" class="form-control form-control-lg" maxlength="100" />
                  <app-field-error-message [control]="form.get('formNomeAreaAtuacao')"
                    label="nome da area de atuação"></app-field-error-message>
                </div>
              </div>
              <div class="top1">
                <div class="table-responsive linha-de-botoes">
                  <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
                  <button [disabled]="form.invalid" type="button" class="btn btn-primary"
                  (click)="SalvaAreaAtuacao();">Salvar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="offcanvas offcanvas-top h-50" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="container">
    <div class="row d-flex align-items-center justify-content-center h-100">
      <div class="col-lg-6">
        <div class="offcanvas-header">
          <div class="card-title-form-menor">Área de Atuação
            <button type="button" class="float-end btn-close text-primary customSearchButtons"
              data-bs-dismiss="offcanvas" aria-label="Fechar" containerClass="customClass" tooltip="Fechar Help"
              placement="bottom" [adaptivePosition]="false"></button>
          </div>
        </div>
        <div class="offcanvas-body">

          <div class="row">
            <div class="form-outline">
              <label class="form-label">Nome da Área de Atuação</label>
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Necessário informar o nome da área de atuação (máximo 100 caracteres)." />
            </div>
          </div>
          <div class="w-100 top1"><button type="button" class="btn btn-warning">Cancelar</button><label
              style="margin-left: 4px; margin-right: 1%;">Retorna para tela de lista de áreas de atuação.</label></div>
          <div class="w-100 top1"><button type="button" class="btn btn-primary">Salvar</button><label
              style="margin-left: 4px; margin-right: 1%;">Salva a área de atuação selecionada.</label></div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
