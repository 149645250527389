<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-lg-6">
          <div class="card-title-form">Edição de Sala
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
              [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
              aria-controls="offcanvasExample">
              <i class="fa-solid fa-circle-question"></i>
            </span>
          </div>

          <div class="card-body-form">
            <form [formGroup]="form">
              <div class="row">
                <div class="form-outline">
                  <label class="form-label" for="formNomeSala">Descrição</label>
                  <input type="text" id="formNomeSala" formControlName="formNomeSala" [value]="form.get('formNomeSala')"
                    name="formNomeSala" class="form-control form-control-lg" maxlength="50"/>
                  <app-field-error-message [control]="form.get('formNomeSala')"
                    label="nome da sala"></app-field-error-message>
                </div>
              </div>
              <div class="row">
                <div class="form-outline">
                  <label class="form-label" for="formtaxaSala">Valor</label>
                  <input type="text" id="formTaxaSala" formControlName="formTaxaSala" [value]="form.get('formTaxaSala')"
                    name="formTaxaSala" class="form-control form-control-lg" currencyMask />
                  <app-field-error-message [control]="form.get('formTaxaSala')"
                    label="taxa da sala"></app-field-error-message>
                </div>
              </div>
              <div class="top1">
                <div class="table-responsive linha-de-botoes">
                  <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
                  <button [disabled]="form.invalid" type="button" class="btn btn-primary"
                    (click)="SalvaSala(false);">Salvar</button>
                  <button [disabled]="form.invalid" type="button" class="btn btn-primary"
                    (click)="SalvaSala(true);">Salvar
                    Valor Todas
                    Salas</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="offcanvas offcanvas-top h-75" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="container">
    <div class="row d-flex align-items-center justify-content-center h-100">
      <div class="col-lg-6">
        <div class="offcanvas-header">
          <div class="card-title-form-menor">Edição de Sala
            <button type="button" class="float-end btn-close text-primary customSearchButtons"
              data-bs-dismiss="offcanvas" aria-label="Fechar" containerClass="customClass" tooltip="Fechar Help"
              placement="bottom" [adaptivePosition]="false"></button>
          </div>
        </div>
        <div class="offcanvas-body">

          <div class="row">
            <div class="form-outline">
              <label class="form-label">Descrição</label>
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Necessário informar o nome da sala (máximo 50 caracteres)." />
            </div>
          </div>
          <div class="row">
            <div class="form-outline">
              <label class="form-label">Valor</label>
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Informar o valor de aluguel da sala ou zeros caso não seja cobrada." />
            </div>
          </div>
          <div class="w-100 top1"><button type="button" class="btn btn-warning">Cancelar</button><label
              style="margin-left: 4px; margin-right: 1%;">Retorna para tela de lista de salas.</label></div>
          <div class="w-100 top1"><button type="button" class="btn btn-primary">Salvar</button><label
              style="margin-left: 4px; margin-right: 1%;">Salva apenas a tela selecionada.</label></div>
          <div class="w-100 top1"><button type="button" class="btn btn-primary">Salvar Valor Todas
              Salas</button><label aria-describedby="btnSalvarTodas" style="margin-left: 4px;">Salva a
              descrição apenas na tela selecionada e o valor do aluguel para todas as salas.</label></div>

        </div>
      </div>
    </div>
  </div>
</div>


<app-footer></app-footer>
