<app-header></app-header>

<div class="content-wrapper">
    <div class="container-fluid">
        <div class="container">
            <div class="row d-flex align-items-center justify-content-center h-100">
                <div class="col-lg-12">
                    <div class="card-title-form">Orçamentos
                        <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Adicionar"
                            placement="top" [adaptivePosition]="false">
                            <button type="button" class="btn btn-sm btn-warning float-end" (click)="Adicionar();">
                                <i class="fa-solid fa-plus icone-para-botao"></i>Adicionar</button>
                        </span>
                    </div>

                    <div style="margin-top: 0.5%;margin-bottom: 0.5%;" class="row w-100">
                        <span class="col-lg-8">
                          <input type="text" #search id="search" class="form-control form-control-lg "
                            placeholder="Pesquisar Plano de Contas e Complemento na listagem abaixo..."
                            (keyup)="updateFilter($event)" />
                        </span>

                        <span style="margin-top: 0.5%;margin-bottom: 0.5%;" class="col-lg-4">
                          <button type="button" class="btn btn-sm btn-warning float-end" containerClass="customClass" tooltip="Exporta para o Excel os dados consultados abaixo." placement="top"
                          [adaptivePosition]="false" (click)="generateExcell();">
                          <i class="fa-solid fa-file-excel icone-para-botao"></i>Exportar Excel</button>
                        </span>
                      </div>
                    <div class="card-body-form">
                        <ngx-datatable #myTable class="w-100 bootstrap ngx-datatable" [rows]="orcamento"
                            [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50"
                            rowHeight="auto" [scrollbarH]="false" [scrollbarV]="false" [limit]="8"
                            [messages]="my_messages" [sorts]="[{prop: 'Data', dir: 'desc'}]">

                            <ngx-datatable-column prop="PlanoConta" [draggable]="false" [flexGrow]="4">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Plano de Contas</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span containerClass="customClass" tooltip="{{row.PlanoConta.DescricaoPlanoConta}}"
                                        placement="auto" [adaptivePosition]="false">{{row.PlanoConta.DescricaoPlanoConta}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="Complemento" [draggable]="false" [flexGrow]="4">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Complemento</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span containerClass="customClass" tooltip="{{row.Complemento}}"
                                        placement="auto" [adaptivePosition]="false">{{row.Complemento}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="Fornecedor1" [draggable]="false" [flexGrow]="4">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Fornecedor 1</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span containerClass="customClass" tooltip="{{row.Fornecedor1.NomeSocio}}"
                                        placement="auto" [adaptivePosition]="false">{{row.Fornecedor1.NomeSocio}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="Fornecedor2" [draggable]="false" [flexGrow]="4">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Fornecedor 2</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span containerClass="customClass" tooltip="{{row.Fornecedor2.NomeSocio}}"
                                        placement="auto" [adaptivePosition]="false">{{row.Fornecedor2.NomeSocio}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="Fornecedor3" [draggable]="false" [flexGrow]="4">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Fornecedor 3</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span containerClass="customClass" tooltip="{{row.Fornecedor3.NomeSocio}}"
                                        placement="auto" [adaptivePosition]="false">{{row.Fornecedor3.NomeSocio}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="Data" [draggable]="false" [flexGrow]="3">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Data</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span containerClass="customClass" tooltip="{{row.converterData()}}"
                                        placement="auto" [adaptivePosition]="false">{{ row.converterData() }}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="Valor" [draggable]="false" [flexGrow]="3">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Valor</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span containerClass="customClass" tooltip="{{row.converterValor(row.Valor)}}"
                                        placement="auto" [adaptivePosition]="false">{{row.converterValor(row.Valor)}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Ações" [draggable]="false" [flexGrow]="3" [sortable]="false" [cellClass]="'ngx-direita'" [headerClass]="'ngx-direita'">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Ações</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span class="iconeTamanho" containerClass="customClass" tooltip="Edição"
                                        placement="top right" [adaptivePosition]="false" (click)="Edicao(row);">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </span>
                                    <span class="iconeTamanho" containerClass="customClass" tooltip="Impressão"
                                        placement="top right" [adaptivePosition]="false" (click)="Imprimir(row);">
                                        <i class="fa-solid fa-print"></i> <!-- Usei um ícone de impressão mais adequado -->
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>

                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
