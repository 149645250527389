<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="align-items-center justify-content-center h-100">

          <div class="card-title-form">Disponibilidade de Atendimento de {{ SocioDisponibilidade.NomeSocio }}
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
              [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
              aria-controls="offcanvasExample">
              <i class="fa-solid fa-circle-question"></i>
            </span>
          </div>

          <div class="card-body-form">
            <div class="table-responsive table-responsive-overflow-450">
              <form [formGroup]="form">
                <div class="row">
                  <div class="col-lg-12">
                    <span class="d-block"  id="s-nome"><label class="form-label">Supervisão:&nbsp;&nbsp;&nbsp;{{ SocioDisponibilidade.Supervisor }}</label></span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <span class="d-block"  id="s-nome"><label class="form-label">Atendimentos Em Andamento:&nbsp;&nbsp;&nbsp;Adultos Presenciais: {{ SocioDisponibilidade.TotalAdulto }}&nbsp;&nbsp;&nbsp;&nbsp;Crianças Presenciais: {{ SocioDisponibilidade.TotalCrianca }}&nbsp;&nbsp;&nbsp;&nbsp;Adultos On Line: {{ SocioDisponibilidade.TotalOnlineAdulto }}&nbsp;&nbsp;&nbsp;&nbsp;Crianças On Line: {{ SocioDisponibilidade.TotalOnlineCrianca }} </label></span>
                  </div>
                </div>
                <div class="row top1">
                  <span class="col-lg-2">
                    <label class="form-label" style="width: 100%;">Perfil</label>
                    <ngx-select-dropdown name="PerfilSocioDescr" id="PerfilSocioDescr" formControlName="PerfilSocioDescr"
                      (change)="changePerfil($event)" [(ngModel)]="SocioDisponibilidade.PerfilSocioDescr"
                      [config]="configPerfil" [options]="Perfil" class="form_control_ngx_select_dropdown">
                    </ngx-select-dropdown>
                  </span>
                  <span class="col-lg-1">
                    <label class="form-label">Disponível?</label>
                    <span class="d-block"> <ui-switch name="DisponivelSocio" id="DisponivelSocio" formControlName="DisponivelSocio"
                      (change)="changeDisponibilidade($event);" ></ui-switch></span>
                  </span>
                  <span class="col-lg-3" style="padding-left: 2%;">
                    <label class="form-label">Tipo Atendimento:</label>
                    <ngx-select-dropdown name="DescrTipoAtendimentoSocio" id="DescrTipoAtendimentoSocio" formControlName="DescrTipoAtendimentoSocio"
                      (change)="changeTipoAtendimentoSocio($event)"  [(ngModel)]="SocioDisponibilidade.DescrTipoAtendimentoSocio"
                      [config]="configTipo" [options]="TipoAtendimento" class="form_control_ngx_select_dropdown">
                    </ngx-select-dropdown>
                  </span>
                  <span class="col-lg-1">
                    <label class="form-label" for="LimiteAtendimentoSocio" style="width: 100%;">Adultos:</label>
                    <input id="LimiteAtendimentoSocio" name="LimiteAtendimentoSocio" formControlName="LimiteAtendimentoSocio"  [value]="form.get('LimiteAtendimentoSocio')"
                        (change)="changeLimiteAtendimentoSocio();" min="0"  type="number"  class="form-control form-control-lg">
                  </span>
                  <span class="col-lg-1">
                    <label class="form-label" for="LimiteAtendimentoSocioCri" style="width: 100%;">Crianças:</label>
                    <input id="LimiteAtendimentoSocioCri" name="LimiteAtendimentoSocioCri" formControlName="LimiteAtendimentoSocioCri" [value]="form.get('LimiteAtendimentoSocioCri')"
                        (change)="changeLimiteAtendimentoSocioCri();" min="0" type="number"  class="form-control form-control-lg">
                  </span>
                  <span class="col-lg-2">
                    <label class="form-label" for="desligamento" style="width: 100%;">Data Afastamento:</label>
                    <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text"
                    (bsValueChange)="AlteraData($event , 'DataAfastIni');"
                    [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false, showTodayButton: true, todayPosition: 'center', todayButtonLabel: 'Hoje' }" [value]="form.get('DataAfastIni')"
                    name="DataAfastIni" id="DataAfastIni" formControlName="DataAfastIni">
                  </span>
                  <span class="col-lg-2">
                    <label class="form-label" for="retorno" style="width: 100%;">Data Retorno:</label>
                    <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text"
                    (bsValueChange)="AlteraData($event , 'DataAfastFim');"
                    [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false, showTodayButton: true, todayPosition: 'center', todayButtonLabel: 'Hoje' }" [value]="form.get('DataAfastFim')"
                    name="DataAfastFim" id="DataAfastFim" formControlName="DataAfastFim">
                  </span>
                </div>
                <div class="row top1">
                  <div class="form-outline">
                    <ul class="nav nav-tabs">
                      <li class="nav-item d-flex">
                        <a class="nav-link active" id="S1" data-bs-toggle="tab" role="tab" aria-controls="S1"
                          aria-selected="true" [class.active]="isActiveAba('S1')" (click)="setAba('S1')">Presencial</a>
                      </li>
                      <li class="nav-item d-flex">
                        <a class="nav-link" id="S2" data-bs-toggle="tab" role="tab" aria-controls="S2"
                          aria-selected="true" [class.active]="isActiveAba('S2')" (click)="setAba('S2')">On Line</a>
                      </li>
                    </ul>
                    <ng-container>
                      <div class="tab-content top1" id="myTabContentHelp" [ngSwitch]="aba">
                        <div *ngSwitchCase="'S1'">
                          <ngx-datatable #myTable class="w-100 bootstrap ngx-datatable" [rows]="SocioDisponibilidade.SocioDispResHorarios"
                          [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                          [scrollbarH]="false" [scrollbarV]="false" [limit]="13" [messages]="my_messages"
                          [sorts]="[{prop: 'Ordenacao', dir: 'asc'}]">

                            <ngx-datatable-column prop="row.DispHora" [draggable]="false" [sortable]="false" [flexGrow]="1">
                              <ng-template ngx-datatable-header-template>
                                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Horário</span>
                                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Hora</span>
                              </ng-template>
                              <ng-template let-row="row" ngx-datatable-cell-template>
                                <span containerClass="customClass" tooltip="{{row.DispHora}}" placement="auto"
                                  [adaptivePosition]="false">{{row.DispHora}}</span>
                              </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="row.DispSegConsulta" [draggable]="false" [sortable]="false" [flexGrow]="1">
                              <ng-template ngx-datatable-header-template>
                                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Segunda</span>
                                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Seg</span>
                              </ng-template>
                              <ng-template let-row="row" ngx-datatable-cell-template>
                                <span class="d-block"><ui-switch size="medium" [checked]="row.DispSegConsulta" (change)="Segunda(row,$event)"></ui-switch></span>
                              </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="row.DispTerConsulta" [draggable]="false" [sortable]="false" [flexGrow]="1">
                              <ng-template ngx-datatable-header-template>
                                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Terça</span>
                                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Ter</span>
                              </ng-template>
                              <ng-template let-row="row" ngx-datatable-cell-template>
                                <span class="d-block"><ui-switch size="medium" [checked]="row.DispTerConsulta" (change)="Terca(row,$event)"></ui-switch></span>
                              </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="row.DispQuaConsulta" [draggable]="false" [sortable]="false" [flexGrow]="1">
                              <ng-template ngx-datatable-header-template>
                                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Quarta</span>
                                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Qua</span>
                              </ng-template>
                              <ng-template let-row="row" ngx-datatable-cell-template>
                                <span class="d-block"><ui-switch size="medium" [checked]="row.DispQuaConsulta" (change)="Quarta(row,$event)" [disabled]="row.DispHora == 11 || row.DispHora == 12"></ui-switch></span>
                              </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="row.DispQuiConsulta" [draggable]="false" [sortable]="false" [flexGrow]="1">
                              <ng-template ngx-datatable-header-template>
                                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Quinta</span>
                                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Qui</span>
                              </ng-template>
                              <ng-template let-row="row" ngx-datatable-cell-template>
                                <span class="d-block"><ui-switch size="medium" [checked]="row.DispQuiConsulta" (change)="Quinta(row,$event)"></ui-switch></span>
                              </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="row.DispSexConsulta" [draggable]="false" [sortable]="false" [flexGrow]="1">
                              <ng-template ngx-datatable-header-template>
                                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Sexta</span>
                                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Sex</span>
                              </ng-template>
                              <ng-template let-row="row" ngx-datatable-cell-template>
                                <span class="d-block"><ui-switch size="medium" [checked]="row.DispSexConsulta" (change)="Sexta(row,$event)"></ui-switch></span>
                              </ng-template>
                            </ngx-datatable-column>

                          </ngx-datatable>
                        </div>

                        <div *ngSwitchCase="'S2'">
                          <ngx-datatable #myTableOn class="w-100 bootstrap ngx-datatable" [rows]="SocioDisponibilidade.SocioDispResHorarios"
                          [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                          [scrollbarH]="false" [scrollbarV]="false" [limit]="13" [messages]="my_messages"
                          [sorts]="[{prop: 'Ordenacao', dir: 'asc'}]">

                            <ngx-datatable-column prop="row.DispHora" [draggable]="false" [sortable]="false" [flexGrow]="1">
                              <ng-template ngx-datatable-header-template>
                                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Horário</span>
                                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Hora</span>
                              </ng-template>
                              <ng-template let-row="row" ngx-datatable-cell-template>
                                <span containerClass="customClass" tooltip="{{row.DispHora}}" placement="auto"
                                  [adaptivePosition]="false">{{row.DispHora}}</span>
                              </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="row.DispSegConsultaOn" [draggable]="false" [sortable]="false" [flexGrow]="1">
                              <ng-template ngx-datatable-header-template>
                                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Segunda</span>
                                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Seg</span>
                              </ng-template>
                              <ng-template let-row="row" ngx-datatable-cell-template>
                                <span class="d-block"><ui-switch size="medium" [checked]="row.DispSegConsultaOn" (change)="SegundaOn(row,$event)"></ui-switch></span>
                              </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="row.DispTerConsultaOn" [draggable]="false" [sortable]="false" [flexGrow]="1">
                              <ng-template ngx-datatable-header-template>
                                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Terça</span>
                                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Ter</span>
                              </ng-template>
                              <ng-template let-row="row" ngx-datatable-cell-template>
                                <span class="d-block"><ui-switch size="medium" [checked]="row.DispTerConsultaOn" (change)="TercaOn(row,$event)"></ui-switch></span>
                              </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="row.DispQuaConsultaOn" [draggable]="false" [sortable]="false" [flexGrow]="1">
                              <ng-template ngx-datatable-header-template>
                                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Quarta</span>
                                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Qua</span>
                              </ng-template>
                              <ng-template let-row="row" ngx-datatable-cell-template>
                                <span class="d-block"><ui-switch size="medium" [checked]="row.DispQuaConsultaOn" (change)="QuartaOn(row,$event)"></ui-switch></span>
                              </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="row.DispQuiConsultaOn" [draggable]="false" [sortable]="false" [flexGrow]="1">
                              <ng-template ngx-datatable-header-template>
                                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Quinta</span>
                                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Qui</span>
                              </ng-template>
                              <ng-template let-row="row" ngx-datatable-cell-template>
                                <span class="d-block"><ui-switch size="medium" [checked]="row.DispQuiConsultaOn" (change)="QuintaOn(row,$event)"></ui-switch></span>
                              </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="row.DispSexConsultaOn" [draggable]="false" [sortable]="false" [flexGrow]="1">
                              <ng-template ngx-datatable-header-template>
                                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Sexta</span>
                                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Sex</span>
                              </ng-template>
                              <ng-template let-row="row" ngx-datatable-cell-template>
                                <span class="d-block"><ui-switch size="medium" [checked]="row.DispSexConsultaOn" (change)="SextaOn(row,$event)"></ui-switch></span>
                              </ng-template>
                            </ngx-datatable-column>

                          </ngx-datatable>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </form>
            </div>
            <div class="top1">
              <div class="table-responsive linha-de-botoes">
                <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="Salva();">Salvar</button>
              </div>
            </div>
          </div>

      </div>
    </div>
  </div>
</div>


<div class="offcanvas offcanvas-top h-75" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="container">
    <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="offcanvas-header">
          <div class="card-title-form-menor">Disponibilidade de Atendimento de {{ SocioDisponibilidade.NomeSocio }}
            <button type="button" class="float-end btn-close text-primary customSearchButtons"
              data-bs-dismiss="offcanvas" aria-label="Fechar" containerClass="customClass" tooltip="Fechar Help"
              placement="bottom" [adaptivePosition]="false"></button>
          </div>
        </div>
        <div class="offcanvas-body">
            <div class="row">
            <div class="col-lg-12">
                <span class="d-block"  id="s-nome"><label class="form-label">Supervisão:&nbsp;&nbsp;&nbsp;lista dos profissionais responsáveis pela supervisão do associaod</label></span>
            </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <span class="d-block"  id="s-nome"><label class="form-label">Atendimentos Em Andamento:&nbsp;&nbsp;&nbsp; quantidade de consultas ativas presenciais e on line do associado</label></span>
                </div>
            </div>
            <div class="row top1">
              <span class="col-lg-2">
                <label class="form-label">Perfil</label>
                <input disabled class="form-control form-control-lg"
                placeholder="Perfil de acesso ao sistema." />
              </span>
              <span class="col-lg-1">
                <label class="form-label">Disponível?</label>
                <span class="d-block"> <ui-switch></ui-switch>
                  <input disabled class="form-control form-control-lg top02"
                  placeholder="Disponível para atendimento?" />
                </span>
              </span>
              <span class="col-lg-3" style="padding-left: 2%;">
                <label class="form-label">Tipo Atendimento:</label>
                <input disabled class="form-control form-control-lg"
                placeholder="Tipo de atendimento prestado." />
              </span>
              <span class="col-lg-1">
                <label class="form-label">Adultos:</label>
                <input disabled class="form-control form-control-lg"
                placeholder="Limite Adultos." />
              </span>
              <span class="col-lg-1">
                <label class="form-label">Crianças:</label>
                <input disabled class="form-control form-control-lg"
                placeholder="Limite Crianças." />
              </span>
              <span class="col-lg-2">
                <label class="form-label" for="desligamento" style="width: 100%;">Data Afastamento:</label>
                <input disabled class="form-control form-control-lg"
                placeholder="Data de afastamento temporário." />
              </span>
              <span class="col-lg-2">
                <label class="form-label" for="retorno" style="width: 100%;">Data Retorno:</label>
                <input disabled class="form-control form-control-lg"
                placeholder="Data de retorno do afastamento." />
              </span>
            </div>
            <div class="row top1">
              <div class="form-outline">
                  <ngx-datatable #myTableOnVazio class="w-100 bootstrap ngx-datatable" [rows]="SocioDisponibilidadeVazio.SocioDispResHorarios"
                  [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                  [scrollbarH]="false" [scrollbarV]="false" [limit]="13" [messages]="my_messagesVazio"
                  [sorts]="[{prop: 'Ordenacao', dir: 'asc'}]">

                    <ngx-datatable-column prop="row.DispHora" [draggable]="false" [sortable]="false" [flexGrow]="1">
                      <ng-template ngx-datatable-header-template>
                        <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Horário</span>
                        <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Hora</span>
                      </ng-template>
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span containerClass="customClass" tooltip="{{row.DispHora}}" placement="auto"
                          [adaptivePosition]="false">{{row.DispHora}}</span>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column prop="row.DispSegConsultaOn" [draggable]="false" [sortable]="false" [flexGrow]="1">
                      <ng-template ngx-datatable-header-template>
                        <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Segunda</span>
                        <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Seg</span>
                      </ng-template>
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="d-block"><ui-switch size="medium" [checked]="row.DispSegConsultaOn" (change)="SegundaOn(row,$event)"></ui-switch></span>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column prop="row.DispTerConsultaOn" [draggable]="false" [sortable]="false" [flexGrow]="1">
                      <ng-template ngx-datatable-header-template>
                        <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Terça</span>
                        <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Ter</span>
                      </ng-template>
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="d-block"><ui-switch size="medium" [checked]="row.DispTerConsultaOn" (change)="TercaOn(row,$event)"></ui-switch></span>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column prop="row.DispQuaConsultaOn" [draggable]="false" [sortable]="false" [flexGrow]="1">
                      <ng-template ngx-datatable-header-template>
                        <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Quarta</span>
                        <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Qua</span>
                      </ng-template>
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="d-block"><ui-switch size="medium" [checked]="row.DispQuaConsultaOn" (change)="QuartaOn(row,$event)"></ui-switch></span>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="row.DispQuiConsultaOn" [draggable]="false" [sortable]="false" [flexGrow]="1">
                      <ng-template ngx-datatable-header-template>
                        <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Quinta</span>
                        <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Qui</span>
                      </ng-template>
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="d-block"><ui-switch size="medium" [checked]="row.DispQuiConsultaOn" (change)="QuintaOn(row,$event)"></ui-switch></span>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="row.DispSexConsultaOn" [draggable]="false" [sortable]="false" [flexGrow]="1">
                      <ng-template ngx-datatable-header-template>
                        <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Sexta</span>
                        <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Sex</span>
                      </ng-template>
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="d-block"><ui-switch size="medium" [checked]="row.DispSexConsultaOn" (change)="SextaOn(row,$event)"></ui-switch></span>
                      </ng-template>
                    </ngx-datatable-column>

                  </ngx-datatable>
              </div>
            </div>
          <div class="w-100 top1"><button type="button" class="btn btn-warning">Cancelar</button><label
              style="margin-left: 4px; margin-right: 1%;">Retorna para tela que chamou.</label></div>
          <div class="w-100 top1"><button type="button" class="btn btn-primary">Salvar</button><label
              style="margin-left: 4px; margin-right: 1%;">Salva os dados editados/incluídos.</label></div>
         </div>
      </div>
    </div>
</div>

<app-footer></app-footer>
