import { Component, OnInit, ViewChild, Input, ChangeDetectorRef  } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';

import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { Lancamento } from '../models/lancamento';
import { ExcelService } from 'src/app/excel.service';
import LancamentoService from '../lancamento-service';
import { DataTableFooterComponent } from '@swimlane/ngx-datatable';

import { FormUtils } from 'src/app/Projeto/Utils/form-utils';
import { PlanoConta } from '../../planoconta/models/planoconta';
import { SocioResumo } from '../../associados/models/associados';
import PlanoContaService from '../../planoconta/planoconta-service';
import { AssociadosService } from '../../associados/associados.service';
import { TipoLancamento } from '../models/tipoLancamento';

@Component({
  selector: 'app-lancamento-lista',
  templateUrl: './lancamento-lista.component.html',
  styleUrls: ['./lancamento-lista.component.css'],
})

export class LancamentoListaComponent implements OnInit {
  constructor(
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private LancamentoService: LancamentoService,
    private utilsservice: UtilsService,
    private excelService: ExcelService,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private planoContaService: PlanoContaService,
    private associadosService: AssociadosService,
  ) {}
  
  @Input() origemSubTela: number = 0;
  @Input() origemSubTelaCodigo: number = 0;
  lancamento: Lancamento[] = [];
  lancamentoOriginal: Lancamento[] = [];
  novaLancamento: Lancamento = new Lancamento();
  DadosExcell: any = [];
  ColunasExcell: any = [];
  indexPagina: number = 1;
  totalLinhas: number = 0;

  descricaoPlanoConta: string = '';
  form: FormGroup;
  dataLancamento: Date;
  valorLancamento: string = '';
  listaPlanoConta: PlanoConta[] = [];
  planoContaOriginal: PlanoConta[] = [];
  planoContaArray: {}[];
  complemento: string = '';
  numeroLancamento: number;
  numeroParcelas: number;
  recebeuBoleto: boolean = false;
  compensado: boolean = false;
  estoqueAntigo: boolean = false;
  listaFornecedorOriginal: SocioResumo[] = [];
  listaAssociadoOriginal: SocioResumo[] = [];
  listaTipoLancamentoOriginal: TipoLancamento[] = [];

  @ViewChild('tFooter', { static: false }) tFooter: DataTableFooterComponent;
  //Variaveis - Detalhes dentro do ngx-datatable
  @ViewChild('myTable') table: any;

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.'
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.BuscaLancamento();
    this.BuscaAssociado();
    this.BuscaPlanoConta();  
    this.BuscaFornecedor();
    this.BuscaTipoLancamento();
    this.criarForm(this.novaLancamento);
        
  }

  BuscaLancamento(): void {
    this.spinner.show();
    this.lancamentoOriginal = [];
    try {
      this.LancamentoService
        .GetListaGeral(this.indexPagina, 0, this.origemSubTelaCodigo)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                console.log(response)
                console.log(response)
                
                response.LancamentoLista.forEach((item: any) => {
                    const tempList = new Lancamento();
                    tempList.carregar(item);
                    this.lancamentoOriginal.push(tempList);
                  });
            }
            this.lancamento.sort((a: Lancamento, b: Lancamento) => {
                return new Date(b.DataLancamento).getTime() - new Date(a.DataLancamento).getTime();
            });
            this.lancamento = this.lancamentoOriginal;
            //this.my_messages.totalMessage = `${this.GetTotalLinhas()} linhas encontradas.`
            this.my_messages.totalMessage = ` linhas encontradas.`
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }
  fecharModal() {
    const modalElement = document.getElementById('modalAdicionar');
    
    if (modalElement) {
   //   const modalInstance = Modal.getInstance(modalElement) || new Modal(modalElement);
  
      // Fecha o modal
      //modalInstance.hide();
    }
  }
  BuscaPlanoConta(): void {
    try {
      this.planoContaService
        .GetListaGeral()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                response.PlanoContaLista.forEach((item: any) => {
                    const tempList = new PlanoConta();
                    tempList.carregar(item);
                    this.planoContaOriginal.push(tempList);
                  });

            }
          },
          error: (error) => {
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.utilsservice.showHttpError(error);
    }
  }

  BuscaAssociado(): void {
    try {
      this.associadosService
        .ListaTodosSocios()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                response.SociosResumo.forEach((item: any) => {
                    const tempList = new SocioResumo();
                    tempList.carregar(item);
                    this.listaAssociadoOriginal.push(tempList);
                  });

            }
          },
          error: (error) => {
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.utilsservice.showHttpError(error);
    }
  }

  BuscaFornecedor(): void {
    try {
      this.associadosService
        .ListaFornecedores()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                response.SociosResumo.forEach((item: any) => {
                    const tempList = new SocioResumo();
                    tempList.carregar(item);
                    this.listaFornecedorOriginal.push(tempList);
                  });

            }
          },
          error: (error) => {
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.utilsservice.showHttpError(error);
    }
  }

  BuscaTipoLancamento(): void {
    try {
      this.LancamentoService
        .GetTipoLancamento()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                response.TipoLancamentoLista.forEach((item: any) => {
                    const tempList = new TipoLancamento();
                    tempList.carregar(item);
                    this.listaTipoLancamentoOriginal.push(tempList);
                  });

            }
          },
          error: (error) => {
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.utilsservice.showHttpError(error);
    }
  }
  SalvaLancamento(): void {
    let requestLancamento: Lancamento = new Lancamento();
    requestLancamento.CodigoLancamento = this.novaLancamento.CodigoLancamento;
    requestLancamento.CodigoPlanoConta = this.form.get("formContaPlano")?.value;
    requestLancamento.CodigoFornecedor = this.form.get("formFornecedor")?.value;
    requestLancamento.CodigoSocioLancamento = this.form.get("formAssociado")?.value;
    requestLancamento.CodigoTipoLancamento = this.form.get("formTipoLancamento")?.value;
    requestLancamento.DescExtraPlanoconta = this.form.get("formComplemento")?.value;
    requestLancamento.DataLancamento = this.novaLancamento.DataLancamento;
    requestLancamento.ValorLancamento = this.form.get("formValorLancamento")?.value;
    requestLancamento.NroCheque = this.form.get("formNumeroLancamento")?.value;
    requestLancamento.OrigemLancamento = 'LA';
    requestLancamento.Compensado = this.compensado === true ? 'Sim' : 'Não';
    requestLancamento.RecebeuBoleto = this.recebeuBoleto === true ? 'S' : 'N';
    requestLancamento.Estoque = this.estoqueAntigo;

    if (!requestLancamento.CodigoPlanoConta) 
        this.messageService.showAlertDanger("Plano de Contas deve ser informado.");     
    
    else if (!requestLancamento.CodigoFornecedor && !requestLancamento.CodigoSocioLancamento) 
        this.messageService.showAlertDanger("Fornecedor ou Associado precisa ser informado.");     
    
    else if (!requestLancamento.DataLancamento) 
      this.messageService.showAlertDanger("Data precisa ser informado.");  

    else if (!requestLancamento.ValorLancamento) 
      this.messageService.showAlertDanger("Valor precisa ser informado.");  

    else if (!requestLancamento.CodigoTipoLancamento) 
      this.messageService.showAlertDanger("Tipo Lançamento precisa ser informado.");  

    else if (!Lancamento.saoDiferentes(requestLancamento, this.novaLancamento)) {
        this.messageService.showAlertDanger("Nenhum campo novo detectado para alteração.");     
        }

    else {
      this.spinner.show();
      try {
        this.LancamentoService
          .SalvaLancamento(
            requestLancamento
          )
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              this.spinner.hide();
              if (response.Error) {
                this.messageService.showAlertDanger(response.ErrorMessage);
              } else {
                this.fecharModal();
              }
            },
            error: (error) => {
              this.spinner.hide();
              this.utilsservice.showHttpError(error);
            },
          });
      } catch (error) {
        this.spinner.hide();
        this.utilsservice.showHttpError(error);
      }
    }
  }

  AlteraData($event: any, data: string)
  {
    this.novaLancamento.DataLancamento = new Date($event);
  }

  toggleRecebeuBoleto() {
    this.recebeuBoleto = !this.recebeuBoleto;
 }   
  toggleCompensado() {
    this.compensado = !this.compensado;
  }   
  toggleEstoqueAntigo() {
    this.estoqueAntigo = !this.estoqueAntigo;
  }   
  criarForm(dados: Lancamento) {
    const plano = new PlanoConta
    this.planoContaArray = plano.toDict(this.planoContaOriginal)
    this.form = this.formBuilder.group({
      formDataLancamento: [dados.DataLancamento, Validators.required],
      formValorLancamento: [dados.ValorLancamento || '', [Validators.maxLength(255)]],
      formContaPlano: [dados.CodigoPlanoConta],
      formFornecedor: [dados.CodigoFornecedor],
      formTipoLancamento: [{value:dados.CodigoTipoLancamento, disabled:dados.CodigoLancamento > 0}],
      formAssociado: [dados.CodigoSocioLancamento],
      formComplemento: [dados.DescExtraPlanoconta],
      formNumeroLancamento: [dados.NroCheque],
      formNumeroParcela: [{value:1, disabled:dados.CodigoLancamento > 0}],
      formRecebeuBoleto: [this.recebeuBoleto],
      formCompensado: [this.compensado],
      formEstoqueAntigo: [this.estoqueAntigo],

    });
}
  Edicao(lancamento: Lancamento) {
     this.router.navigateByUrl('/administrativo/lancamentos/edicao', { state: { lancamento: lancamento }});
   }

  EdicaoSubTela(lancamento: Lancamento) {
    setTimeout(() => {
        this.criarForm(lancamento); // Inicializa o formulário com os dados do profissional
        // lógica para abrir o modal
      });
   }

  Adicionar() {
    this.novaLancamento = new Lancamento();
    this.novaLancamento.CodigoLancamento = -1;
    this.router.navigateByUrl('/administrativo/lancamentos/edicao', {
      state: { lancamento: this.novaLancamento },
    });
   } 

   AdicionarSubTela() {
    this.novaLancamento = new Lancamento();
    this.novaLancamento.CodigoLancamento = -1;
    this.novaLancamento.Estoque = false;
    this.novaLancamento.Compensado = '';
    this.novaLancamento.RecebeuBoleto = '';
    this.novaLancamento.ValorLancamento = '';
    this.novaLancamento.NroCheque = 0;
    //this.novaLancamento.CodigoLancamento = 1;
    setTimeout(() => {
        this.criarForm(this.novaLancamento); // Inicializa o formulário com os dados do profissional
        // lógica para abrir o modal
      });
   } 
  //Rotinas - Detalhes dentro do ngx-datatable
  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    const temp = this.lancamentoOriginal.filter(function (d: any) {
      return (
        d.DescricaoPlanoConta.toLowerCase().indexOf(val) !== -1 || d.TipoPlanoConta.toLowerCase().indexOf(val) !== -1
      );
    });
    // update the rows
    this.lancamento = temp;
    return true;
  }

  GetTotalLinhas() {
    if (this.lancamentoOriginal){
        this.totalLinhas = Math.max(...this.lancamentoOriginal.map(lancamento => lancamento.TotalLinhas));
    }
    return this.totalLinhas;
  };

  handleFooter(event: any) {
    this.indexPagina = event.page; 
    this.BuscaLancamento(); 
  }

  generateExcell() {
    var nomeArquivo: string = 'RelatorioLancamento';
    var Titulo: string = 'Relatório de Lançamentos';
    this.ColunasExcell = [];
    this.ColunasExcell.push('Plano Conta');
    this.ColunasExcell.push('Descrição Complementar');
    this.ColunasExcell.push('Nome Fornecedor');
    this.ColunasExcell.push('Tipo');
    this.ColunasExcell.push('Data');
    this.ColunasExcell.push('Valor');
    this.ColunasExcell.push('Número');
    this.ColunasExcell.push('Boleto');
    this.ColunasExcell.push('Compensado');
    this.ColunasExcell.push('Tipo Lançamento');
    this.DadosExcell = [];
    this.lancamento.forEach((item: Lancamento) => {
      const varExcell: any = [];
      varExcell.push(item.DescricaoPlanoConta);
      varExcell.push(item.DescExtraPlanoconta);
      varExcell.push(item.NomeFornecedor);
      varExcell.push(item.TipoPlanoConta);
      varExcell.push(item.converterDataLancamento());
      varExcell.push(item.converterValorLancamento());
      varExcell.push(item.NroCheque);
      varExcell.push(item.RecebeuBoleto);
      varExcell.push(item.Compensado);
      varExcell.push(item.NomeTipoLancamento);
      this.DadosExcell.push(varExcell);
    });
    this.excelService.generateExcel(
      nomeArquivo,
      Titulo,
      this.ColunasExcell,
      this.DadosExcell
    );
 }

}
