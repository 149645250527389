import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RetInscricaoLista } from './models/ret-inscricao';
import { Inscricao } from './models/inscricao';
import { AuthStorageService } from '../../../Projeto/Autenticacao/auth-storage.service';
import { RetError } from 'src/app/Projeto/models/ret-error';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export default class InscricaoService {

  private readonly baseURL: string = '';
  constructor(private httpClient: HttpClient, private router: Router, private authStorageService: AuthStorageService) {
    this.baseURL = `${environment.InscricaoWS}`;
  }
  
  GetListaGeral(p_index: number, p_id: number, p_origem: number): Observable<RetInscricaoLista> {

    const params  = p_origem === 0 ? new HttpParams() 
    .set('p_index', p_index.toString())
    .set('p_id', p_id.toString()) : new HttpParams()
    .set('p_index', p_index.toString())
    .set('p_id', p_id.toString())
    .set('p_origem', p_origem.toString()); 

    const url = `${this.baseURL}/ListaGeral`;

    return this.httpClient.get<Inscricao[]>(url, { params }).pipe(
      take(1),
      map((response: Inscricao[]) => {
        const tempRet: RetInscricaoLista = {
          Error: false,                  // Define Error como false por padrão, ou ajuste conforme necessário.
          ErrorMessage: '',              // Mensagem de erro vazia, já que não há erro explícito.
          InscricaoLista: response || []  // Usa o array de resposta ou um array vazio.
        };
        return tempRet;
      })
    );
}

  SalvaInscricao(inscricao: Inscricao): Observable<RetError> {
    let bodyString = JSON.stringify(inscricao.toJson());

    const url = `${this.baseURL}/SalvaInscricao`;
    return this.httpClient.post<RetError>(url, bodyString ).pipe(
        take(1),
        map((response: RetError) => {
            return response;
        })
    );
  }   
}
