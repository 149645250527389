<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">

    <div class="card-title-form">Criação de Seminários</div>
    <div class="card-body-form">
      <div class="table-responsive table-responsive-overflow-500">

        <ul class="nav nav-tabs">
          <li class="nav-item d-flex">
            <a class="nav-link active" id="S1" data-bs-toggle="tab" role="tab" aria-controls="S1" aria-selected="true"
              [class.active]="isActiveAba('S1')" (click)="setAba('S1')">Seleção de Associados</a>
          </li>
          <li class="nav-item d-flex">
            <a class="nav-link active" id="S2" data-bs-toggle="tab" role="tab" aria-controls="S2" aria-selected="true"
              [class.active]="isActiveAba('S2')" (click)="setAba('S2')">Gravação do Seminário</a>
          </li>
        </ul>

        <ng-container>
          <div class="tab-content" id="myTabContent" [ngSwitch]="aba">
            <div *ngSwitchCase="'S1'">
              <div style="margin-top: 0.5%;"></div>
              <div class="row">
                <div [ngClass]="!isMobile ? 'col-lg-8' : 'col-lg-12' ">
                    <input type="text" #search id="search" class="form-control form-control-lg "
                      placeholder="Pesquisar nome seminário ou quantidade de alunos" (keyup)="updateFilter($event)" />
                </div>
                <div [ngClass]="!isMobile ? 'col-lg-4' : 'col-lg-12' ">
                    <input type="text" class="form-control form-control-lg card-title-form-texto" [(ngModel)]="SeminarioSelDescr" disabled />
                </div>
              </div>
              <div style="margin-top: 0.5%;"></div>
              <div class="row">
                <div [ngClass]="!isMobile ? 'col-lg-8' : 'col-lg-12' ">
                  <ngx-datatable #myTable class="w-100 bootstrap ngx-datatable" [rows]="ListaSeminariosComPart"
                    [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                    [scrollbarH]="false" [scrollbarV]="false" [limit]="6" [messages]="my_messages"
                    [sorts]="[{prop: 'Ordenacao', dir: 'asc'}]">

                    <ngx-datatable-column prop="NomeEnsinoNome" [draggable]="false" [flexGrow]="3">
                      <ng-template ngx-datatable-header-template>
                        <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Seminário</span>
                        <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Seminário</span>
                      </ng-template>
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span containerClass="customClass" tooltip="{{row.NomeEnsinoNome}}" placement="auto"
                          [adaptivePosition]="false">{{row.NomeEnsinoNome }}</span>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column prop="TotalAssociados" [cellClass]="'ngx-direita'" [headerClass]="'ngx-direita'"
                      [draggable]="false" [flexGrow]="2">
                      <ng-template ngx-datatable-header-template>
                        <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Total de Associados</span>
                        <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Associados</span>
                      </ng-template>
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span containerClass="customClass" tooltip="{{row.TotalAssociados}}" placement="auto"
                          [adaptivePosition]="false">{{row.TotalAssociados}}</span>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column prop="TotalPartipantes" [cellClass]="'ngx-direita'" [headerClass]="'ngx-direita'"
                      [draggable]="false" [flexGrow]="2">
                      <ng-template ngx-datatable-header-template>
                        <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Associados Participaram</span>
                        <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Participaram</span>
                      </ng-template>
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span containerClass="customClass" tooltip="{{row.TotalPartipantes}}" placement="auto"
                          [adaptivePosition]="false">{{row.TotalPartipantes}}</span>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Ações&nbsp;&nbsp;&nbsp;" [sortable]="false"
                    [canAutoResize]="false" [resizeable]="false" [draggable]="false" [width]="100"
                    [headerClass]="'ngx-direita'" [cellClass]="'ngx-direita'">
                      <ng-template let-row="row" let-rowIndex="rowIndex" let-expanded="expanded"
                        ngx-datatable-cell-template>
                        <a href="javascript:void(0)">
                          <span *ngIf="!expanded" (click)="toggleExpandRow(row, false)" class="iconeTamanho"
                            containerClass="customClass" tooltip="Mostrar Informações Adicionais" placement="top"
                            [adaptivePosition]="false">
                            <i class="fas fa-plus">&nbsp;&nbsp;&nbsp;</i>
                          </span>
                          <span *ngIf="expanded" (click)="toggleExpandRow(row, true)" class="iconeTamanho"
                            containerClass="customClass" tooltip="Ocultar Informações Adicionais" placement="top"
                            [adaptivePosition]="false">
                            <i class="fas fa-minus">&nbsp;&nbsp;&nbsp;</i>
                          </span>
                        </a>

                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-row-detail #myDetailRow [rowHeight]="100">
                      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>

                        <div style="padding: 0 1%; width: 98%;">
                          <h5>Associados que não fizeram este seminário.</h5>
                          <ngx-datatable #myTable class="bootstrap ngx-datatable" [rows]="SociosResumo"
                            [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50"
                            rowHeight="auto" [scrollbarH]="false" [scrollbarV]="false" [limit]="5" [messages]="my_messages"
                            [sorts]="[{prop: 'NomeSocio ', dir: 'asc'}]">

                              <ngx-datatable-column prop="NomeSocio" [draggable]="false" [flexGrow]="4">
                                <ng-template ngx-datatable-header-template>
                                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Nome Associado</span>
                                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Nome</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                  <span containerClass="customClass" tooltip="{{row.NomeSocio}}" placement="auto"
                                    [adaptivePosition]="false">{{row.NomeSocio }}</span>
                                </ng-template>
                              </ngx-datatable-column>

                              <ngx-datatable-column prop="AreaAtuacao" [draggable]="false" [flexGrow]="2">
                                <ng-template ngx-datatable-header-template>
                                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Área Atuação</span>
                                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Área</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                  <span containerClass="customClass" tooltip="{{row.AreaAtuacao}}" placement="auto"
                                    [adaptivePosition]="false">{{row.AreaAtuacao}}</span>
                                </ng-template>
                              </ngx-datatable-column>

                              <ngx-datatable-column prop="EmailSocio" [draggable]="false" [flexGrow]="2">
                                <ng-template ngx-datatable-header-template>
                                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">E-mail</span>
                                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">E-mail</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                  <span containerClass="customClass" tooltip="{{row.EmailSocio}}" placement="auto"
                                    [adaptivePosition]="false">{{row.EmailSocio}}</span>
                                </ng-template>
                              </ngx-datatable-column>

                              <ngx-datatable-column name="Ações&nbsp;&nbsp;&nbsp;" [sortable]="false"
                              [canAutoResize]="false" [resizeable]="false" [draggable]="false" [width]="100"
                              [headerClass]="'ngx-direita'" [cellClass]="'ngx-direita'">
                                <ng-template let-row="row" let-rowIndex="rowIndex" let-expanded="expanded"
                                  ngx-datatable-cell-template>
                                  <span class="iconeTamanho" containerClass="customClass" tooltip="Selecionar" placement="auto"
                                  [adaptivePosition]="false" *ngIf="!row.Selecionado" (click)="onSelectSeminario(row)">
                                  <i class="far fa-check-circle text-danger"></i></span>

                                  <span class="iconeTamanho" containerClass="customClass" tooltip="Selecionado" placement="auto"
                                  [adaptivePosition]="false" *ngIf="row.Selecionado">
                                  <i class="far fa-check-circle text-success"></i></span>

                                </ng-template>
                              </ngx-datatable-column>

                          </ngx-datatable>
                        </div>

                      </ng-template>
                    </ngx-datatable-row-detail>

                  </ngx-datatable>
                </div>
                <div [ngClass]="!isMobile ? 'col-lg-4' : 'col-lg-12' ">
                  <table class="table table-hover">
                    <tbody>
                        <tr>
                            <th style="font-weight: bolder;padding: 0.7rem 0.25rem;">Associado</th>
                            <th style="font-weight: bolder;padding: 0.7rem 0.25rem;">Ação</th>
                        </tr>
                        <tr *ngFor="let sel of SociosResumoSel">
                            <td style="font-weight: bolder;padding: 0.25rem;">
                                {{sel.NomeSocio}}</td>
                            <td class="iconeTamanho col-lg-1" containerClass="customClass" tooltip="Remover"
                                style="font-size: 1rem;padding-top: 0.25rem;font-weight: bolder;"
                                (click)="removeSelectSeminario(sel)">
                                <i class="fas fa-trash-alt"></i></td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>

            <div *ngSwitchCase="'S2'">
              <div style="margin-top: 0.5%;"></div>
              <div class="row">
                <div class='col-lg-4'>
                  <label class="form-label" for="formNomeSala">Seminário Selecionado</label>
                  <input type="text" class="form-control form-control-lg card-title-form-texto" [(ngModel)]="SeminarioSelDescr" disabled />
                </div>
                <div class='col-lg-6'>
                  <label class="form-label" for="formNomeSala">Título</label>
                  <input type="text" class="form-control form-control-lg"/>
                </div>
                <div class='col-lg-2'>
                  <label class="form-label" for="formNomeSala">Período</label>
                  <input type="text" class="form-control form-control-lg" mask="00/0000" />
                </div>
              </div>
              <div style="margin-top: 0.5%;"></div>

              <div class="row">
                <ngx-datatable #myTable class="bootstrap ngx-datatable" [rows]="SociosResumoSel"
                [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50"
                rowHeight="auto" [scrollbarH]="false" [scrollbarV]="false" [limit]="5" [messages]="my_messages"
                [sorts]="[{prop: 'NomeSocio ', dir: 'asc'}]">

                  <ngx-datatable-column prop="NomeSocio" [draggable]="false" [flexGrow]="4">
                    <ng-template ngx-datatable-header-template>
                      <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Nome Associado</span>
                      <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Nome</span>
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span containerClass="customClass" tooltip="{{row.NomeSocio}}" placement="auto"
                        [adaptivePosition]="false">{{row.NomeSocio }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column prop="AreaAtuacao" [draggable]="false" [flexGrow]="2">
                    <ng-template ngx-datatable-header-template>
                      <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Área Atuação</span>
                      <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Área</span>
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span containerClass="customClass" tooltip="{{row.AreaAtuacao}}" placement="auto"
                        [adaptivePosition]="false">{{row.AreaAtuacao}}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column prop="EmailSocio" [draggable]="false" [flexGrow]="2">
                    <ng-template ngx-datatable-header-template>
                      <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">E-mail</span>
                      <span *ngIf="isMobile" class="ngx-datatable-cabecalho">E-mail</span>
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span containerClass="customClass" tooltip="{{row.EmailSocio}}" placement="auto"
                        [adaptivePosition]="false">{{row.EmailSocio}}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column prop="EmailSocio" [draggable]="false" [flexGrow]="2">
                    <ng-template ngx-datatable-header-template>
                      <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Telefone</span>
                      <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Telefone</span>
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span containerClass="customClass" tooltip="{{row.Telefone1Socio | mask:'(00) 00000-0000'}}" placement="auto"
                        [adaptivePosition]="false">{{row.Telefone1Socio | mask:'(00) 00000-0000'}}</span>
                    </ng-template>
                  </ngx-datatable-column>

                </ngx-datatable>
              </div>

            </div>

          </div>
        </ng-container>


      </div>

    </div>
  </div>
</div>


<app-footer></app-footer>
