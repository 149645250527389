<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">

      <div class="align-items-center justify-content-center h-100">

        <div class="card-title-form">Mensalidades de {{ NomeSocio }}
          <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
            [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
            aria-controls="offcanvasExample">
            <i class="fa-solid fa-circle-question"></i>
          </span>
        </div>

        <div class="card-body-form">
          <div class="table-responsive table-responsive-overflow-450">
            <form [formGroup]="form">
              <div class="row">
                <span class="col-lg-6">
                  <label class="form-label">Tipo Mensalidade:</label>
                  <ngx-select-dropdown name="TipoMensalidadeDescr" id="TipoMensalidadeDescr"
                    formControlName="TipoMensalidadeDescr" (change)="changeTipoMensalidade($event)"
                    [(ngModel)]="mensalidade.TipoMensalidadeDescr" [disabled]="CodigoMensalidade != -1"
                    [config]="configTipo" [options]="TipoMensalidade" class="form_control_ngx_select_dropdown">
                  </ngx-select-dropdown>
                </span>
                <div class="col-lg-3">
                  <label class="form-label" for="ValorTotal">Valor Total:</label>
                  <input type="text" id="ValorTotal" formControlName="ValorTotal" [(ngModel)]="mensalidade.ValorTotal"
                    [value]="form.get('mensalidade.ValorTotal')" name="ValorTotal"
                    class="form-control form-control-lg" currencyMask />
                </div>
                <div class="col-lg-3">
                  <label class="form-label" for="DescontoVencimento">Desconto Concedido:</label>
                  <input type="text" id="DescontoVencimento" formControlName="DescontoVencimento" [(ngModel)]="mensalidade.DescontoVencimento"
                    [value]="form.get('mensalidade.DescontoVencimento')" name="DescontoVencimento"
                    class="form-control form-control-lg" currencyMask />
                </div>
              </div>
              <div class="row top02">
                <div class="col-lg-3">
                  <label class="form-label" for="TarifaVencimento">Tarifa Bancária:</label>
                  <input type="text" id="TarifaVencimento" formControlName="TarifaVencimento" [(ngModel)]="mensalidade.TarifaVencimento"
                    [value]="form.get('mensalidade.TarifaVencimento')" name="TarifaVencimento"
                    class="form-control form-control-lg" currencyMask />
                </div>
                <div class="col-lg-3">
                  <label class="form-label" for="ValorVencimento">Valor Vencimento:</label>
                  <input type="text" id="ValorVencimento" formControlName="ValorVencimento" [(ngModel)]="mensalidade.ValorVencimento"
                    [value]="form.get('mensalidade.ValorVencimento')" name="ValorVencimento"
                    class="form-control form-control-lg" currencyMask />
                </div>
                <span class="col-lg-3">
                  <label class="form-label" for="DataVencimento" style="width: 100%;">Data Vencimento:</label>
                  <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text"
                    (bsValueChange)="AlteraData($event , 'DataVencimento');"
                    [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false, showTodayButton: true, todayPosition: 'center', todayButtonLabel: 'Hoje' }"
                    [value]="form.get('mensalidade.DataVencimento')" name="DataVencimento" id="DataVencimento"
                     formControlName="DataVencimento">
                </span>
                <div class="col-lg-3">
                  <label class="form-label">Forma Pagamento:</label>
                  <ngx-select-dropdown name="NomeTipoLancamento" id="NomeTipoLancamento"
                    formControlName="NomeTipoLancamento" (change)="changeTipoLancamento($event, '1')"
                    [(ngModel)]="mensalidade.NomeTipoLancamento" [config]="configTipo" [options]="TipoLancamento"
                    class="form_control_ngx_select_dropdown">
                  </ngx-select-dropdown>
                </div>
              </div>
              <div class="row top02">
                <div class="col-lg-3">
                  <label class="form-label" for="JuroVencimento">Juros:</label>
                  <input type="text" id="JuroVencimento" formControlName="JuroVencimento" [(ngModel)]="mensalidade.JuroVencimento"
                    [value]="form.get('mensalidade.JuroVencimento')" name="JuroVencimento"
                    class="form-control form-control-lg" currencyMask />
                </div>
                <div class="col-lg-3">
                  <label class="form-label" for="ValorPagamento">Valor Pagamento:</label>
                  <input type="text" id="ValorPagamento" formControlName="ValorPagamento" [(ngModel)]="mensalidade.ValorPagamento"
                    [value]="form.get('mensalidade.ValorPagamento')" name="ValorPagamento"
                    class="form-control form-control-lg" currencyMask />
                </div>
                <span class="col-lg-3">
                  <label class="form-label" for="DataPagamento" style="width: 100%;">Data Pagamento:</label>
                  <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text"
                    (bsValueChange)="AlteraData($event , 'DataPagamento');"
                    [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false, showTodayButton: true, todayPosition: 'center', todayButtonLabel: 'Hoje' }"
                    [value]="form.get('mensalidade.DataPagamento')" name="DataPagamento" id="DataPagamento" formControlName="DataPagamento">
                </span>
                <div class="col-lg-3">
                  <label class="form-label">Boleto Gerado?</label>
                  <span class="d-block"> <ui-switch name="BloquetoGerado" id="BloquetoGerado"
                      formControlName="BloquetoGerado" (change)="changeBoleto($event);"></ui-switch></span>
                </div>
              </div>
              <div class="row top02" *ngIf="mensalidade.MostrarPagto2">
                <div class="col-lg-3"></div>
                <div class="col-lg-3"></div>
                <div class="col-lg-3">
                  <label class="form-label" for="ValorPagamento2">Valor Pagamento 2:</label>
                  <input type="text" id="ValorPagamento2" formControlName="ValorPagamento2"  [(ngModel)]="mensalidade.ValorPagamento2"
                    [value]="form.get('mensalidade.ValorPagamento2')" name="ValorPagamento2"
                    class="form-control form-control-lg" currencyMask />
                </div>
                <div class="col-lg-3">
                  <label class="form-label">Forma Pagamento 2:</label>
                  <ngx-select-dropdown name="NomeTipoLancamento2" id="NomeTipoLancamento2"
                    formControlName="NomeTipoLancamento2" (change)="changeTipoLancamento($event, '2')"
                    [(ngModel)]="mensalidade.NomeTipoLancamento2" [config]="configTipo" [options]="TipoLancamento"
                    class="form_control_ngx_select_dropdown">
                  </ngx-select-dropdown>
                </div>
              </div>
              <div class="row top02">
                <div [ngClass]="mensalidade.MostrarPagto2 ? 'col-lg-9' : 'col-lg-12'">
                  <label class="form-label">Observações diversas:</label>
                  <textarea class="form-control form-control-lg" rows="2" name="DescricaoMensalidade"
                    id="DescricaoMensalidade" formControlName="DescricaoMensalidade"
                    [value]="form.get('mensalidade.DescricaoMensalidade')" (change)="changeObs();"></textarea>
                </div>
                <div *ngIf="mensalidade.MostrarPagto2"   class="col-lg-3">
                  <label class="form-label">Compensado?</label>
                  <span class="d-block"> <ui-switch name="bCompensado" id="bCompensado" formControlName="bCompensado"
                      (change)="changeCompensado($event);"></ui-switch></span>
                </div>
              </div>
            </form>
          </div>
          <div class="top1">
            <div class="table-responsive linha-de-botoes">
              <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
              <button type="button" class="btn btn-primary" (click)="SalvarVista();">A Vista</button>
              <button type="button" class="btn btn-primary" (click)="Salvar();">Salvar</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="offcanvas offcanvas-top h-75" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="container">
    <div class="row d-flex align-items-center justify-content-center h-100">
      <div class="col-lg-12">
        <div class="offcanvas-header">
          <div class="card-title-form-menor">Mensalidades de {{NomeSocio}}
            <button type="button" class="float-end btn-close text-primary customSearchButtons"
              data-bs-dismiss="offcanvas" aria-label="Fechar" containerClass="customClass" tooltip="Fechar Help"
              placement="bottom" [adaptivePosition]="false"></button>
          </div>
        </div>
        <div class="offcanvas-body">
          <div class="row">
            <span class="col-lg-6">
              <label class="form-label">Tipo Mensalidade:</label>
              <input type="text" disabled class="form-control form-control-lg"
              placeholder="Mensalidade/Uso Sala/Atividade.Inscrição Atividade só edição, sem inclusão." />
            </span>
            <div class="col-lg-3">
              <label class="form-label" for="ValorTotal">Valor Total: (informativo)</label>
              <input type="text" disabled class="form-control form-control-lg"
              placeholder="Valor total desta mensalidade." />
            </div>
            <div class="col-lg-3">
              <label class="form-label" for="DescontoVencimento">Desconto Concedido: (informativo)</label>
              <input type="text" disabled class="form-control form-control-lg"
              placeholder="Desconto dado nesta mensalidade." />
            </div>
          </div>
          <div class="row top02">
            <div class="col-lg-3">
              <label class="form-label" for="TarifaVencimento">Tarifa Bancária:</label>
              <input type="text" disabled class="form-control form-control-lg"
              placeholder="Tarifa de cobrança bancária." />
            </div>
            <div class="col-lg-3">
              <label class="form-label" for="ValorVencimento">Valor Vencimento:</label>
              <input type="text" disabled class="form-control form-control-lg"
              placeholder="Valor no vencimento." />
            </div>
            <span class="col-lg-3">
              <label class="form-label" for="DataVencimento" style="width: 100%;">Data Vencimento:</label>
              <input type="text" disabled class="form-control form-control-lg"
              placeholder="Data de vencimento." >
            </span>
            <div class="col-lg-3">
              <label class="form-label">Forma Pagamento:</label>
              <input type="text" disabled class="form-control form-control-lg"
              placeholder="Forma de pagamento." >
            </div>
          </div>
          <div class="row top02">
            <div class="col-lg-3">
              <label class="form-label" for="JuroVencimento">Juros:</label>
              <input type="text" disabled class="form-control form-control-lg"
              placeholder="Juros cobrados por atraso." />
            </div>
            <div class="col-lg-3">
              <label class="form-label" for="ValorPagamento">Valor Pagamento:</label>
              <input type="text" disabled class="form-control form-control-lg"
              placeholder="Valor efetivamente pago." />
            </div>
            <span class="col-lg-3">
              <label class="form-label" for="DataPagamento" style="width: 100%;">Data Pagamento:</label>
              <input type="text" disabled class="form-control form-control-lg"
              placeholder="Data do efetivo pagamento.">
            </span>
            <div class="col-lg-3">
              <label class="form-label">Boleto Gerado?</label>
              <input type="text" disabled class="form-control form-control-lg"
              placeholder="Se foi gerado ou não boleto de cobrança.">
            </div>
          </div>
          <div class="row top02">
            <div class="col-lg-6">
              <label class="form-label">Esta linha só aparece se o pagamento for dividido em 2 formas diferentes</label>
            </div>
            <div class="col-lg-3">
              <label class="form-label" for="ValorPagamento2">Valor Pagamento 2:</label>
              <input  type="text" disabled class="form-control form-control-lg"
              placeholder="Segundo valor efetivamente pago." />
            </div>
            <div class="col-lg-3">
              <label class="form-label">Forma Pagamento 2:</label>
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Segunda Forma de pagamento." >
            </div>
          </div>
          <div class="row top02">
            <div class="col-lg-9">
              <label class="form-label">Observações diversas:</label>
              <input type="text" disabled class="form-control form-control-lg"
              placeholder="Descrição da origem da mensalidade." />
            </div>
            <div class="col-lg-3">
              <label class="form-label">Compensado?</label>
              <input type="text" disabled class="form-control form-control-lg"
              placeholder="Se o cheque compensou ou não." />
            </div>
          </div>
          <div class="w-100 top1"><button type="button" class="btn btn-warning">Cancelar</button><label
            style="margin-left: 4px; margin-right: 1%;">Retorna para tela que chamou.</label></div>
          <div class="w-100 top1"><button type="button" class="btn btn-primary">A Vista</button><label
            style="margin-left: 4px; margin-right: 1%;">Copia a data/valor de vencimento para a data/valor de pagamento e já salva os dados.</label></div>
          <div class="w-100 top1"><button type="button" class="btn btn-primary">Salvar</button><label
            style="margin-left: 4px; margin-right: 1%;">Salva os dados editados/incluídos.</label></div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
