<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-lg-6">
          <div class="card-title-form">Lançamento
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top" 
            [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
              aria-controls="offcanvasExample">
              <i class="fa-solid fa-circle-question"></i>
            </span>
          </div>

          <div class="card-body-form">
            <form [formGroup]="form">
              <div class="row">

                <div class="form-outline">
                    <label class="form-label" for="formContaPlano">Plano Conta</label>
                    <select id="formContaPlano" formControlName="formContaPlano" name="formContaPlano" class="form-select">
                        <option value="0" disabled selected>Selecione...</option>
                        <option *ngFor="let conta of planoContaOriginal" [value]="conta.IdPlanoConta">{{ conta.CodigoPlanoConta }} - {{ conta.DescricaoPlanoConta }} </option>
                    </select> 
                
                <app-field-error-message [control]="form.get('formContaPlano')" label="plano de contas"></app-field-error-message>
                </div>

                <div class="form-outline">
                  <label class="form-label" for="formFornecedor">Fornecedor</label>
                  <select id="formFornecedor" formControlName="formFornecedor" name="formFornecedor" class="form-select">
                      <option value="0" disabled selected>Selecione...</option>
                      <option *ngFor="let fornecedor of listaFornecedorOriginal" [value]="fornecedor.CodigoSocio">{{ fornecedor.NomeSocio }} </option>
                  </select> 
              
              <app-field-error-message [control]="form.get('formContaPlano')" label="plano de contas"></app-field-error-message>
              </div>
              <div class="form-outline">
                <label class="form-label" for="formComplemento">Complemento</label>
                <input type="text" id="formComplemento" formControlName="formComplemento" [value]="form.get('formComplemento')"
                  name="formComplemento" class="form-control form-control-lg" />
                <app-field-error-message [control]="form.get('formComplemento')"
                  label="descrição complementar"></app-field-error-message>
              </div>

                <div class="row top02">
                  <div class="col-lg-5">
                    <label class="form-label" style="width: 100%;">Data Lançamento</label>
                    <input style="display: block"  class="form-control form-control-lg" bsDatepicker type="text"
                    (bsValueChange)="AlteraData($event , 'lancamento.DataLancamento');"
                    [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false }"
                    name="formDataLancamento" id="formDataLancamento" formControlName="formDataLancamento" [value]="form.get('formDataLancamento')">
                  </div>

                  <div class="col-lg-5">
                    <label class="form-label" for="formValorLancamento">Valor</label>
                    <input type="text" id="formValorLancamento" formControlName="formValorLancamento" [value]="form.get('formValorLancamento')"
                      name="formValorLancamento" class="form-control form-control-lg" currencyMask/>
                    <app-field-error-message [control]="form.get('formValorLancamento')"
                      label="Valor"></app-field-error-message>
                  </div>
              </div>

              <div class="row top02">
                <div class="col-lg-6">
                <label class="form-label" for="formTipoLancamento">Tipo Lancto</label>
                <select id="formTipoLancamento" formControlName="formTipoLancamento" name="formTipoLancamento" class="form-select">
                    <option value="0" disabled selected>Selecione...</option>
                    <option *ngFor="let tipo of listaTipoLancamentoOriginal" [value]="tipo.CodigoTipoLancamento">{{ tipo.NomeTipoLancamento }} </option>
                </select> 
              </div>
                <div class="col-lg-3">
                  <label class="form-label" style="width: 100%;">Nro Lançamento</label>
                  <input type="text" id="formNumeroLancamento" formControlName="formNumeroLancamento" [value]="form.get('formNumeroLancamento')"
                    name="formNumeroLancamento" class="form-control form-control-lg" />
                </div>

                <div class="col-lg-3">
                  <label class="form-label" for="formNumeroParcela">Nro Parcelas</label>
                  <input type="text" id="formNumeroParcela" formControlName="formNumeroParcela" [value]="form.get('formNumeroParcela')"
                    name="formNumeroParcela" class="form-control form-control-lg" />

                </div>
            </div>

            <div class="form-outline">
              <label class="form-check-label" for="formRecebeuBoleto">Recebeu Boleto: </label>
               <span class="d-inline"> <ui-switch name="formRecebeuBoleto" id="formRecebeuBoleto" formControlName="formRecebeuBoleto"
                    (click)="toggleRecebeuBoleto();" size="medium"></ui-switch></span> 
               <label class="form-check-label" for="formCompensado" style="margin-left: 5px;">Compensado: </label>
               <span class="d-inline"> <ui-switch name="formCompensado" id="formCompensado" formControlName="formCompensado"
                    (click)="toggleCompensado();" size="medium"></ui-switch></span> 
               <label class="form-check-label" for="formEstoqueAntigo" style="margin-left: 5px;">Estoque Antigo: </label>
               <span class="d-inline"> <ui-switch name="formEstoqueAntigo" id="formEstoqueAntigo" formControlName="formEstoqueAntigo"
                    (click)="toggleEstoqueAntigo();" size="medium"></ui-switch></span> 
           </div>
           <div class="form-outline">
            <label class="form-label" for="formAssociado">Associado</label>
            <select id="formAssociado" formControlName="formAssociado" name="formAssociado" class="form-select">
                <option value="0" disabled selected>Selecione...</option>
                <option *ngFor="let socio of listaAssociadoOriginal" [value]="socio.CodigoSocio">{{ socio.NomeSocio }} </option>
            </select> 
            </div>
        <app-field-error-message [control]="form.get('formAssociado')" label="Associado"></app-field-error-message>
              </div>              
              <div class="top2">
                <div class="table-responsive linha-de-botoes">
                  <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
                  <button [disabled]="form.invalid" type="button" class="btn btn-primary" 
                  (click)="SalvaLancamento();">Salvar </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
