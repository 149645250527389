
export class Profissional {
    Codigo: number;
    Nome: string;
    Funcao: string;
    Mesa: string;
    Parecer: string;
    

    constructor() {
        this.Codigo = 0;
        this.Nome = '';
        this.Funcao = '';
        this.Mesa = '';
        this.Parecer = '';

    }

    carregar(objeto: any): void {
        this.Codigo = objeto.Nome || 0;
        this.Nome = objeto.Nome || '';
        this.Funcao = objeto.Funcao || '';
        this.Mesa = objeto.Mesa || '';
        this.Parecer = objeto.Parecer || '';
    }

    toJson(): any {
        return {
            Codigo: this.Codigo,
            Nome: this.Nome,
            Funcao: this.Funcao,
            Mesa: this.Mesa,
            Parecer: this.Parecer
        };
    }

    static saoDiferentes(dados1: Profissional, dados2: Profissional): boolean {
        return dados1.Codigo !== dados2.Codigo ||
        dados1.Nome !== dados2.Nome ||
        dados1.Funcao !== dados2.Funcao ||
        dados1.Mesa !== dados2.Mesa ||
        dados1.Parecer !== dados2.Parecer;
    }
}
