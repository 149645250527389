<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-lg-12">
          <div class="card-title-form"> {{ orcamento.IdOrcamento === 0 ? 'Editar' : 'Adicionar' }} Orçamento
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
              [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
              aria-controls="offcanvasExample">
              <i class="fa-solid fa-circle-question"></i>
            </span>
          </div>

          <div class="card-body-form">
            <form [formGroup]="form">
             <div class="row">
                  <!-- Plano de Contas -->
                    <div class="col-md-6 mb-3">
                        <label class="form-label" for="formPlanoContas">Plano de Contas</label>
                        <select id="formPlanoContas" formControlName="formPlanoContas" name="formPlanoContas" class="form-select">
                            <option value="" disabled selected>Selecione...</option>
                            <option *ngFor="let conta of listaPlanoContaOriginal" [value]="conta">
                                {{ conta.CodigoPlanoConta }} - {{ conta.DescricaoPlanoConta }}
                            </option>
                        </select>
                    </div>

                <!-- Situação -->
                    <div class="col-md-6 mb-3">
                        <label class="form-label" for="formSituacao">Situação</label>
                        <select id="formSituacao" formControlName="formSituacao" name="formSituacao" class="form-select">
                            <option value="A">Aberto</option>
                            <option value="E">Encerrado</option>
                        </select>
                    </div>
                
                    <!-- Complemento -->    
                <div class="form-outline">
                  <label class="form-label" for="formComplemento">Complemento</label>
                  <input type="text" id="formComplemento" formControlName="formComplemento" [value]="form.get('formComplemento')"
                    name="formComplemento" class="form-control form-control-lg" />
                  <app-field-error-message [control]="form.get('formComplemento')"
                    label="descrição complementar"></app-field-error-message>
                </div>
                 <!-- Data da Compra -->       
                  <div class="row top02">
                    <div class="col-lg-6">
                      <label class="form-label" style="width: 100%;">Data Compra</label>
                      <input style="display: block"  class="form-control form-control-lg" bsDatepicker type="text"
                      (bsValueChange)="AlteraData($event , 'orcamento.Data');"
                      [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false }"
                      name="formDataCompra" id="formDataCompra" formControlName="formDataCompra" [value]="form.get('formDataCompra')">
                    </div>
                 <!-- Valor da Compra -->
                    <div class="col-lg-6">
                      <label class="form-label" for="formValorCompra">Valor Compra</label>
                      <input type="text" id="formValorCompra" formControlName="formValorCompra" [value]="form.get('formValorCompra')"
                        name="formValorCompra" class="form-control form-control-lg" currencyMask/>
                      <app-field-error-message [control]="form.get('formValorCompra')"
                        label="Valor"></app-field-error-message>
                    </div>
                </div>
                <div class="border-box"> </div>  
                <!------- Empresa 1 -------->
                <div class="form-outline">
                    <label class="form-label" for="formEmpresa1">Empresa 1</label>
                    <select id="formEmpresa1" formControlName="formEmpresa1" name="formEmpresa1" class="form-select">
                        <option value="" selected>Selecione...</option>
                        <option *ngFor="let fornecedor of listaFornecedorOriginal" [value]="fornecedor.NomeSocio">{{ fornecedor.NomeSocio }} </option>
                    </select> 
                </div>
                 <!-- Pessoa 1 -->
                <div class="col-md-3 mb-3">
                    <label class="form-label" for="formPessoa1">Pessoa 1</label>
                    <input type="text" id="formPessoa1" formControlName="formPessoa1" name="formPessoa1" class="form-control form-control-lg" />
                    <app-field-error-message [control]="form.get('formPessoa1')" label="Pessoa 1"></app-field-error-message>
                </div>

                <!-- Valor 1 -->
                <div class="col-md-2 mb-3">
                    <label class="form-label" for="formValor1">Valor 1</label>
                    <input type="text" id="formValor1" formControlName="formValor1" name="formValor1" 
                        class="form-control form-control-lg" currencyMask/>
                    <app-field-error-message [control]="form.get('formValor1')" label="Valor 1"></app-field-error-message>
                </div>

                <!-- Condição 1 -->
                <div class="col-md-3 mb-3">
                    <label class="form-label" for="formCondicao1">Condições 1</label>
                    <input type="text" id="formCondicao1" formControlName="formCondicao1" name="formCondicao1" class="form-control form-control-lg" />
                    <app-field-error-message [control]="form.get('formCondicao1')" label="Condição 1"></app-field-error-message>
                </div>

                <!-- Fone 1 -->
                <div class="col-md-4 mb-1">
                    <div class="col-md-12 mb-2 d-flex align-items-center">
                        <!-- Campo para o DDD -->
                        <div class="me-2"  style="flex: 0 0 60px;">
                            <label class="form-label" for="formDDD1">Fone 1</label>
                            <input type="text" id="formDDD1" formControlName="formDDD1" name="formDDD1" class="form-control form-control-lg"
                                   maxlength="2" placeholder="DDD" />
                        </div>
                        
                        <!-- Campo para o número de telefone -->
                        <div class="flex-grow-1">
                            <label class="form-label" for="formNumero1" style="visibility: hidden;">Número</label> <!-- Esconde o segundo label visualmente -->
                            <input type="text" id="formNumero1" formControlName="formNumero1" name="formNumero1" class="form-control form-control-lg"
                                   maxlength="10" placeholder="Número" />
                            <app-field-error-message [control]="form.get('formNumero1')" label="Número de telefone"></app-field-error-message>
                        </div>
                    </div>
                </div>
                <!-- Observação Empresa 1 -->
                  <div class="form-outline ">
                    <label class="form-label" for="formObservacao1">Observações Empresa 1</label>
                    <input type="text" id="formObservacao1" formControlName="formObservacao1" [value]="form.get('formObservacao1')"
                      name="formObservacao1" class="form-control form-control-lg" />
                    <app-field-error-message [control]="form.get('formObservacao1')"
                      label="descrição complementar"></app-field-error-message>
                  </div>
                  <div class="border-box"> </div>
                <!------- Empresa 2 -------->
                <div class="form-outline">
                    <label class="form-label" for="formEmpresa2">Empresa 2</label>
                    <select id="formEmpresa2" formControlName="formEmpresa2" name="formEmpresa2" class="form-select">
                        <option value="" selected>Selecione...</option>
                        <option *ngFor="let fornecedor of listaFornecedorOriginal" [value]="fornecedor.NomeSocio">{{ fornecedor.NomeSocio }} </option>
                    </select> 

                </div>
                 <!-- Pessoa 2 -->
                <div class="col-md-3 mb-3">
                    <label class="form-label" for="formPessoa2">Pessoa 2</label>
                    <input type="text" id="formPessoa2" formControlName="formPessoa2" name="formPessoa1" class="form-control form-control-lg" />
                    <app-field-error-message [control]="form.get('formPessoa2')" label="Pessoa 2"></app-field-error-message>
                </div>

                <!-- Valor 2 -->
                <div class="col-md-2 mb-3">
                    <label class="form-label" for="formValor2">Valor 2</label>
                    <input type="text" id="formValor2" formControlName="formValor2" name="formValor2" 
                        class="form-control form-control-lg" currencyMask/>
                    <app-field-error-message [control]="form.get('formValor2')" label="Valor 2"></app-field-error-message>
                </div>

                <!-- Condição 2 -->
                <div class="col-md-3 mb-3">
                    <label class="form-label" for="formCondicao2">Condições 2</label>
                    <input type="text" id="formCondicao2" formControlName="formCondicao2" name="formCondicao2" class="form-control form-control-lg" />
                    <app-field-error-message [control]="form.get('formCondicao2')" label="Condição 2"></app-field-error-message>
                </div>

                <!-- Fone 2 -->
                <div class="col-md-4 mb-1">
                    <div class="col-md-12 mb-2 d-flex align-items-center">
                        <!-- Campo para o DDD -->
                        <div class="me-2"  style="flex: 0 0 60px;">
                            <label class="form-label" for="formDDD2">Fone 2</label>
                            <input type="text" id="formDDD2" formControlName="formDDD2" name="formDDD2" class="form-control form-control-lg"
                                   maxlength="2" placeholder="DDD" />
                        </div>
                        
                        <!-- Campo para o número de telefone -->
                        <div class="flex-grow-1">
                            <label class="form-label" for="formNumero2" style="visibility: hidden;">Número</label> <!-- Esconde o segundo label visualmente -->
                            <input type="text" id="formNumero2" formControlName="formNumero2" name="formNumero2" class="form-control form-control-lg"
                                   maxlength="10" placeholder="Número" />
                            <app-field-error-message [control]="form.get('formNumero2')" label="Número de telefone"></app-field-error-message>
                        </div>
                    </div>
                </div>
                <!-- Observação Empresa 2 -->
                  <div class="form-outline">
                    <label class="form-label" for="formObservacao2">Observações Empresa 2</label>
                    <input type="text" id="formObservacao2" formControlName="formObservacao2" [value]="form.get('formObservacao2')"
                      name="formObservacao2" class="form-control form-control-lg" />
                    <app-field-error-message [control]="form.get('formObservacao2')"
                      label="descrição complementar"></app-field-error-message>
                  </div>        
                  <div class="border-box"> </div>
              <!------- Empresa 3 -------->
              <div class="form-outline">
                <label class="form-label" for="formEmpresa3">Empresa 3</label>
                <select id="formEmpresa3" formControlName="formEmpresa3" name="formEmpresa3" class="form-select">
                    <option value="" selected>Selecione...</option>
                    <option *ngFor="let fornecedor of listaFornecedorOriginal" [value]="fornecedor.NomeSocio">{{ fornecedor.NomeSocio }} </option>
                </select> 

            </div>
             <!-- Pessoa 3 -->
            <div class="col-md-3 mb-3">
                <label class="form-label" for="formPessoa3">Pessoa 3</label>
                <input type="text" id="formPessoa3" formControlName="formPessoa3" name="formPessoa1" class="form-control form-control-lg" />
                <app-field-error-message [control]="form.get('formPessoa3')" label="Pessoa 3"></app-field-error-message>
            </div>

            <!-- Valor 3 -->
            <div class="col-md-2 mb-3">
                <label class="form-label" for="formValor3">Valor 3</label>
                <input type="text" id="formValor3" formControlName="formValor3" name="formValor3" 
                    class="form-control form-control-lg" currencyMask/>
                <app-field-error-message [control]="form.get('formValor3')" label="Valor 3"></app-field-error-message>
            </div>

            <!-- Condição 3 -->
            <div class="col-md-3 mb-3">
                <label class="form-label" for="formCondicao3">Condições 3</label>
                <input type="text" id="formCondicao3" formControlName="formCondicao3" name="formCondicao3" class="form-control form-control-lg" />
                <app-field-error-message [control]="form.get('formCondicao3')" label="Condição 3"></app-field-error-message>
            </div>

            <!-- Fone 3 -->
            <div class="col-md-4 mb-1">
                <div class="col-md-12 mb-2 d-flex align-items-center">
                    <!-- Campo para o DDD -->
                    <div class="me-2"  style="flex: 0 0 60px;">
                        <label class="form-label" for="formDDD2">Fone 3</label>
                        <input type="text" id="formDDD3" formControlName="formDDD3" name="formDDD3" class="form-control form-control-lg"
                               maxlength="2" placeholder="DDD" />
                    </div>
                    
                    <!-- Campo para o número de telefone -->
                    <div class="flex-grow-1">
                        <label class="form-label" for="formNumero3" style="visibility: hidden;">Número</label> <!-- Esconde o segundo label visualmente -->
                        <input type="text" id="formNumero3" formControlName="formNumero3" name="formNumero3" class="form-control form-control-lg"
                               maxlength="10" placeholder="Número" />
                        <app-field-error-message [control]="form.get('formNumero3')" label="Número de telefone"></app-field-error-message>
                    </div>
                </div>
            </div>
            <!-- Observação Empresa 3 -->
              <div class="form-outline">
                <label class="form-label" for="formObservacao3">Observações Empresa 3</label>
                <input type="text" id="formObservacao3" formControlName="formObservacao3" [value]="form.get('formObservacao3')"
                  name="formObservacao3" class="form-control form-control-lg" />
                <app-field-error-message [control]="form.get('formObservacao3')"
                  label="descrição complementar"></app-field-error-message>
              </div>        
              <div class="border-box"> </div>  

              <div class="top1">
                <div class="table-responsive linha-de-botoes">
                  <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
                  <button [disabled]="form.invalid" type="button" class="btn btn-primary" 
                  (click)="SalvaOrcamento();">Salvar</button>
                </div>
            </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
