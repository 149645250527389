import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable(
    { providedIn: 'root' }
)
export class AssociadoEdicaoService {

    constructor(
    ) {
    }

    EdicaoPrio = new FormGroup({
      CodigoSocio: new FormControl({ value: 0, disabled: false }, { nonNullable: true }),
      NomeSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.required, Validators.maxLength(80)] }),
      CodigoCategoria: new FormControl({ value: 0, disabled: false }, { nonNullable: true }),
      CodigoCategoriaOri: new FormControl({ value: 0, disabled: false }, { nonNullable: true }),
      CodigoCategoria2: new FormControl({ value: 0, disabled: false }, { nonNullable: true }),
      Telefone1Socio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(15)] }),
      Telefone2Socio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(15)] }),
      Telefone3Socio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(15)] }),
      CPF_CNPJSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(20)]  }),
      EmailSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(100)]  }),
      UsuarioSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(30)]  }),
      SenhaSocioOriginal: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(32)]  }),
      SenhaSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(32)]  }),
      NovaSenhaSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(32)]  }),
      ConfirmaSenhaSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(32)]  }),
      PerfilSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(4)]  }),
      AtivoSocio: new FormControl({ value: false, disabled: false }, { nonNullable: true }),
      BolsistaSocio: new FormControl({ value: false, disabled: false }, { nonNullable: true }),
      BolsaSuspensaSocio: new FormControl({ value: false, disabled: false }, { nonNullable: true }),
      AcoesAfirmativasSocio: new FormControl({ value: false, disabled: false }, { nonNullable: true }),
      TipoSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(120)]}),
      DataNascimentoSocio: new FormControl({ value: new Date(), disabled: false }, { nonNullable: true }),
      CodigoAreaAtuacao: new FormControl({ value: 0, disabled: false }, { nonNullable: true }),
      DescontoSalaSocio: new FormControl({ value: false, disabled: false }, { nonNullable: true }),
      NrInscrProfSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(50)] }),
      RGSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(20)] }),
      EmailAltSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(100)] }),
      NomeEmerSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(80)] }),
      TelefoneEmerSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(15)] }),
      DataIngressoSocio: new FormControl({ value: new Date(), disabled: false }, { nonNullable: true }),
      DataDesligamentoSocio: new FormControl({ value: new Date(), disabled: false }, { nonNullable: true }),
      DataRetornoSocio: new FormControl({ value: new Date(), disabled: false }, { nonNullable: true }),
      ObservacoesSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true }),
      IngressoFormacaoSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true })
    })

    EdicaoEndereco = new FormGroup({
      EnderecoSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(120)] }),
      ComplementoSocio: new FormControl<any>({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(40)] }),
      BairroSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(40)] }),
      CidadeSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(40)] }),
      UFSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(2)] }),
      CepSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(15)] }),
      TipoEnderecoSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(1)] }),
      EnderecoSocio2: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(120)] }),
      ComplementoSocio2: new FormControl<any>({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(40)] }),
      BairroSocio2: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(40)] }),
      CidadeSocio2: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(40)] }),
      UFSocio2: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(2)] }),
      CepSocio2: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(15)] }),
      TipoEnderecoSocio2: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(1)] }),
      CodigoSocioEnc: new FormControl({ value: 0, disabled: false }, { nonNullable: true }),
      NomeSocioEnc: new FormControl({ value: '', disabled: false }, { nonNullable: true }),
      ArqAssinaturaSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(120)]}),
      ArqAssinaturaSocioBase64: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(120)]}),
    })

    Atendimento = new FormGroup({
      LugarFila: new FormControl({ value: 0, disabled: false }, { nonNullable: true }),
      LugarFilaCri: new FormControl({ value: 0, disabled: false }, { nonNullable: true }),
      LugarFilaOri: new FormControl({ value: 0, disabled: false }, { nonNullable: true }),
      LimiteAtendimentoSocio: new FormControl({ value: 0, disabled: false }, { nonNullable: true }),
      LimiteAtendimentoSocioCri: new FormControl({ value: 0, disabled: false }, { nonNullable: true }),
      LimiteRetIndSocio: new FormControl({ value: 0, disabled: false }, { nonNullable: true }),
      DisponivelSocio: new FormControl({ value: false, disabled: false }, { nonNullable: true }),
      TipoAtendimentoSocio: new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: [Validators.maxLength(2)] }),
      DataAfastIni: new FormControl({ value: new Date(), disabled: false }, { nonNullable: true }),
      DataAfastFim: new FormControl({ value: new Date(), disabled: false }, { nonNullable: true }),
    })

}
