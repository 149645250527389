import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RetListaSeminariosComPart , RetEnsinoNomeComAssociados } from './models/ret-ensino';
import { RetSocioResumoLista } from '../associados/models/ret-associados';
import { AuthStorageService } from '../../Projeto/Autenticacao/auth-storage.service';

import { RetError } from 'src/app/Projeto/models/ret-error';

@Injectable({
  providedIn: 'root'
})

export class EnsinoService {

  private readonly baseURL: string = '';
  constructor(private httpClient: HttpClient, private router: Router, private authStorageService: AuthStorageService) {
    this.baseURL = `${environment.EnsinoParticipanteWS}`;
  }

  GetListaSeminariosComPartAbertos(): Observable<RetListaSeminariosComPart> {

    const url = `${this.baseURL}/GetListaSeminariosComPartAbertos`;
    return this.httpClient.get<RetListaSeminariosComPart>(url).pipe(
      take(1),
      map((response: RetListaSeminariosComPart) => {
        return response;
      })
    );

  }

  ListaFaltaFazerSeminario(CodigoEnsinoNome: number): Observable<RetSocioResumoLista> {
    const params = new HttpParams()
    .set('CodigoEnsinoNome', CodigoEnsinoNome)

    const url = `${this.baseURL}/ListaFaltaFazerSeminario`;
    return this.httpClient.get<RetSocioResumoLista>(url,{ "params": params }).pipe(
      take(1),
      map((response: RetSocioResumoLista) => {
        return response;
      })
    );

  }

  ListaSeminarioFeitoAssociado(CodigoSocio: number): Observable<RetEnsinoNomeComAssociados> {

    const params = new HttpParams()
    .set('CodigoSocio', CodigoSocio)

    const url = `${this.baseURL}/ListaSeminarioFeitoAssociado`;
    return this.httpClient.get<RetEnsinoNomeComAssociados>(url,
      { "params": params }).pipe(
      take(1),
      map((response: RetEnsinoNomeComAssociados) => {
        return response;
      })
    );

  }


}
