import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, take } from 'rxjs';

import { NgxSpinnerService } from 'ngx-spinner';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';
import { MessageService } from '../../../Projeto/message/message.service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { AssociadosService } from '../associados.service';
import { Associado, SocioResumo } from '../models/associados';
import { AreaAtuacao } from '../../areaatuacao/models/areaatuacao';
import { Categoria } from '../../categoria/models/categoria';
import { ExcelService } from 'src/app/excel.service';
import { AssociadoEdicaoService } from '../associadosedicao.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RelatoriosService } from '../../Relatorios/relatorios.service';
import { ParametrosRecord } from '../../Relatorios/models/ret-relatorio';

@Component({
  selector: 'app-associados-lista',
  templateUrl: './associados-lista.component.html',
  styleUrls: ['./associados-lista.component.css'],
})
export class AssociadosListaComponent implements OnInit {
  constructor(
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    public modalService: BsModalService,
    private associadosService: AssociadosService,
    private associadosEdicao: AssociadoEdicaoService,
    private utilsservice: UtilsService,
    private excelService: ExcelService,
    private relService: RelatoriosService
  ) {}

  //Variaveis - Detalhes dentro do ngx-datatable
  mensagem: string = '';
  @ViewChild('myTable') table: any;
  associados: SocioResumo[] = [];
  associadosOri: SocioResumo[] = [];
  DadosExcell: any = [];
  ColunasExcell: any = [];
  AreaAtuacaoObj: AreaAtuacao = new AreaAtuacao();
  CategoriaObj: Categoria = new Categoria();
  BolsistaSocio: boolean = false;
  AcoesAfirmativasSocio: boolean = false;
  associado: Associado = new Associado();
  nomeAssociado: string = '';
  codigoAssociado: number = 0;

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.',
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  modalRef?: BsModalRef;
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.ListaGeral();
  }

  ListaGeral(): void {
    this.spinner.show();
    try {
      this.associadosService
        .ListaGeral(
          this.AreaAtuacaoObj.CodigoAreaAtuacao,
          this.CategoriaObj.CodigoCategoria,
          this.BolsistaSocio,
          this.AcoesAfirmativasSocio
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.associados = this.associadosOri = response.SociosResumo;
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  //Rotinas - Detalhes dentro do ngx-datatable
  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    // filter our data
    const temp = this.associadosOri.filter(function (d: any) {
      return (
        d.NomeSocio.toLowerCase().indexOf(val) !== -1 ||
        d.Categoria.toLowerCase().indexOf(val) !== -1 ||
        d.AreaAtuacao.toLowerCase().indexOf(val) !== -1 ||
        !val
      );
    });
    // update the rows
    this.associados = temp;
    return true;
  }

  toggleExpandRow(row: any) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  changeArea($event: AreaAtuacao) {
    this.AreaAtuacaoObj = $event;
  }

  changeCategoria($event: Categoria) {
    this.CategoriaObj = $event;
  }

  changeBolsista($event: any) {
    this.BolsistaSocio = $event;
  }

  changeAcoesAfirmativas($event: any) {
    this.AcoesAfirmativasSocio = $event;
  }

  Edicao(CodSocio: number) {
    this.spinner.show();

    try {
      forkJoin([
        this.associadosService.GetAssociadoByCodigo(CodSocio)
      ])
        .pipe(take(1))
        .subscribe({
          next: ([respAssoc]) => {
            this.mensagem = '';
            if (respAssoc.Error) {
              this.mensagem += respAssoc.ErrorMessage;
            } else {
              this.associado = respAssoc.Associado;
              if (this.associado.DataNascimentoSocio != null)
              { this.associado.DataNascimentoSocio = Utils.getDateUTC(respAssoc.Associado.DataNascimentoSocio); }
              if (this.associado.DataIngressoSocio != null)
              { this.associado.DataIngressoSocio = Utils.getDateUTC(respAssoc.Associado.DataIngressoSocio); }
              if (this.associado.DataDesligamentoSocio != null)
              { this.associado.DataDesligamentoSocio = Utils.getDateUTC(respAssoc.Associado.DataDesligamentoSocio); }
              if (this.associado.DataRetornoSocio != null)
              { this.associado.DataRetornoSocio = Utils.getDateUTC(respAssoc.Associado.DataRetornoSocio); }

            }
            this.CarregaForms();
            this.associado.SenhaSocio = '';
            this.spinner.hide();
            if (this.mensagem != '') {
              this.messageService.showAlertDanger(this.mensagem);
            }
            else
            {
              this.router.navigateByUrl('/cadastrogeral/edicao', {
                state: { associado: this.associado, Origem: 'LISTA' },
              });
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }

  }

  Adicionar() {
    this.spinner.show();
    this.associado = new Associado();
    this.associado.CodigoSocio = -1;
    this.associado.DataIngressoSocio = new Date();
    this.CarregaForms();
    this.spinner.hide();
    this.router.navigateByUrl('/cadastrogeral/edicao', {
      state: { associado: this.associado, Origem: 'LISTA' },
    });

  }

  CarregaForms() {

    this.associadosEdicao.EdicaoPrio.controls['CodigoSocio'].setValue(
      this.associado.CodigoSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['NomeSocio'].setValue(
      this.associado.NomeSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['CodigoCategoria'].setValue(
      this.associado.CodigoCategoria
    );
    this.associadosEdicao.EdicaoPrio.controls['CodigoCategoriaOri'].setValue(
      this.associado.CodigoCategoria
    );
    this.associadosEdicao.EdicaoPrio.controls['CodigoCategoria2'].setValue(
      this.associado.CodigoCategoria2
    );
    this.associadosEdicao.EdicaoPrio.controls['Telefone1Socio'].setValue(
      this.associado.Telefone1Socio
    );
    this.associadosEdicao.EdicaoPrio.controls['Telefone2Socio'].setValue(
      this.associado.Telefone2Socio
    );
    this.associadosEdicao.EdicaoPrio.controls['Telefone3Socio'].setValue(
      this.associado.Telefone3Socio
    );
    this.associadosEdicao.EdicaoPrio.controls['CPF_CNPJSocio'].setValue(
      this.associado.CPF_CNPJSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['EmailSocio'].setValue(
      this.associado.EmailSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['UsuarioSocio'].setValue(
      this.associado.UsuarioSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['SenhaSocio'].setValue(
      this.associado.SenhaSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['SenhaSocioOriginal'].setValue(
      this.associado.SenhaSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['NovaSenhaSocio'].setValue('');
    this.associadosEdicao.EdicaoPrio.controls['ConfirmaSenhaSocio'].setValue('');
    this.associadosEdicao.EdicaoPrio.controls['PerfilSocio'].setValue(
      this.associado.PerfilSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['AtivoSocio'].setValue(
      this.associado.AtivoSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['BolsistaSocio'].setValue(
      this.associado.BolsistaSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['BolsaSuspensaSocio'].setValue(
      this.associado.BolsaSuspensaSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['AcoesAfirmativasSocio'].setValue(
      this.associado.AcoesAfirmativasSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['DescontoSalaSocio'].setValue(
      this.associado.DescontoSalaSocio
    );
    this.associadosEdicao.EdicaoEndereco.controls['ArqAssinaturaSocio'].setValue(
      this.associado.ArqAssinaturaSocio
    );
    this.associadosEdicao.EdicaoEndereco.controls['ArqAssinaturaSocioBase64'].setValue(
      this.associado.ArqAssinaturaSocioBase64
    );
    this.associadosEdicao.EdicaoPrio.controls['TipoSocio'].setValue(
      this.associado.TipoSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['DataNascimentoSocio'].setValue(
      this.associado.DataNascimentoSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['CodigoAreaAtuacao'].setValue(
      this.associado.CodigoAreaAtuacao
    );
    this.associadosEdicao.EdicaoPrio.controls['NrInscrProfSocio'].setValue(
      this.associado.NrInscrProfSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['RGSocio'].setValue(
      this.associado.RGSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['EmailAltSocio'].setValue(
      this.associado.EmailAltSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['NomeEmerSocio'].setValue(
      this.associado.NomeEmerSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['TelefoneEmerSocio'].setValue(
      this.associado.TelefoneEmerSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['DataIngressoSocio'].setValue(
      this.associado.DataIngressoSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['DataDesligamentoSocio'].setValue(
      this.associado.DataDesligamentoSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['DataRetornoSocio'].setValue(
      this.associado.DataRetornoSocio
    );
    this.associadosEdicao.EdicaoPrio.controls['IngressoFormacaoSocio'].setValue(
      this.associado.IngressoFormacaoSocio
    );

    /**********************************************************************************/

    this.associadosEdicao.EdicaoEndereco.controls['EnderecoSocio'].setValue(
      this.associado.EnderecoSocio
    );
    this.associadosEdicao.EdicaoEndereco.controls['ComplementoSocio'].setValue(
      this.associado.ComplementoSocio
    );
    this.associadosEdicao.EdicaoEndereco.controls['BairroSocio'].setValue(
      this.associado.BairroSocio
    );
    this.associadosEdicao.EdicaoEndereco.controls['CidadeSocio'].setValue(
      this.associado.CidadeSocio
    );
    this.associadosEdicao.EdicaoEndereco.controls['UFSocio'].setValue(
      this.associado.UFSocio
    );
    this.associadosEdicao.EdicaoEndereco.controls['CepSocio'].setValue(
      this.associado.CepSocio
    );
    this.associadosEdicao.EdicaoEndereco.controls['TipoEnderecoSocio'].setValue(
      this.associado.TipoEnderecoSocio
    );
    this.associadosEdicao.EdicaoEndereco.controls['EnderecoSocio2'].setValue(
      this.associado.EnderecoSocio2
    );
    this.associadosEdicao.EdicaoEndereco.controls['ComplementoSocio2'].setValue(
      this.associado.ComplementoSocio2
    );
    this.associadosEdicao.EdicaoEndereco.controls['BairroSocio2'].setValue(
      this.associado.BairroSocio2
    );
    this.associadosEdicao.EdicaoEndereco.controls['CidadeSocio2'].setValue(
      this.associado.CidadeSocio2
    );
    this.associadosEdicao.EdicaoEndereco.controls['UFSocio2'].setValue(
      this.associado.UFSocio2
    );
    this.associadosEdicao.EdicaoEndereco.controls['CepSocio2'].setValue(
      this.associado.CepSocio2
    );
    this.associadosEdicao.EdicaoEndereco.controls['TipoEnderecoSocio2'].setValue(
      this.associado.TipoEnderecoSocio2
    );
    this.associadosEdicao.EdicaoPrio.controls['ObservacoesSocio'].setValue(
      this.associado.ObservacoesSocio
    );
    this.associadosEdicao.EdicaoEndereco.controls['CodigoSocioEnc'].setValue(
      this.associado.CodigoSocioEnc
    );
    this.associadosEdicao.EdicaoEndereco.controls['NomeSocioEnc'].setValue(
      this.associado.NomeSocioEnc
    );

  }

  generateExcell() {
    var nomeArquivo: string = 'RelatorioCadastroAssociados';
    var Titulo: string = 'Relatório Cadastro Geral';

    this.ColunasExcell = [];
    this.ColunasExcell.push('Nome');
    this.ColunasExcell.push('Categoria');
    this.ColunasExcell.push('Área Atuação');
    this.ColunasExcell.push('E-mail');
    this.ColunasExcell.push('Ativo');
    this.ColunasExcell.push('Usuário');
    this.ColunasExcell.push('Perfil');
    this.ColunasExcell.push('Data Nascimento');
    this.ColunasExcell.push('Telefone 1');
    this.ColunasExcell.push('Telefone 2');
    this.ColunasExcell.push('Telefone 3');
    this.ColunasExcell.push('Endereço');
    this.ColunasExcell.push('Bairro');
    this.ColunasExcell.push('Cidade');
    this.ColunasExcell.push('UF');
    this.ColunasExcell.push('CEP');
    this.ColunasExcell.push('Tipo Endereço');
    this.ColunasExcell.push('Observações');
    this.DadosExcell = [];

    this.associados.forEach((item: SocioResumo) => {
       const varExcell: any = [];
       varExcell.push(item.NomeSocio);
       varExcell.push(item.Categoria);
       varExcell.push(item.AreaAtuacao);
       varExcell.push(item.EmailSocio);
       varExcell.push(item.AtivoSocio == true ? 'Sim' : 'Não');
       varExcell.push(item.UsuarioSocio);
       varExcell.push(item.PerfilSocio);
       varExcell.push(Utils.getDateddMMyyy(item.DataNascimentoSocio) == '01/01/1900' ? '' : Utils.getDateddMMyyy(item.DataNascimentoSocio));
       varExcell.push(item.Telefone1Socio);
       varExcell.push(item.Telefone2Socio);
       varExcell.push(item.Telefone3Socio);
       varExcell.push(item.EnderecoSocio);
       varExcell.push(item.Bairro);
       varExcell.push(item.Cidade);
       varExcell.push(item.UF);
       varExcell.push(item.CepSocio);
       varExcell.push(item.TipoEnderecoSocio);
       varExcell.push(item.ObservacoesSocio);

       this.DadosExcell.push(varExcell);
     });
     this.excelService.generateExcel(
       nomeArquivo,
       Titulo,
       this.ColunasExcell,
       this.DadosExcell
     );
  }

  openModalExportar(
    templateModal: TemplateRef<any>,
    CodAssoc: number,
    nomeAssoc: string
  ): void {
    this.nomeAssociado = nomeAssoc;
    this.codigoAssociado = CodAssoc;
    this.modalRef = this.modalService.show(templateModal, {
      class: 'modal-dialog-centered',
    });
  }

  ExcluiAssociadoByCodigo()
  {
    this.modalRef?.hide();
    this.spinner.show();
    try {
      this.associadosService.ExcluiAssociadoByCodigo(this.codigoAssociado)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            }
            this.ListaGeral();
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  closeModal(): void {
    this.modalRef?.hide();
  }

  Disponibilidade(CodSocio: number) {

    this.router.navigateByUrl('/cadastrogeral/edicaodisp', {
      state: { CodigoSocio: CodSocio },
    });

  }

  Mensalidade(CodSocio: number, NomeSocio: string) {

    this.router.navigateByUrl('/cadastrogeral/listamens', {
      state: { CodigoSocio: CodSocio , NomeSocio: NomeSocio },
    });

  }

  Historico(CodSocio: number)
  {

    let relatorio: ParametrosRecord = new ParametrosRecord;
    relatorio.NomeRelatorio = "Historico";
    relatorio.CodigoIdentificacao01 = CodSocio;

    this.spinner.show();
    try {
      this.relService.GeraRelatorios(relatorio)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            }
            else
            {
                Utils.downloadFileBase64(relatorio.NomeRelatorio + '_' + relatorio.CodigoIdentificacao01.toString() + '_'  + Utils.getDateddMMyyyyHhmmss(new Date()) + '.xls', response.RelBase64);
            }
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

}
