import { Component, OnInit } from '@angular/core';
import { AuthStorageService } from '../../Autenticacao/auth-storage.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  constructor(private router: Router,public authStorageService: AuthStorageService) { }

  ngOnInit() {
  }

  ocupacao() {
    this.router.navigateByUrl('/salas/ocupacao');
  }

  rotinas() {
    this.router.navigateByUrl('/administrativo/rotinas');
  }

  dashboard() {
    this.router.navigateByUrl('/administrativo/dashboard');
  }

  // criacaoseminarios() {
  //   this.router.navigateByUrl('/ensino/montaseminario');
  // }


}
