<app-header></app-header>

<div class="content-wrapper">
    <div class="container-fluid">
      <div class="container">
        <div class="row d-flex align-items-center justify-content-center h-100">
          <div class="col-lg-12">
            <div class="card-title-form"> Atividade
              <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
                [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
                aria-controls="offcanvasExample">
                <i class="fa-solid fa-circle-question"></i>
              </span>
            </div>
  
            <div class="card-body-form">
              <form [formGroup]="form">
               <div class="row">
                    <!-- Plano de Contas -->
                      <div class="col-md-6 mb-3">
                          <label class="form-label" for="formPlanoContas">Administrativo</label>
                          <select id="formPlanoContas" formControlName="formPlanoContas" name="formPlanoContas" class="form-select">
                              <option value="" disabled selected>Selecione...</option>

                          </select>
                      </div>
  
                  <!-- Situação -->
                      <div class="col-md-3 mb-3">
                          <label class="form-label" for="formSituacao">Situação</label>
                          <select id="formSituacao" formControlName="formSituacao" name="formSituacao" class="form-select">
                              <option value="A">Aberto</option>
                              <option value="C">Cancelado</option>
                              <option value="E">Encerrado</option>
                          </select>
                      </div>
                  <!-- Carga Horária -->    
                      <div class="col-md-3 mb-3">
                        <label class="form-label" for="formCargaHoraria">Carga Horária Total</label>
                        <input type="text" id="formCargaHoraria" formControlName="formCargaHoraria"
                          name="formCargaHoraria" class="form-control form-control-lg" />
                        <app-field-error-message [control]="form.get('formCargaHoraria')"
                          label="Carga Horária"></app-field-error-message>
                      </div>
                  
                      <!-- Título -->    
                      <div class="col-md-6 mb-6">
                    <label class="form-label" for="formTitulo">Título</label>
                    <input type="text" id="formTitulo" formControlName="formTitulo" [value]="form.get('formTitulo')"
                      name="formTitulo" class="form-control form-control-lg" />
                    <app-field-error-message [control]="form.get('formTitulo')"
                      label="Título"></app-field-error-message>
                  </div>

                    <!-- Datas -->       
                    <div class="col-md-3 mb-3">
                    <label class="form-label" style="width: 100%;">Inicio</label>
                    <input style="display: block"  class="form-control form-control-lg" bsDatepicker type="text"
                    (bsValueChange)="AlteraDataInicio($event , 'atividade.DataInicio');" 
                    [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false }"
                    name="formDataInicio" id="formDataInicio" formControlName="formDataInicio" [value]="form.get('formDataInicio')">
                  </div>
                  <div class="col-md-3 mb-3">
                      <label class="form-label" style="width: 100%;">Fim</label>
                      <input style="display: block"  class="form-control form-control-lg" bsDatepicker type="text"
                      (bsValueChange)="AlteraDataFim($event , 'atividade.DataFim');" 
                      [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false }"
                      name="formDataFim" id="formDataFim" formControlName="formDataFim" [value]="form.get('formDataFim')">
                    </div>
                        <!-- Tipo de Pagamento--> 
                    <div class="row top02">     
                        <div class="col-md-2 mb-2">
                            <label class="form-label" for="formTipoPagamento">Situação</label>
                            <select id="formTipoPagamento" formControlName="formTipoPagamento" name="formTipoPagamento" class="form-select">
                                <option value="P">Pago</option>
                                <option value="G">Gratuito</option>
                            </select>
                        </div>    
                   <!-- Valores -->
                   <div class="col-md-2 mb-2">
                        <label class="form-label" for="formValorSocio">Valor Sócio</label>
                        <input type="text" id="formValorSocio" formControlName="formValorSocio" [value]="form.get('formValorSocio')"
                          name="formValorSocio" class="form-control form-control-lg" currencyMask/>
                        <app-field-error-message [control]="form.get('formValorSocio')"
                          label="Valor sócio"></app-field-error-message>
                        </div>

                        <div class="col-md-3 mb-3">
                          <label class="form-label" for="formValorNaoSocio">Valor Não Sócio</label>
                        <input type="text" id="formValorNaoSocio" formControlName="formValorNaoSocio" [value]="form.get('formValorNaoSocio')"
                          name="formValorNaoSocio" class="form-control form-control-lg" currencyMask/>
                        <app-field-error-message [control]="form.get('formValorNaoSocio')"
                          label="Valor não sócio"></app-field-error-message>
                      </div>

                      <div class="col-md-3 mb-3">
                        <label class="form-label" for="formValorEstudanteSocio">Valor Estudante Sócio</label>
                      <input type="text" id="formValorEstudanteSocio" formControlName="formValorEstudanteSocio" [value]="form.get('formValorEstudanteSocio')"
                        name="formValorEstudanteSocio" class="form-control form-control-lg" currencyMask/>
                      <app-field-error-message [control]="form.get('formValorEstudanteSocio')"
                        label="Valor estudante sócio"></app-field-error-message>
                    </div>

                    <div class="col-md-2 mb-2">
                        <label class="form-label" for="formValorEstudante">Valor Estudante</label>
                      <input type="text" id="formValorEstudante" formControlName="formValorEstudante" [value]="form.get('formValorEstudante')"
                        name="formValorEstudante" class="form-control form-control-lg" currencyMask/>
                      <app-field-error-message [control]="form.get('formValorEstudante')"
                        label="Valor estudante"></app-field-error-message>
                    </div>
                  </div>
                   <!-- Local -->
                  <div class="col-md-8 mb-8">
                      <label class="form-label" for="formLocal">Local </label>
                      <input type="text" id="formLocal" formControlName="formLocal" name="formLocal" class="form-control form-control-lg" />
                      <app-field-error-message [control]="form.get('formLocal')" label="Local"></app-field-error-message>
                  </div>
    
                  <!-- Palavra Chave -->
                  <div class="col-md-4 mb-4">
                      <label class="form-label" for="formPalavraChave">Palavra-chave</label>
                      <input type="text" id="formPalavraChave" formControlName="formPalavraChave" name="formPalavraChave" class="form-control form-control-lg" />
                      <app-field-error-message [control]="form.get('formPalavraChave')" label="Palavra Chave"></app-field-error-message>
                  </div>
                    <!-- Observação Geral -->
                    <div class="form-outline ">
                      <label class="form-label" for="formObservacaoGeral">Observações Geral </label>
                      <input type="text" id="formObservacaoGeral" formControlName="formObservacaoGeral" [value]="form.get('formObservacaoGeral')"
                        name="formObservacaoGeral" class="form-control form-control-lg" />
                      <app-field-error-message [control]="form.get('formObservacaoGeral')"
                        label="descrição complementar"></app-field-error-message>
                    </div>
  
                <div class="top1">
                  <div class="table-responsive linha-de-botoes">
                    <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
                    <button [disabled]="form.invalid" type="button" class="btn btn-primary" 
                    (click)="SalvarAtividade();">Salvar</button>
                    <button [disabled]="form.invalid" type="button" class="btn btn-primary" 
                    (click)="SalvarAtividade();">Salvar e Sair</button>
                    <button [disabled]="form.invalid" type="button" class="btn btn-primary" 
                    (click)="SalvarAtividade();">Anexos</button>
                  </div>
              </div>
                </div>
              </form>
              <ul class="nav nav-tabs">
                <li class="nav-item d-flex">
                  <a class="nav-link" id="S1" role="tab" aria-controls="A1"
                     aria-selected="true" [class.active]="isActiveAba('A1')" (click)="setAba('A1')">Inscrições</a>
                </li>
                <li class="nav-item d-flex">
                  <a class="nav-link" id="S2" role="tab" aria-controls="A2"
                     aria-selected="true" [class.active]="isActiveAba('A2')" (click)="setAba('A2')">Profissionais</a>
                </li>
                <li class="nav-item d-flex">
                  <a class="nav-link" id="S3" role="tab" aria-controls="A3"
                     aria-selected="true" [class.active]="isActiveAba('A3')" (click)="setAba('A3')">Lançamentos</a>
                </li>
              </ul>
              
              <ng-container>
                <div class="tab-content top02" id="myTabContent"> 
                  <app-inscricao-lista *ngIf="aba === 'A1'" [origemSubTela]="1"></app-inscricao-lista>
                  <app-profissional-lista *ngIf="aba === 'A2'" [origemSubTela]="1"></app-profissional-lista>
                  <app-lancamento-lista *ngIf="aba === 'A3'" [origemSubTela]="1" [origemSubTelaCodigo]="atividade.IdAtividade"></app-lancamento-lista>
                </div> 
              </ng-container>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  

<app-footer></app-footer>
