<app-header *ngIf="origemSubTela === 0"></app-header>

<div class="content-wrapper">
    <div class="container-fluid">
        <div class="container">
            <div class="row d-flex align-items-center justify-content-center h-100">
                <div class="col-lg-12">
                    <div class="card-title-form" *ngIf="origemSubTela === 0">Profissional
                        <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Adicionar"
                        placement="top" [adaptivePosition]="false">                            
                        <button type="button" class="btn btn-sm btn-warning float-end" (click)="Adicionar();">
                            <i class="fa-solid fa-plus icone-para-botao"></i>Adicionar</button>
                    </span>
                    </div>

                    <div style="margin-top: 0.5%;margin-bottom: 0.5%;" class="row w-100">
                        <span class="col-lg-8">
                          <input type="text" #search id="search" class="form-control form-control-lg "
                            placeholder="Pesquisar Profissional na listagem abaixo..."
                            (keyup)="updateFilter($event)" />
                        </span>
                       
                        <!-- Botão "Adicionar" -->
                        <span style="margin-top: 0.5%;margin-bottom: 0.5%;" class="col-lg-2" *ngIf="origemSubTela != 0">                            
                        <button
                        type="button"
                        class="btn btn-sm btn-warning float-end"
                        data-bs-toggle="modal"
                        data-bs-target="#modalAdicionar"
                        (click)="Adicionar();">
                        <i class="fa-solid fa-plus icone-para-botao"></i> Adicionar
                        </button>
                        </span>
                        </div>
                        <!-- Modal -->
                        <div class="modal fade" id="modalAdicionar" tabindex="-1" aria-labelledby="modalAdicionarLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered modal-lg">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title" id="modalAdicionarLabel">Adicionar Profissional</h5>
                                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                  <form [formGroup]="form">
                                    <div class="mb-3">
                                      <label for="formProfissionalLista" class="form-label">Profissional</label>
                                      <select id="formProfissionalLista" formControlName="formProfissionalLista" class="form-select">
                                        <option value="0" disabled selected>Selecione...</option>
                                        <option *ngFor="let profissional of listaAssociadoOriginal" [value]="profissional.CodigoSocio">
                                          {{ profissional.NomeSocio }}
                                        </option>
                                      </select>
                                    </div>
                          
                                    <div class="mb-3">
                                      <label for="formFuncao" class="form-label">Função</label>
                                      <select id="formFuncao" formControlName="formFuncao" class="form-select">
                                        <option value="coordenacao">Coordenação</option>
                                        <option value="co-coordenação">Co-Coordenação</option>
                                        <option value="supervisao">Supervisão</option>
                                        <option value="ministrante">Ministrante</option>
                                      </select>
                                    </div>
                          
                                    <div class="mb-3">
                                      <label for="formParecer" class="form-label">Parecer</label>
                                      <textarea id="formParecer" formControlName="formParecer" class="form-control form-control-lg" rows="5"></textarea>
                                    </div>
                          
                                    <div class="d-flex justify-content-end">
                                      <button type="button" class="btn btn-warning me-2" (click)="fecharModal()">Cancelar</button>
                                      <button type="submit" class="btn btn-primary" (click)="salvarProfissional()">Salvar</button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                    <div class="card-body-form">
                        <ngx-datatable #myTable class="w-100 bootstrap ngx-datatable" [rows]="profissional"
                            [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                            [scrollbarH]="false" [scrollbarV]="false" [limit]="5"  [messages]="my_messages"
                            [sorts]="[{prop: 'Ordenacao', dir: 'desc'}]">

                            <!-- Profissional -->
                            <ngx-datatable-column prop="Nome" [draggable]="false" [flexGrow]="2">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Profissional</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.Nome}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Função -->
                            <ngx-datatable-column prop="Funcao" [draggable]="false" [flexGrow]="3">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Função</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.Funcao}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Mesa-->
                            <ngx-datatable-column prop="Mesa" [draggable]="false" [flexGrow]="3">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Mesa</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.Mesa}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Ações -->
                            <ngx-datatable-column name="Ações" [draggable]="false" [flexGrow]="1" [sortable]="false"
                                [cellClass]="'ngx-direita'" [headerClass]="'ngx-direita'">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Ações</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    
                                    <span class="iconeTamanho" containerClass="customClass" tooltip="Gerar Certificado" placement="top right"
                                        [adaptivePosition]="false" (click)="gerarCertificado(row);">
                                        <i class="fa-solid fa-certificate"></i>
                                    </span>

                                    <!--<span class="iconeTamanho" containerClass="customClass" tooltip="Edição" placement="top right"
                                        [adaptivePosition]="false" (click)="Edicao(row);">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </span> -->
                                    <span
                                        type="button"
                                        class="iconeTamanho" 
                                        containerClass="customClass" 
                                        tooltip="Editar"
                                        data-bs-toggle="modal"
                                        data-bs-target="#modalAdicionar"
                                        (click)="Edicao(row);">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </span>

                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>  
    </div>
    </div>
    
