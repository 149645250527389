<app-header *ngIf="origemSubTela === 0"></app-header>

<div class="content-wrapper">
    <div class="container-fluid">
        <div class="container">
            <div class="row d-flex align-items-center justify-content-center h-100">
                <div class="col-lg-12">
                    <div class="card-title-form" *ngIf="origemSubTela === 0">Lançamento
                        <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Adicionar"
                            placement="top" [adaptivePosition]="false">                            
                            <button type="button" class="btn btn-sm btn-warning float-end" (click)="Adicionar();">
                                <i class="fa-solid fa-plus icone-para-botao"></i>Adicionar</button>
                        </span>
                    </div>

                    <div style="margin-top: 0.5%;margin-bottom: 0.5%;" class="row w-100">
                        <span class="col-lg-8">
                          <input type="text" #search id="search" class="form-control form-control-lg "
                            placeholder="Pesquisar Inscrição na listagem abaixo..."
                            (keyup)="updateFilter($event)" />
                        </span>
                       
                        <span style="margin-top: 0.5%;margin-bottom: 0.5%;" class="col-lg-2" *ngIf="origemSubTela != 0">                            
                        <button type="button" class="btn btn-sm btn-warning float-end" containerClass="customClass" (click)="Adicionar();">
                            <i class="fa-solid fa-plus icone-para-botao"></i>Adicionar</button>
                       </span>             

                      </div>
                    <div class="card-body-form">
                        <ngx-datatable #myTable class="w-100 bootstrap ngx-datatable" [rows]="inscricao"
                            [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                            [scrollbarH]="false" [scrollbarV]="false" [limit]="5"  [messages]="my_messages"
                            [sorts]="[{prop: 'Ordenacao', dir: 'desc'}]">

                            <!-- Inscrito -->
                            <ngx-datatable-column prop="DescricaoPlanoConta" [draggable]="false" [flexGrow]="2">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Inscrito</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.Nome}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Situação -->
                            <ngx-datatable-column prop="situacao" [draggable]="false" [flexGrow]="3">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Situação</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.Situacao}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                          <!-- Ações -->
                            <ngx-datatable-column name="Ações" [draggable]="false" [flexGrow]="1" [sortable]="false"
                                [cellClass]="'ngx-direita'" [headerClass]="'ngx-direita'">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Ações</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>

                                    <span class="iconeTamanho" containerClass="customClass" tooltip="Falta" placement="top right"
                                        [adaptivePosition]="false" (click)="marcarFalta(row);">
                                        <i class="fa-solid fa-user-xmark"></i>
                                    </span>

                                    <span class="iconeTamanho" containerClass="customClass" tooltip="Desistência" placement="top right"
                                        [adaptivePosition]="false" (click)="registrarDesistencia(row);">
                                        <i class="fa-solid fa-arrow-right-from-bracket"></i>
                                    </span>

                                    <span class="iconeTamanho" containerClass="customClass" tooltip="Parecer" placement="top right"
                                        [adaptivePosition]="false" (click)="adicionarParecer(row);">
                                        <i class="fa-solid fa-comment-dots"></i>
                                    </span>

                                    <span class="iconeTamanho" containerClass="customClass" tooltip="Gerar Certificado" placement="top right"
                                        [adaptivePosition]="false" (click)="gerarCertificado(row);">
                                        <i class="fa-solid fa-certificate"></i>
                                    </span>

                                    <span class="iconeTamanho" containerClass="customClass" tooltip="Edição" placement="top right"
                                        [adaptivePosition]="false" (click)="Edicao(row);">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </span>

                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>  
    </div>
    </div>
    

<app-footer></app-footer>
