import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RelatoriosService } from './relatorios.service';
import { ParametrosRecord } from './models/ret-relatorio';
import { take } from 'rxjs';
import { MessageService } from 'src/app/Projeto/message/message.service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { Categoria } from '../categoria/models/categoria';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SocioResumo } from '../associados/models/associados';
import { PlanoConta } from '../planoconta/models/planoconta';
import { ListasGeraisService } from '../listasgerais/listasgerais.service';
import { Lista } from '../models-gerais/lista';
import { ListaSeminariosComPart } from '../ensino/models/ensino';
import { EnsinoService } from '../ensino/ensino.service';

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios.component.html',
  styleUrls: ['./relatorios.component.css'],
})
export class RelatoriosComponent implements OnInit {
  constructor(
    private router: Router,
    public frmBui: FormBuilder,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private utilsservice: UtilsService,
    private listasGeraisService: ListasGeraisService,
    private relService: RelatoriosService,
    private ensinoService: EnsinoService
  ) {}

  dataInicial: Date = new Date();
  dataFinal: Date = new Date();
  CategoriaObj: Categoria = new Categoria();
  AssociadoObj: SocioResumo = new SocioResumo();
  AssociadoObjHistCat: SocioResumo = new SocioResumo();
  AssociadoObjCoCoor: SocioResumo = new SocioResumo();
  PlanoContaObj: PlanoConta = new PlanoConta();
  form: FormGroup;

  TipoRelLancamento: Lista = new Lista();
  TipoRelLanc: string = 'TO';
  TipoRelFluxo: Lista = new Lista();
  TipoRelFlux: string = 'A';
  TipoRelPagamento: Lista = new Lista();
  TipoRelPaga: string = 'TO';
  TipoRelAtividades: Lista = new Lista();
  TipoRelAtiv: string = 'TO';
  TipoRelSupervisao: Lista = new Lista();
  TipoRelSupe: string = 'TO';
  TipoRelRecebimento: Lista = new Lista();
  TipoRelRecebe: string = 'TO';
  ListaSeminariosComPart: ListaSeminariosComPart[] = [];
  SeminarioSel: ListaSeminariosComPart;


  Inativas: boolean = false;

  configTipo = {
    search: true,
    placeholder: 'Selecione o tipo', //TROCA MENSAGEM
    noResultsFound: 'Nenhum registro encontrado!',
    searchPlaceholder: 'Pesquise...',
    moreText: 'Mais...',
    displayKey: 'Descricao', //TROCA CAMPO DESCRIÇÃO+
    height: 'auto',
    limitTo: 0,
  };

  configSeminario = {
    search: true,
    placeholder: 'Selecione o seminário', //TROCA MENSAGEM
    noResultsFound: 'Nenhum registro encontrado!',
    searchPlaceholder: 'Pesquise...',
    moreText: 'Mais...',
    displayKey: 'NomeETituloEnsino', //TROCA CAMPO DESCRIÇÃO+
    height: 'auto',
    limitTo: 0,
  };

  ngOnInit() {
    let dtAux: Date = new Date();
    dtAux.setMonth(dtAux.getUTCMonth() - 1);
    this.dataInicial = Utils.PrimeiroDiaMes(dtAux);
    this.dataFinal = Utils.UltimoDiaMes(dtAux);
    this.GetListasFixas();
  }

  GetListasFixas(): void {
    this.spinner.show();

    let ArrayListas: string[] = [];
    ArrayListas.push('TipoRelLancamento');
    ArrayListas.push('TipoRelFluxo');
    ArrayListas.push('TipoRelPagamento');
    ArrayListas.push('TipoRelAtividades');
    ArrayListas.push('TipoRelSupervisao');

    try {
      this.listasGeraisService
        .GetListasFixas(ArrayListas)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.TipoRelLancamento = response.ListasFixas[0];
              this.TipoRelFluxo = response.ListasFixas[1];
              this.TipoRelPagamento = response.ListasFixas[2];
              this.TipoRelRecebimento = response.ListasFixas[2];
              this.TipoRelAtividades = response.ListasFixas[3];
              this.TipoRelSupervisao = response.ListasFixas[4];
            }
            this.spinner.hide();
            this.GetListaSeminariosComPartAbertos();
            this.criarForm();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  GetListaSeminariosComPartAbertos(): void {
    this.spinner.show();
    try {
      this.ensinoService
        .GetListaSeminariosComPartAbertos()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.ListaSeminariosComPart = response.ListaSeminariosComPart;
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  criarForm() {
    this.form = this.frmBui.group({
      DataInicial: [this.dataInicial],
      DataFinal: [this.dataFinal],
      TipoRelLancamento: ['Todas'],
      TipoRelFluxo: ['Ano Atual'],
      TipoRelPagamento: ['Todas'],
      TipoRelRecebimento: ['Todas'],
      TipoRelAtividades: ['Todas'],
      TipoRelSupervisao: ['Todas'],
    });
  }

  changeDtIni($event: any) {
    //Gera nova data para eliminar a hora que vem junto mesmo quando é somente a data
    this.dataInicial = new Date($event);
  }

  changeDtFim($event: any) {
    //Gera nova data para eliminar a hora que vem junto mesmo quando é somente a data
    this.dataFinal = new Date($event);
  }

  changeCategoria($event: Categoria) {
    this.CategoriaObj = $event;
  }

  changeAssociadoHistCat($event: SocioResumo) {
    this.AssociadoObjHistCat = $event;
  }

  changeTerapeuta($event: SocioResumo) {
    this.AssociadoObjHistCat = $event;
  }

  changePlanoConta($event: PlanoConta) {
    this.PlanoContaObj = $event;
  }

  changeTipoRelLancamento($event: any) {
    this.TipoRelLanc = $event.value.Valor;
  }

  changeTipoRelFluxo($event: any) {
    this.TipoRelFlux = $event.value.Valor;
  }

  changeTipoRelPagamento($event: any) {
    this.TipoRelPaga = $event.value.Valor;
  }

  changeInativa($event: any) {
    this.Inativas = $event;
  }

  changeAssociado($event: SocioResumo) {
    this.AssociadoObj = $event;
  }

  changeTipoRelRecebimento($event: any) {
    this.TipoRelRecebe = $event.value.Valor;
  }

  changeTipoRelAtividade($event: any) {
    this.TipoRelAtiv = $event.value.Valor;
  }

  changeTipoRelSupervisao($event: any) {
    this.TipoRelSupe = $event.value.Valor;
  }

  changeAssociadoCoCoor($event: SocioResumo) {
    this.AssociadoObjCoCoor = $event;
  }

  changeTipoRelSeminario($event: any) {
    this.SeminarioSel = $event.value;
  }

  AssocListagem(relato: string) {
    if (this.CategoriaObj == undefined) {
      this.CategoriaObj = new Categoria();
    }

    if (this.AssociadoObjHistCat == undefined) {
      this.AssociadoObjHistCat = new SocioResumo();
    }

    if (
      this.AssociadoObjHistCat.CodigoSocio == 0 &&
      relato == 'HistoricoCategoria'
    ) {
      this.messageService.showAlertDanger(
        'Selecione associado para gerar histórico das categorias.'
      );
    } else {
      let relatorio: ParametrosRecord = new ParametrosRecord();
      relatorio.NomeRelatorio = relato;
      relatorio.CodigoIdentificacao01 = this.CategoriaObj.CodigoCategoria;
      relatorio.CodigoIdentificacao02 = this.AssociadoObjHistCat.CodigoSocio;
      relatorio.DataInicial = this.dataInicial;
      relatorio.DataFinal = this.dataFinal;
      this.spinner.show();
      try {
        this.relService
          .GeraRelatorios(relatorio)
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              if (response.Error) {
                this.messageService.showAlertDanger(response.ErrorMessage);
              } else {
                Utils.downloadFileBase64(
                  relatorio.NomeRelatorio +
                    '_' +
                    Utils.getDateddMMyyyyHhmmss(new Date()) +
                    '.xls',
                  response.RelBase64
                );
              }
              this.spinner.hide();
            },
            error: (error) => {
              this.spinner.hide();
              this.utilsservice.showHttpError(error);
            },
          });
      } catch (error) {
        this.spinner.hide();
        this.utilsservice.showHttpError(error);
      }
    }
  }

  ConsultasListagem(relato: string) {
    if (this.AssociadoObj == undefined) {
      this.AssociadoObj = new SocioResumo();
    }

    let relatorio: ParametrosRecord = new ParametrosRecord();
    relatorio.NomeRelatorio = relato;
    relatorio.CodigoIdentificacao01 = this.AssociadoObj.CodigoSocio;
    relatorio.CodigoIdentificacao02 = 0;
    relatorio.DataInicial = this.dataInicial;
    relatorio.DataFinal = this.dataFinal;
    this.spinner.show();
    try {
      this.relService
        .GeraRelatorios(relatorio)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              Utils.downloadFileBase64(
                relatorio.NomeRelatorio +
                  '_' +
                  Utils.getDateddMMyyyyHhmmss(new Date()) +
                  '.xls',
                response.RelBase64
              );
            }
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  ConsultasLancamento(relato: string) {
    if (this.PlanoContaObj == undefined) {
      this.PlanoContaObj = new PlanoConta();
    }
    if (this.PlanoContaObj.CodigoPlanoConta == '') {
      this.PlanoContaObj.CodigoPlanoConta = '0';
    }

    let relatorio: ParametrosRecord = new ParametrosRecord();
    relatorio.NomeRelatorio = relato;
    relatorio.CodigoIdentificacaoTxt01 = this.PlanoContaObj.CodigoPlanoConta;
    relatorio.CodigoIdentificacaoTxt02 = this.TipoRelLanc;
    relatorio.DataInicial = this.dataInicial;
    relatorio.DataFinal = this.dataFinal;
    this.spinner.show();
    try {
      this.relService
        .GeraRelatorios(relatorio)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              Utils.downloadFileBase64(
                relatorio.NomeRelatorio +
                  '_' +
                  Utils.getDateddMMyyyyHhmmss(new Date()) +
                  '.xls',
                response.RelBase64
              );
            }
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  ConsultasExercicio(relato: string) {
    if (this.PlanoContaObj == undefined) {
      this.PlanoContaObj = new PlanoConta();
    }
    if (this.PlanoContaObj.CodigoPlanoConta == '') {
      this.PlanoContaObj.CodigoPlanoConta = '0';
    }

    let relatorio: ParametrosRecord = new ParametrosRecord();
    relatorio.NomeRelatorio = relato;
    relatorio.CodigoIdentificacaoTxt01 = this.PlanoContaObj.CodigoPlanoConta;
    relatorio.CodigoIdentificacaoTxt02 = this.TipoRelFlux;
    relatorio.DataInicial = this.dataInicial;
    relatorio.DataFinal = this.dataFinal;
    relatorio.Boleano01 = this.Inativas;
    this.spinner.show();
    try {
      this.relService
        .GeraRelatorios(relatorio)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              Utils.downloadFileBase64(
                relatorio.NomeRelatorio +
                  '_' +
                  Utils.getDateddMMyyyyHhmmss(new Date()) +
                  '.xls',
                response.RelBase64
              );
            }
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  ConsultasContasPagar(relato: string) {
    if (this.PlanoContaObj == undefined) {
      this.PlanoContaObj = new PlanoConta();
    }
    if (this.PlanoContaObj.CodigoPlanoConta == '') {
      this.PlanoContaObj.CodigoPlanoConta = '0';
    }

    let relatorio: ParametrosRecord = new ParametrosRecord();
    relatorio.NomeRelatorio = relato;
    relatorio.CodigoIdentificacao01 = this.AssociadoObj.CodigoSocio;
    relatorio.CodigoIdentificacaoTxt01 = this.TipoRelPaga;
    relatorio.DataInicial = this.dataInicial;
    relatorio.DataFinal = this.dataFinal;
    this.spinner.show();
    try {
      this.relService
        .GeraRelatorios(relatorio)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              Utils.downloadFileBase64(
                relatorio.NomeRelatorio +
                  '_' +
                  Utils.getDateddMMyyyyHhmmss(new Date()) +
                  '.xls',
                response.RelBase64
              );
            }
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  ConsultasContasReceber(relato: string) {
    if (this.PlanoContaObj == undefined) {
      this.PlanoContaObj = new PlanoConta();
    }
    if (this.PlanoContaObj.CodigoPlanoConta == '') {
      this.PlanoContaObj.CodigoPlanoConta = '0';
    }

    let relatorio: ParametrosRecord = new ParametrosRecord();
    relatorio.NomeRelatorio = relato;
    relatorio.CodigoIdentificacao01 = this.AssociadoObj.CodigoSocio;
    relatorio.CodigoIdentificacaoTxt01 = this.TipoRelRecebe;
    relatorio.DataInicial = this.dataInicial;
    relatorio.DataFinal = this.dataFinal;
    this.spinner.show();
    try {
      this.relService
        .GeraRelatorios(relatorio)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              Utils.downloadFileBase64(
                relatorio.NomeRelatorio +
                  '_' +
                  Utils.getDateddMMyyyyHhmmss(new Date()) +
                  '.xls',
                response.RelBase64
              );
            }
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  ConsultasAtividades(relato: string) {
    if (this.PlanoContaObj == undefined) {
      this.PlanoContaObj = new PlanoConta();
    }
    if (this.PlanoContaObj.CodigoPlanoConta == '') {
      this.PlanoContaObj.CodigoPlanoConta = '0';
    }

    let relatorio: ParametrosRecord = new ParametrosRecord();
    relatorio.NomeRelatorio = relato;
    relatorio.CodigoIdentificacaoTxt01 = this.TipoRelAtiv;
    relatorio.DataInicial = this.dataInicial;
    relatorio.DataFinal = this.dataFinal;
    this.spinner.show();
    try {
      this.relService
        .GeraRelatorios(relatorio)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              Utils.downloadFileBase64(
                relatorio.NomeRelatorio +
                  '_' +
                  Utils.getDateddMMyyyyHhmmss(new Date()) +
                  '.xls',
                response.RelBase64
              );
            }
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  ConsultasSupervisoes(relato: string) {
    if (this.PlanoContaObj == undefined) {
      this.PlanoContaObj = new PlanoConta();
    }
    if (this.PlanoContaObj.CodigoPlanoConta == '') {
      this.PlanoContaObj.CodigoPlanoConta = '0';
    }
    if (this.AssociadoObjCoCoor == undefined) {
      this.AssociadoObjCoCoor = new SocioResumo();
    }

    if (this.AssociadoObjCoCoor.CodigoSocio == 0 && relato == 'CoCoordenacao')
    {
      this.messageService.showAlertDanger('Selecione associado para gerar as co-coordenações.');
    }
    else if (this.SeminarioSel.CodigoEnsinoNome == 0 && relato == 'Chamada')
    {
      this.messageService.showAlertDanger('Selecione um seminário para gerar a chamada.');
    }
    else {
      let relatorio: ParametrosRecord = new ParametrosRecord();
      relatorio.NomeRelatorio = relato;
      relatorio.CodigoIdentificacao01 = this.AssociadoObjCoCoor.CodigoSocio;
      relatorio.CodigoIdentificacaoTxt01 = this.TipoRelSupe;
      relatorio.CodigoIdentificacao02 = this.SeminarioSel.CodigoEnsino;
      relatorio.CodigoIdentificacaoTxt02 = this.SeminarioSel.NomeEnsinoNome;
      relatorio.DataInicial = this.dataInicial;
      relatorio.DataFinal = this.dataFinal;
      this.spinner.show();
      try {
        this.relService
          .GeraRelatorios(relatorio)
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              if (response.Error) {
                this.messageService.showAlertDanger(response.ErrorMessage);
              } else {
                Utils.downloadFileBase64(
                  relatorio.NomeRelatorio +
                    '_' +
                    Utils.getDateddMMyyyyHhmmss(new Date()) +
                    '.xls',
                  response.RelBase64
                );
              }
              this.spinner.hide();
            },
            error: (error) => {
              this.spinner.hide();
              this.utilsservice.showHttpError(error);
            },
          });
      } catch (error) {
        this.spinner.hide();
        this.utilsservice.showHttpError(error);
      }
    }
  }
}
