<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">

    <div class="card-title-form">Cadastro Geral
      <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
        [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas" aria-controls="offcanvasExample">
        <i class="fa-solid fa-circle-question" (click)="setAbaHelp('S1Help')"></i>
      </span>
    </div>

    <div class="card-body-form">

      <ul class="nav nav-tabs">
        <li class="nav-item d-flex">
          <a class="nav-link active" id="S1" data-bs-toggle="tab" role="tab" aria-controls="S1"
            aria-selected="true" [class.active]="isActiveAba('S1')" (click)="setAba('S1')">Dados Prioritários</a>
        </li>
        <li class="nav-item d-flex">
          <a class="nav-link active" id="S2" data-bs-toggle="tab" role="tab" aria-controls="S2"
            aria-selected="true" [class.active]="isActiveAba('S2')" (click)="setAba('S2')">Dados Complementares</a>
        </li>
        <li class="nav-item d-flex">
          <a class="nav-link active" id="S3" data-bs-toggle="tab" role="tab" aria-controls="S3"
            aria-selected="true" [class.active]="isActiveAba('S3')" (click)="setAba('S3')">Histórico de Categorias</a>
        </li>
      </ul>
      <ng-container>
        <div class="tab-content top02" id="myTabContent" [ngSwitch]="aba">
          <app-associados-edicao-prio     *ngSwitchCase="'S1'" [associado]="assoc"></app-associados-edicao-prio>
          <app-associados-edicao-end      *ngSwitchCase="'S2'" [associado]="assoc"></app-associados-edicao-end>
          <app-associados-lista-categoria *ngSwitchCase="'S3'" [associado]="assoc"></app-associados-lista-categoria>
        </div>
      </ng-container>

      <div class="table-responsive col-lg-12 linha-de-botoes">
        <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
        <button type="button" class="btn btn-primary"
          (click)="SalvaAssociado();">Salvar</button>
      </div>
    </div>

  </div>
</div>

<div class="offcanvas offcanvas-top h-100" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-header">
    <div class="card-title-form-menor">Cadastro Geral
      <button type="button" class="float-end btn-close text-primary customSearchButtons" data-bs-dismiss="offcanvas" aria-label="Fechar"
        containerClass="customClass" tooltip="Fechar Help" placement="bottom" [adaptivePosition]="false"></button>
    </div>
  </div>
  <div class="offcanvas-body">
    <ul class="nav nav-tabs">
      <li class="nav-item d-flex">
        <a class="nav-link active" id="S1Help" data-bs-toggle="tabHelp" role="tabHelp" aria-controls="S1Help"
          aria-selected="true" [class.active]="isActiveAbaHelp('S1Help')" (click)="setAbaHelp('S1Help')">Dados Prioritários</a>
      </li>
      <li class="nav-item d-flex">
        <a class="nav-link active" id="S2Help" data-bs-toggle="tabHelp" role="tabHelp" aria-controls="S2Help"
          aria-selected="true" [class.active]="isActiveAbaHelp('S2Help')" (click)="setAbaHelp('S2Help')">Dados Complementares</a>
      </li>
    </ul>
    <ng-container>
      <div class="tab-content" id="myTabContentHelp" [ngSwitch]="abaHelp">
        <div *ngSwitchCase="'S1Help'">

          <div class="card-body-form">
            <div class="table-responsive table-responsive-overflow-450">
              <form [formGroup]="form">

                <div class="row">
                  <span class="w-100 card-title-form-menor-FB" style="margin-left: 0.8%;">Identificação</span>
                  <div class="col-lg-4">
                    <label class="form-label">Nome</label>
                    <input disabled class="form-control form-control-lg"
                     placeholder="Nome do associado (até 80 caracteres)." />
                  </div>
                  <div class="col-lg-2">
                    <label class="form-label" style="width: 100%;">Nascimento</label>
                    <input disabled  class="form-control form-control-lg"
                    placeholder="Data de nascimento." />
                  </div>
                  <span class="col-lg-6 row">
                    <div class="col-lg-2">
                      <label class="form-label">Ativo?</label>
                      <span class="d-block"> <ui-switch></ui-switch>
                        <input disabled class="form-control form-control-lg top02"
                        placeholder="Está ativo?" />
                      </span>
                    </div>
                    <div class="col-lg-2">
                      <label class="form-label">Desconto Sala?</label>
                      <span class="d-block"> <ui-switch></ui-switch>
                        <input disabled class="form-control form-control-lg top02"
                        placeholder="Possui desconto na sala?" />
                      </span>
                    </div>
                    <div class="col-lg-2">
                      <label class="form-label">Bolsista?</label>
                      <span class="d-block"> <ui-switch></ui-switch>
                        <input disabled class="form-control form-control-lg top02"
                        placeholder="É bolsista?" />
                      </span>
                    </div>
                    <div class="col-lg-3">
                      <label class="form-label">Bolsa Suspensa?</label>
                      <span class="d-block"> <ui-switch></ui-switch>
                        <input disabled class="form-control form-control-lg top02"
                        placeholder="A bolsa foi suspensa?" />
                      </span>
                    </div>
                    <div class="col-lg-3">
                      <label class="form-label">Ações Afirmativas?</label>
                      <span class="d-block"> <ui-switch></ui-switch>
                        <input disabled class="form-control form-control-lg top02"
                        placeholder="Participa ações afirmativas?" />
                      </span>
                    </div>
                  </span>
                </div>

                <div class="row top02">
                  <span class="col-lg-3">
                    <label class="form-label">Categoria</label>
                    <input disabled disabled class="form-control form-control-lg"
                    placeholder="Categoria ao qual o associado pertence." />
                  </span>
                  <span class="col-lg-3">
                    <label class="form-label">Categoria Extra Profissionais/Estudantes</label>
                    <input disabled class="form-control form-control-lg"
                    placeholder="Categoria extra para classificar Profissionais/Estudantes." />
                  </span>
                  <span class="col-lg-3">
                    <label class="form-label">Área Atuação</label>
                    <input disabled class="form-control form-control-lg"
                    placeholder="Área de atuação do associado." />
                  </span>
                  <span class="col-lg-3">
                    <label class="form-label">Perfil</label>
                    <input disabled class="form-control form-control-lg"
                    placeholder="Perfil de acesso ao sistema." />
                  </span>
                </div>
                <div class="row top02">
                  <span class="col-lg-2">
                    <label class="form-label">Inscrição CRP:</label>
                    <input disabled class="form-control form-control-lg"
                    placeholder="Nro de iscrição no CRP." />
                  </span>
                  <span class="col-lg-2">
                    <label class="form-label">CPF/CNPJ:</label>
                    <input disabled class="form-control form-control-lg"
                    placeholder="CPF ou CNPJ do associado." />
                  </span>
                  <span class="col-lg-2">
                    <label class="form-label">RG:</label>
                    <input disabled class="form-control form-control-lg"
                    placeholder="Identidade do associado." />
                  </span>
                  <span class="col-lg-2">
                    <label class="form-label" for="ingresso" style="width: 100%;">Ingresso Formação:</label>
                    <input disabled class="form-control form-control-lg"
                    placeholder="Ano/Mês ingresso formação." />
                  </span>
                  <span class="col-lg-2">
                    <label class="form-label" for="desligamento" style="width: 100%;">Data Desligamento:</label>
                    <input disabled class="form-control form-control-lg"
                    placeholder="Data de desligamento." />
                  </span>
                  <span class="col-lg-2">
                    <label class="form-label" for="retorno" style="width: 100%;">Data Retorno:</label>
                    <input disabled class="form-control form-control-lg"
                    placeholder="Data de retorno." />
                  </span>
                </div>

                <div class="row top1">
                  <span class="w-100 card-title-form-menor-FB" style="margin-left: 0.8%;">Contatos Principais</span>
                    <span class="col-lg-3">
                      <label class="form-label">E-mail Principal:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="E-mail Principal." />
                    </span>
                    <span class="col-lg-3">
                      <label class="form-label">E-mail Alternativo:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="E-mail Principal caso tenha." />
                    </span>
                    <span class="col-lg-2">
                      <label class="form-label">Celular/Fone 1:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="Celular/Fone Principal." />
                    </span>
                    <span class="col-lg-2">
                      <label class="form-label">Celular/Fone 2:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="Celular/Fone para contato." />
                    </span>
                    <span class="col-lg-2">
                      <label class="form-label">Celular/Fone 3:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="Celular/Fone para contato." />
                    </span>
                </div>

                <div class="row top1">
                    <div class="row">
                      <div class="col-lg-6"><span class="card-title-form-menor-FB">Contato Emergencial:</span></div>
                      <div class="col-lg-6"><span class="card-title-form-menor-FB" style="margin-left: 1.3%;">Observações:</span></div>
                    </div>
                    <div class="col-lg-6 row">
                      <span class="col-lg-8">
                        <label class="form-label">Pessoa:</label>
                        <input disabled class="form-control form-control-lg"
                        placeholder="Nome de conhecido para contato emergencial." />
                      </span>
                      <span class="col-lg-4">
                        <label class="form-label">Telefone:</label>
                        <input disabled class="form-control form-control-lg"
                        placeholder="Celular/Fone do conhecido para contato emergencial." />
                      </span>
                      <span class="w-100 card-title-form-menor-FB top1" style="margin-left: 1.5%;">Acesso Sistema</span>
                      <span class="col-lg-3">
                        <label class="form-label">Usuário:</label>
                        <input disabled class="form-control form-control-lg"
                        placeholder="Usuário para logar no sistema." />
                      </span>
                      <span class="col-lg-3">
                        <label class="form-label">Senha Atual:</label>
                        <input disabled class="form-control form-control-lg"
                        placeholder="Senha para logar no sistema. Informar na inclusão e na alteração da senha." />
                      </span>
                      <span class="col-lg-3">
                        <label class="form-label">Senha Nova:</label>
                        <input disabled class="form-control form-control-lg"
                        placeholder="Nova senha. Informar na alteração da senha." />
                      </span>
                      <span class="col-lg-3">
                      <label class="form-label">Confirma Senha Nova:</label>
                        <input disabled class="form-control form-control-lg"
                        placeholder="Confirmar Nova senha. Informar na alteração da senha." />
                      </span>
                    </div>
                    <div class="col-lg-6" style="margin-left: 24px;">
                        <span class="w-100">
                          <label class="form-label">Observações diversas:</label>
                          <textarea disabled class="form-control form-control-lg" rows="7"
                          placeholder="Observações gerais sobre o associado"></textarea>
                      </span>
                    </div>
                </div>

              </form>
            </div>
          </div>

        </div>
        <div *ngSwitchCase="'S2Help'">

          <div class="card-body-form">
            <div class="table-responsive table-responsive-overflow-450">
              <form [formGroup]="form">

                <div class="row">
                  <span class="card-title-form-menor-FB" style="padding-left: 14px;">Endereço de Cobrança/Principal</span>
                  <div class="row">
                    <span class="col-lg-1">
                      <label class="form-label">CEP:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="Busca endereço nos correios." />
                    </span>
                    <span class="col-lg-3">
                      <label class="form-label">Endereço:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="Av./Rua do endereço." />
                    </span>
                    <span class="col-lg-1">
                      <label class="form-label">Complemento:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="Nro ou complemento do endereço." />
                    </span>
                    <span class="col-lg-3">
                      <label class="form-label">Bairro:</label>
                      <input disabled class="form-control form-control-lg"
                       placeholder="Bairro do endereço." />
                    </span>
                    <span class="col-lg-2">
                      <label class="form-label">Cidade:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="Cidade do endereço." />                    </span>
                    <span class="col-lg-1">
                      <label class="form-label">UF:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="Estado do endereço." />
                    </span>
                    <span class="col-lg-1">
                      <label class="form-label">Tipo:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="comercial ou residencial?" />
                    </span>
                  </div>
                </div>

                <div class="row top02">
                  <span class="card-title-form-menor-FB" style="padding-left: 14px;">Endereço Secundário</span>
                  <div class="row">
                    <span class="col-lg-1">
                      <label class="form-label">CEP:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="Busca endereço 2 nos correios." />
                    </span>
                    <span class="col-lg-3">
                      <label class="form-label">Endereço:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="Av./Rua do endereço 2." />
                    </span>
                    <span class="col-lg-1">
                      <label class="form-label">Complemento:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="Nro ou complemento do endereço 2." />
                    </span>
                    <span class="col-lg-3">
                      <label class="form-label">Bairro:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="Bairro do endereço 2." />
                    </span>
                    <span class="col-lg-2">
                      <label class="form-label">Cidade:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="Cidade do endereço 2." />
                    </span>
                    <span class="col-lg-1">
                      <label class="form-label">UF:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="Estado do endereço 2." />                    </span>
                    <span class="col-lg-1">
                      <label class="form-label">Tipo:</label>
                      <input disabled class="form-control form-control-lg"
                      placeholder="comercial ou residencial?" />
                    </span>
                  </div>
                </div>

                <div class="row top02">
                  <span class="card-title-form-menor-FB" style="padding-left: 14px;">Informações Adicionais</span>
                  <span class="col-lg-6">
                    <label class="form-label">Encaminhado Por:</label>
                    <input disabled class="form-control form-control-lg"
                    placeholder="pessoa que trouxe para SIG." />
                  </span>
                  <span class="col-lg-6">
                      <div class="form-label">Arquivo Assinatura</div>
                      <input disabled class="form-control form-control-lg"
                      placeholder="Imagem da assinatura para inclusão nos certificados" />
                  </span>
                </div>

              </form>
            </div>
          </div>

        </div>
      </div>
    </ng-container>
    <div class="row top1">
      <div class="col-lg-12">
        <button type="button" class="btn btn-warning" >Cancelar</button><label
          style="margin-left: 4px; margin-right: 1%;">Retorna para tela de lista de Associados.</label>
        <button type="button" class="btn btn-primary">Salvar</button><label
          style="margin-left: 4px; margin-right: 1%;">Salva os dados editados.</label>
      </div>
    </div>
  </div>
</div>


<app-footer></app-footer>
