<app-header *ngIf="origemSubTela === 0"></app-header>

<div class="content-wrapper">
    <div class="container-fluid">
        <div class="container">
            <div class="row d-flex align-items-center justify-content-center h-100">
                <div class="col-lg-12">
                    <div class="card-title-form" *ngIf="origemSubTela === 0">Lançamento
                        <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Adicionar"
                            placement="top" [adaptivePosition]="false">                            
                            <button type="button" class="btn btn-sm btn-warning float-end" (click)="Adicionar();">
                                <i class="fa-solid fa-plus icone-para-botao"></i>Adicionar</button>
                        </span>
                    </div>

                    <div style="margin-top: 0.5%;margin-bottom: 0.5%;" class="row w-100">
                        <span class="col-lg-8">
                          <input type="text" #search id="search" class="form-control form-control-lg "
                            placeholder="Pesquisar Plano Conta na listagem abaixo..."
                            (keyup)="updateFilter($event)" />
                        </span>
                       
                       <!-- <span style="margin-top: 0.5%;margin-bottom: 0.5%;" class="col-lg-2" *ngIf="origemSubTela != 0">                            
                        <button type="button" class="btn btn-sm btn-warning float-end" containerClass="customClass" (click)="Adicionar();">
                            <i class="fa-solid fa-plus icone-para-botao"></i>Adicionar</button>
                       </span>             -->
                        <!-- Botão "Adicionar" -->
                        <span style="margin-top: 0.5%;margin-bottom: 0.5%;" class="col-lg-2" *ngIf="origemSubTela != 0">                            
                            <button
                            type="button"
                            class="btn btn-sm btn-warning float-end"
                            data-bs-toggle="modal"
                            data-bs-target="#modalAdicionar"
                            (click)="AdicionarSubTela();">
                            <i class="fa-solid fa-plus icone-para-botao"></i> Adicionar
                            </button>
                            </span>
                            </div>
                            <!-- Modal -->
                                <div class="modal-body">
                                        <div class="modal fade" id="modalAdicionar" tabindex="-1" aria-labelledby="modalAdicionarLabel" aria-hidden="true">
                                          <div class="modal-dialog modal-dialog-centered modal-lg">
                                            <div class="modal-content">
                                              <div class="modal-header">
                                                <h5 class="modal-title" id="modalAdicionarLabel">Adicionar Lançamento</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                              </div>
                                              <div class="modal-body">
                                                <form [formGroup]="form">
                                                  <div class="mb-3">
                                                    <label for="formContaPlano" class="form-label">Plano Conta</label>
                                                    <select id="formContaPlano" formControlName="formContaPlano" class="form-select">
                                                      <option value="0" disabled selected>Selecione...</option>
                                                      <option *ngFor="let conta of planoContaOriginal" [value]="conta.IdPlanoConta">
                                                        {{ conta.CodigoPlanoConta }} - {{ conta.DescricaoPlanoConta }}
                                                      </option>
                                                    </select>
                                                    <app-field-error-message [control]="form.get('formContaPlano')" label="Plano Conta"></app-field-error-message>
                                                  </div>
                                        
                                                  <div class="mb-3">
                                                    <label for="formFornecedor" class="form-label">Fornecedor</label>
                                                    <select id="formFornecedor" formControlName="formFornecedor" class="form-select">
                                                      <option value="0" disabled selected>Selecione...</option>
                                                      <option *ngFor="let fornecedor of listaFornecedorOriginal" [value]="fornecedor.CodigoSocio">
                                                        {{ fornecedor.NomeSocio }}
                                                      </option>
                                                    </select>
                                                    <app-field-error-message [control]="form.get('formFornecedor')" label="Fornecedor"></app-field-error-message>
                                                  </div>
                                        
                                                  <div class="mb-3 form-outline">
                                                    <label class="form-label" for="formComplemento">Complemento</label>
                                                    <input type="text" id="formComplemento" formControlName="formComplemento" class="form-control form-control-lg"/>
                                                    <app-field-error-message [control]="form.get('formComplemento')" label="Descrição Complementar"></app-field-error-message>
                                                  </div>
                                        
                                                  <div class="mb-3">
                                                    <label class="form-label" for="formDataLancamento">Data Lançamento</label>
                                                    <input type="text" id="formDataLancamento" formControlName="formDataLancamento" class="form-control form-control-lg" bsDatepicker />
                                                    <app-field-error-message [control]="form.get('formDataLancamento')" label="Data de Lançamento"></app-field-error-message>
                                                  </div>
                                                  <div class="col-lg-5">
                                                    <label class="form-label" for="formValorLancamento">Valor</label>
                                                    <input type="text" id="formValorLancamento" formControlName="formValorLancamento" 
                                                      name="formValorLancamento" class="form-control form-control-lg" currencyMask/>
                                                    <app-field-error-message [control]="form.get('formValorLancamento')"
                                                      label="Valor"></app-field-error-message>
                                                  </div>
                                             <div class="row top02">
                                                <div class="col-lg-6">
                                                <label class="form-label" for="formTipoLancamento">Tipo Lancto</label>
                                                <select id="formTipoLancamento" formControlName="formTipoLancamento" name="formTipoLancamento" class="form-select">
                                                    <option value="0" disabled selected>Selecione...</option>
                                                    <option *ngFor="let tipo of listaTipoLancamentoOriginal" [value]="tipo.CodigoTipoLancamento">{{ tipo.NomeTipoLancamento }} </option>
                                                </select> 
                                              </div>
                                                <div class="col-lg-3">
                                                  <label class="form-label" style="width: 100%;">Nro Lançamento</label>
                                                  <input type="text" id="formNumeroLancamento" formControlName="formNumeroLancamento"
                                                    name="formNumeroLancamento" class="form-control form-control-lg" />
                                                </div>
                                
                                                <div class="col-lg-3">
                                                  <label class="form-label" for="formNumeroParcela">Nro Parcelas</label>
                                                  <input type="text" id="formNumeroParcela" formControlName="formNumeroParcela" 
                                                    name="formNumeroParcela" class="form-control form-control-lg" />
                                
                                                </div>
                                            </div>
                                
                                            <div class="form-outline">
                                              <label class="form-check-label" for="formRecebeuBoleto">Recebeu Boleto: </label>
                                               <span class="d-inline"> <ui-switch name="formRecebeuBoleto" id="formRecebeuBoleto" formControlName="formRecebeuBoleto"
                                                    (click)="toggleRecebeuBoleto();" size="medium"></ui-switch></span> 
                                               <label class="form-check-label" for="formCompensado" style="margin-left: 5px;">Compensado: </label>
                                               <span class="d-inline"> <ui-switch name="formCompensado" id="formCompensado" formControlName="formCompensado"
                                                    (click)="toggleCompensado();" size="medium"></ui-switch></span> 
                                               <label class="form-check-label" for="formEstoqueAntigo" style="margin-left: 5px;">Estoque Antigo: </label>
                                               <span class="d-inline"> <ui-switch name="formEstoqueAntigo" id="formEstoqueAntigo" formControlName="formEstoqueAntigo"
                                                    (click)="toggleEstoqueAntigo();" size="medium"></ui-switch></span> 
                                           </div>
                                           <div class="form-outline">
                                            <label class="form-label" for="formAssociado">Associado</label>
                                            <select id="formAssociado" formControlName="formAssociado" name="formAssociado" class="form-select">
                                                <option value="0" disabled selected>Selecione...</option>
                                                <option *ngFor="let socio of listaAssociadoOriginal" [value]="socio.CodigoSocio">{{ socio.NomeSocio }} </option>
                                            </select> 
                                            </div>
                                        <app-field-error-message [control]="form.get('formAssociado')" label="Associado"></app-field-error-message>
                                       </form>
                                    </div>
                                  </div>
                              </div>

                        <span style="margin-top: 0.5%;margin-bottom: 0.5%;" [ngClass]="origemSubTela === 0 ? 'col-lg-4' : 'col-lg-2'" *ngIf="origemSubTela === 0">
                          <button type="button" class="btn btn-sm btn-warning float-end" containerClass="customClass" tooltip="Exporta para o Excel os dados consultados abaixo." placement="top"
                          [adaptivePosition]="false" (click)="generateExcell();"><i class="fa-solid fa-file-excel icone-para-botao" ></i>Exportar Excel</button>
                        </span>
                      </div>
                    <div class="card-body-form">
                        <ngx-datatable #myTable class="w-100 bootstrap ngx-datatable" [rows]="lancamento"
                            [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                            [scrollbarH]="false" [scrollbarV]="false" [limit]="5"  [messages]="my_messages"
                            [sorts]="[{prop: 'Ordenacao', dir: 'desc'}]">

                            <!-- Descrição do Plano de Contas -->
                            <ngx-datatable-column prop="DescricaoPlanoConta" [draggable]="false" [flexGrow]="2">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Plano de Contas</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.DescricaoPlanoConta}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Descrição Complementar -->
                            <ngx-datatable-column prop="DescExtraPlanoconta" [draggable]="false" [flexGrow]="3" *ngIf="origemSubTela === 0">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Descrição Complementar</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.DescExtraPlanoconta}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Nome do Fornecedor -->
                            <ngx-datatable-column prop="NomeFornecedor" [draggable]="false" [flexGrow]="3" *ngIf="origemSubTela === 0">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Nome Fornecedor</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.NomeFornecedor}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Tipo do Plano de Conta -->
                            <ngx-datatable-column prop="TipoPlanoConta" [draggable]="false" [flexGrow]="1.5">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Tipo</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.TipoPlanoConta}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Data de Lançamento -->
                            <ngx-datatable-column prop="DataLancamento" [draggable]="false" [flexGrow]="1.5">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Data</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.converterDataLancamento()}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Valor do Lançamento -->
                            <ngx-datatable-column prop="ValorLancamento" [draggable]="false" [flexGrow]="2">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Valor</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.converterValorLancamento()}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Número do Cheque -->
                            <ngx-datatable-column prop="NroCheque" [draggable]="false" [flexGrow]="1.8">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Número</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.NroCheque}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Recebeu Boleto -->
                            <ngx-datatable-column prop="RecebeuBoleto" [draggable]="false" [flexGrow]="1.5">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Boleto</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.RecebeuBoleto}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Compensado -->
                            <ngx-datatable-column prop="Compensado" [draggable]="false" [flexGrow]="1.5" *ngIf="origemSubTela === 0">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Comp</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.Compensado}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Nome do Tipo de Lançamento -->
                            <ngx-datatable-column prop="NomeTipoLancamento" [draggable]="false" [flexGrow]="2">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Tipo Lan</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.NomeTipoLancamento}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Centro de Custo -->
                            <ngx-datatable-column prop="NomeCentroCusto" [draggable]="false" [flexGrow]="2" *ngIf="origemSubTela !== 0">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Centro de Custo</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.NomeCentroCusto}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Ações -->
                            <ngx-datatable-column name="Ações" [draggable]="false" [flexGrow]="1" [sortable]="false"
                                [cellClass]="'ngx-direita'" [headerClass]="'ngx-direita'">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Ações</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span class="iconeTamanho" containerClass="customClass" tooltip="Edição" placement="top right"
                                        [adaptivePosition]="false" (click)="Edicao(row);" *ngIf="origemSubTela === 0">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </span>

                                    <span
                                        type="button"
                                        class="iconeTamanho" 
                                        containerClass="customClass" 
                                        tooltip="Editar"
                                        data-bs-toggle="modal"
                                        data-bs-target="#modalAdicionar"
                                        (click)="EdicaoSubTela(row);"
                                        *ngIf="origemSubTela !== 0">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </span>

                                </ng-template>
                            </ngx-datatable-column>
<!--                            <ngx-datatable-footer #tFooter (onFooterPage)="handleFooter($event)">
                                <ng-template ngx-datatable-footer-template let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
                                  <span>
                                     {{totalLinhas}} linhas encontradas
                                  </span>
                                  <datatable-pager
                                    (onFooterPage)="handleFooter($event)"
                                    (change)="handleFooter($event)"
                                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                                    [pagerRightArrowIcon]="'datatable-icon-right'"
                                    [pagerPreviousIcon]="'datatable-icon-prev'"
                                    [pagerNextIcon]="'datatable-icon-skip'"
                                    [page]="curPage"
                                    [size]="pageSize"
                                    [count]="totalLinhas"
                                  ></datatable-pager>
                                </ng-template>
                              </ngx-datatable-footer> -->
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>  
    </div>
    </div>
    

<app-footer></app-footer>
