<app-header></app-header>

<div class="content-wrapper">
  <div class="container">

      <div class="col-md-12 justify-content-center">
        <div class="row top1 ">
          <h1 class="fs-4 fw-bolder my-0">Olá, {{ authStorageService.Usuario.Nome }}!</h1>
        </div>

        <div class="row top1 ">
          <div class="card text-white bg-administrativo mb-3" style="max-width: 20rem;margin-left: 0.7%">
            <div class="card-header">Administrativo</div>
            <div class="card-body">
              <h5 class="card-title">Lançamentos</h5>
            </div>
          </div>
          <div class="card text-white bg-administrativo mb-3" style="max-width: 20rem;margin-left: 0.7%">
            <div class="card-header">Administrativo</div>
            <div class="card-body">
              <h5 class="card-title">Saldo do Dia</h5>
            </div>
          </div>
          <div class="card text-white bg-administrativo mb-3" style="max-width: 20rem;margin-left: 0.7%" (click)="rotinas()">
            <div class="card-header">Administrativo</div>
            <div class="card-body">
              <h5 class="card-title">Rotinas</h5>
            </div>
          </div>
          <div class="card text-white bg-administrativo mb-3" style="max-width: 20rem;margin-left: 0.7%">
            <div class="card-header">Administrativo</div>
            <div class="card-body">
              <h5 class="card-title">Atividades Administrativas</h5>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="card bg-clinica mb-3" style="max-width: 20rem;margin-left: 0.7%" (click)="ocupacao()">
            <div class="card-header">Clínica</div>
            <div class="card-body">
              <h5 class="card-title">Ocupação Salas</h5>
            </div>
          </div>
          <div class="card bg-clinica mb-3" style="max-width: 20rem;margin-left: 0.7%">
            <div class="card-header">Clínica</div>
            <div class="card-body">
              <h5 class="card-title">Consultas</h5>
            </div>
          </div>
          <div class="card bg-clinica mb-3" style="max-width: 20rem;margin-left: 0.7%">
            <div class="card-header">Clínica</div>
            <div class="card-body">
              <h5 class="card-title">Avaliação fila</h5>
            </div>
          </div>
          <div class="card bg-clinica mb-3" style="max-width: 20rem;margin-left: 0.7%">
            <div class="card-header">Clínica</div>
            <div class="card-body">
              <h5 class="card-title">Atividades Clínicas</h5>
            </div>
          </div>
        </div>

        <div class="row">
          <!-- <div class="card bg-ensino mb-3" style="max-width: 20rem;margin-left: 0.7%" (click)="criacaoseminarios()">
            <div class="card-header">Ensino</div>
            <div class="card-body">
              <h5 class="card-title">Criação de Seminários</h5>
            </div>
          </div> -->
          <div class="card bg-ensino mb-3" style="max-width: 20rem;margin-left: 0.7%">
            <div class="card-header">Ensino</div>
            <div class="card-body">
              <h5 class="card-title">Seminários</h5>
            </div>
          </div>
          <div class="card bg-ensino mb-3" style="max-width: 20rem;margin-left: 0.7%">
            <div class="card-header">Ensino</div>
            <div class="card-body">
              <h5 class="card-title">Supervisões</h5>
            </div>
          </div>
          <div class="card bg-ensino mb-3" style="max-width: 20rem;margin-left: 0.7%">
            <div class="card-header">Ensino</div>
            <div class="card-body">
              <h5 class="card-title">Trabalhos</h5>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="card bg-ensino mb-3" style="max-width: 20rem;margin-left: 0.7%">
            <div class="card-header">Ensino</div>
            <div class="card-body">
              <h5 class="card-title">Atividades Ensino</h5>
            </div>
          </div>
          <div class="card bg-cientifico mb-3" style="max-width: 20rem;margin-left: 0.7%">
            <div class="card-header">Científico</div>
            <div class="card-body">
              <h5 class="card-title">Atividades Científicas</h5>
            </div>
          </div>
          <div class="card text-white bg-administrativo mb-3" style="max-width: 20rem;margin-left: 0.7%" (click)="dashboard()">
            <div class="card-header">Administrativo</div>
            <div class="card-body">
              <h5 class="card-title">SURPRESA...</h5>
            </div>
          </div>
        </div>

      </div>

  </div>
</div>



<app-footer></app-footer>
