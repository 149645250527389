import { Component, OnInit }  from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import OrcamentoService from '../orcamento-service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { take } from 'rxjs';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';
import { Orcamento } from '../models/orcamento';
import { PlanoConta } from '../../planoconta/models/planoconta';
import { Categoria } from '../../categoria/models/categoria';
import { SocioResumo } from "../../associados/models/associados";
import PlanoContaService from '../../planoconta/planoconta-service';
import { AssociadosService } from '../../associados/associados.service';


@Component({
  selector: 'app-orcamento-edicao',
  templateUrl: './orcamento-edicao.component.html',
  styleUrls: ['./orcamento-edicao.component.css'],
})
export class OrcamentoEdicaoComponent implements OnInit {
  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }
  codigoOrcamento: string = '';
  form: FormGroup;
  PlanoConta: string = '';
  Complemento: string = '';
  orcamento: Orcamento = new Orcamento();
  PlanoContas: PlanoConta[] = [];
  Empresas: Categoria[] = [];
  PlanoContaAtual: PlanoConta = new PlanoConta();
  Situacao: string = 'A';
  Data: Date;
  Valor: string = '';
  Empresa1: SocioResumo = new SocioResumo();
  Pessoa1: string = '';
  Valor1: string = '';
  Condicao1: string = '';
  Fone1: string = '';
  Observacao1: string = '';
  Empresa2: SocioResumo = new SocioResumo();
  Pessoa2: string = '';
  Valor2: string = '';
  Condicao2: string = '';
  Fone2: string = '';
  Observacao2: string = '';
  Empresa3: SocioResumo = new SocioResumo();
  Pessoa3: string = '';
  Valor3: string = '';
  Condicao3: string = '';
  Fone3: string = '';
  Observacao3: string = '';
  listaFornecedorOriginal: SocioResumo[] = [];
  listaPlanoContaOriginal: PlanoConta[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private orcamentoService: OrcamentoService,
    private planoContaService: PlanoContaService,
    private associadosService: AssociadosService,
    private utilsservice: UtilsService

  ) {

  }

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    
    this.BuscaPlanoConta();  
    this.BuscaFornecedor();
    const { orcamento, listaPlanoConta, listaCategoria } = window.history.state;
    this.orcamento = orcamento
    this.PlanoContas = listaPlanoConta;
    this.Empresas = listaCategoria;
    this.PlanoContaAtual = this.orcamento.PlanoConta;
    this.Situacao = this.orcamento.Situacao || 'A';
    this.Data = this.orcamento.Data;
    this.Complemento =  this.orcamento.Complemento;
    this.Valor =  this.orcamento.Valor;
    this.Empresa1 = this.orcamento.Fornecedor1;
    this.Valor1 =  this.orcamento.Valor1;
    this.Pessoa1 = this.orcamento.Pessoa1;
    this.Condicao1 = this.orcamento.Condicao1;
    this.Fone1 = this.orcamento.Fone1;
    this.Observacao1 = this.orcamento.Observacao1;
    this.Empresa2 = this.orcamento.Fornecedor2;
    this.Pessoa2 = this.orcamento.Pessoa2;
    this.Valor2 =  this.orcamento.Valor2;
    this.Condicao2 = this.orcamento.Condicao2;
    this.Fone2 = this.orcamento.Fone2;
    this.Observacao2 = this.orcamento.Observacao2;
    this.Empresa3 = this.orcamento.Fornecedor3;
    this.Pessoa3 = this.orcamento.Pessoa3;
    this.Valor3 =  this.orcamento.Valor3;
    this.Condicao3 = this.orcamento.Condicao3;
    this.Fone3 = this.orcamento.Fone3;
    this.Observacao3 = this.orcamento.Observacao3;

    this.criarForm();
  }
 
 BuscaPlanoConta(): void {
    try {
      this.planoContaService
        .GetListaGeral()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                response.PlanoContaLista.forEach((item: any) => {
                    const tempList = new PlanoConta();
                    tempList.carregar(item);
                    this.listaPlanoContaOriginal.push(tempList);
                  });

            }
          },
          error: (error) => {
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.utilsservice.showHttpError(error);
    }
  }

  BuscaFornecedor(): void {
    try {
      this.associadosService
        .ListaFornecedores()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                response.SociosResumo.forEach((item: any) => {
                    const tempList = new SocioResumo();
                    tempList.carregar(item);
                    this.listaFornecedorOriginal.push(tempList);
                  });

            }
          },
          error: (error) => {
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.utilsservice.showHttpError(error);
    }
  }

  AlteraData($event: any, data: string)
  {
    this.orcamento.Data = new Date($event);
  }

  closeModal() {
    // Lógica para fechar o modal e chamar buscaFornecedor
    this.BuscaFornecedor();
  }

  SalvaOrcamento(): void {
    let requestOrcamento: Orcamento = new Orcamento();
    requestOrcamento.IdOrcamento = this.orcamento.IdOrcamento;
    requestOrcamento.Situacao = this.form.get("formSituacao")?.value;
    requestOrcamento.Data = this.form.get("formDataCompra")?.value;
    requestOrcamento.Valor = this.form.get("formValorCompra")?.value;
    requestOrcamento.PlanoConta = this.form.get("formPlanoContas")?.value;
    requestOrcamento.Complemento = this.form.get("formComplemento")?.value;

    // Adicionando campos para o fornecedor 1
    requestOrcamento.Fornecedor1 = this.form.get("formEmpresa1")?.value;
    requestOrcamento.Pessoa1 = this.form.get("formPessoa1")?.value;
    requestOrcamento.Valor1 = this.form.get("formValor1")?.value;
    requestOrcamento.Condicao1 = this.form.get("formCondicao1")?.value;
    requestOrcamento.Fone1 = this.form.get("formDDD1")?.value + this.form.get("formNumero1")?.value;
    requestOrcamento.Observacao1 = this.form.get("formObservacao1")?.value;

    // Adicionando campos para o fornecedor 2
    requestOrcamento.Fornecedor2 = this.form.get("formEmpresa2")?.value;
    requestOrcamento.Pessoa2 = this.form.get("formPessoa2")?.value;
    requestOrcamento.Valor2 = this.form.get("formValor2")?.value;
    requestOrcamento.Condicao2 = this.form.get("formCondicao2")?.value;
    requestOrcamento.Fone2 = this.form.get("formDDD2")?.value + this.form.get("formNumero2")?.value;
    requestOrcamento.Observacao2 = this.form.get("formObservacao2")?.value;

    // Adicionando campos para o fornecedor 3
    requestOrcamento.Fornecedor3 = this.form.get("formEmpresa3")?.value;
    requestOrcamento.Pessoa3 = this.form.get("formPessoa3")?.value;
    requestOrcamento.Valor3 = this.form.get("formValor3")?.value;
    requestOrcamento.Condicao3 = this.form.get("formCondicao3")?.value;
    requestOrcamento.Fone3 = this.form.get("formDDD3")?.value + this.form.get("formNumero3")?.value;
    requestOrcamento.Observacao3 = this.form.get("formObservacao3")?.value;

    if (!Orcamento.saoDiferentes(requestOrcamento, this.orcamento)) {
        this.messageService.showAlertDanger("Nenhum campo novo detectado para alteração.");
    } else {
        this.spinner.show();
        try {
            this.orcamentoService
                .SalvaOrcamento(requestOrcamento)
                .pipe(take(1))
                .subscribe({
                    next: (response) => {
                        this.spinner.hide();
                        if (response.Error) {
                            this.messageService.showAlertDanger(response.ErrorMessage);
                        } else {
                            this.voltar();
                        }
                    },
                    error: (error) => {
                        this.spinner.hide();
                        this.utilsservice.showHttpError(error);
                    },
                });
        } catch (error) {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
        }
    }
}


  criarForm() {
    const fone1 = this.orcamento.Fone1;
    this.form = this.formBuilder.group({
        formCodigoOrcamento: [this.codigoOrcamento, [Validators.maxLength(255)]],
        formPlanoContas: [this.listaPlanoContaOriginal],
        formSituacao: [this.Situacao],
        formDataCompra: [this.Data, Validators.required],
        formValorCompra: [this.Valor || '', [Validators.maxLength(255)]],
        formFornecedor: [this.listaFornecedorOriginal],
        formComplemento: [this.Complemento],
        formEmpresa1: [this.Empresa1],
        formPessoa1: [this.Pessoa1],
        formValor1: [this.Valor1],
        formCondicao1: [this.Condicao1],
        formDDD1: [this.Fone1 ? this.Fone1.substring(0,2) : ''],
        formNumero1: [this.Fone1 ? this.Fone1.substring(2) : ''],
        formObservacao1: [this.Observacao1],
        formEmpresa2: [this.Empresa2],
        formPessoa2: [this.Pessoa2],
        formValor2: [this.Valor2],
        formCondicao2: [this.Condicao2],
        formDDD2: [this.Fone2 ? this.Fone2.substring(0,2) : ''],
        formNumero2: [this.Fone2 ? this.Fone2.substring(2) : ''],
        formObservacao2: [this.Observacao2],
        formEmpresa3: [this.Empresa3],
        formPessoa3: [this.Pessoa3],
        formValor3: [this.Valor3],
        formCondicao3: [this.Condicao3],
        formDDD3: [this.Fone3 ? this.Fone3.substring(0,2) : ''],
        formNumero3: [this.Fone3 ? this.Fone3.substring(2) : ''],
        formObservacao3: [this.Observacao3],
      
    });
    this.form.get('formPlanoContas')?.setValue(this.PlanoContaAtual.CodigoPlanoConta);
    console.log(this.Empresa1.NomeSocio)
    this.form.get('formEmpresa1')?.setValue(this.Empresa1.NomeSocio ? this.Empresa1.NomeSocio : "");
    this.form.get('formEmpresa2')?.setValue(this.Empresa2.NomeSocio ? this.Empresa2.NomeSocio : "");
    this.form.get('formEmpresa3')?.setValue(this.Empresa3.NomeSocio ? this.Empresa3.NomeSocio : "");
  }

  voltar() {
    this.router.navigateByUrl('/administrativo/orcamentos');
  }
}
