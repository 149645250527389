import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RetAtividadeLista } from './models/ret-atividade';
import { AuthStorageService } from '../../Projeto/Autenticacao/auth-storage.service';
import { RetError } from 'src/app/Projeto/models/ret-error';
import { Atividade } from './models/atividade';

@Injectable({
  providedIn: 'root'
})

export default class AtividadeService {

  private readonly baseURL: string = '';
  constructor(private httpClient: HttpClient, private router: Router, private authStorageService: AuthStorageService) {
    this.baseURL = `${environment.AtividadeWS}`;
  }

  GetListaGeral(p_modulo: string): Observable<RetAtividadeLista> {
    const params = new HttpParams().set('TipoAtividade', p_modulo.toString());
    const url = `${this.baseURL}/BuscarAtividadeTipo`;
    return this.httpClient.get<RetAtividadeLista>(url, { params }).pipe(
      take(1),
      map((response: RetAtividadeLista) => {
        const tempRet: RetAtividadeLista = {
          Error: false,                  // Define Error como false por padrão, ou ajuste conforme necessário.
          ErrorMessage: '',              // Mensagem de erro vazia, já que não há erro explícito.
          AtividadeLista: response.AtividadeLista || []  // Usa o array de resposta ou um array vazio.
        };
        return tempRet;
      })
    );
}
  
  SalvaAtividade(atividade: Atividade): Observable<RetError> {
    let bodyString = JSON.stringify(atividade.toJson());

    const url = `${this.baseURL}/SalvaAtividade`;
    return this.httpClient.post<RetError>(url, bodyString ).pipe(
        take(1),
        map((response: RetError) => {
            return response;
        })
    );
  }   
}
