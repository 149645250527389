import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { take, Subscription } from 'rxjs';

import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';

import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { Atividade } from '../models/atividade';
import { ExcelService } from 'src/app/excel.service';
import AtividadeService from '../atividade-service';
import { DataTableFooterComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-atividade-lista',
  templateUrl: './atividade-lista.component.html',
  styleUrls: ['./atividade-lista.component.css'],
})
export class AtividadeListaComponent implements OnInit {
    tipoAtividade: string = 'Atividades';
    private routeSub: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private AtividadeService: AtividadeService,
    private utilsservice: UtilsService,
    private excelService: ExcelService,
  ) {}

  atividade: Atividade[] = [];
  atividadeOriginal: Atividade[] = [];
  novaAtividade: Atividade = new Atividade();
  DadosExcell: any = [];
  ColunasExcell: any = [];
  indexPagina: number = 1;
  totalLinhas: number = 0;  
  modulo: string = 'AA';
  @ViewChild('tFooter', { static: false }) tFooter: DataTableFooterComponent;
  //Variaveis - Detalhes dentro do ngx-datatable
  @ViewChild('myTable') table: any;

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.'
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.routeSub = this.route.queryParams.subscribe(params => {
      this.tipoAtividade = params['tipo'] || 'Atividades';
      this.modulo = this.getIniciais(this.tipoAtividade);
      this.isMobile = Utils.getIsMobile();
      window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.BuscaAtividade();
   });
  }

  getIniciais(tipoAtividade: string) {
    return tipoAtividade
        .split(/[\s\-]+/) // Divide a string por espaços ou hifens
        .filter(palavra => palavra.trim() !== '') // Remove palavras vazias
        .map(palavra => palavra[0]?.toUpperCase()) // Pega a primeira letra de cada palavra e converte para maiúscula
        .join(''); // Junta as letras em uma string
}

  BuscaAtividade(): void {
    console.log("this.tipoAtividade", this.tipoAtividade)
    console.log("this.modulo", this.modulo)
    this.spinner.show();
    this.atividadeOriginal = [];
    try {
      this.AtividadeService
        .GetListaGeral(this.modulo)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              //this.messageService.showAlertDanger(response.ErrorMessage);
              console.log("retirar o comentario do alerta")
            } else {
                response.AtividadeLista.forEach((item: any) => {
                    const tempList = new Atividade();
                    tempList.carregar(item);
                    this.atividadeOriginal.push(tempList);
                  });
            }
            this.atividade.sort((a: Atividade, b: Atividade) => {
                return new Date(b.Periodo).getTime() - new Date(a.Periodo).getTime();
            });
            this.atividade = this.atividadeOriginal;
            //this.my_messages.totalMessage = `${this.GetTotalLinhas()} linhas encontradas.`
            this.my_messages.totalMessage = ` linhas encontradas.`
          },
          error: (error) => {
            this.spinner.hide();
            //this.utilsservice.showHttpError(error);
            console.log("retirar o comentario do alerta")
            this.preencherComValoresAleatorios();
          },
        });
    } catch (error) {
      this.spinner.hide();
      //this.utilsservice.showHttpError(error);
      console.log("retirar o comentario do alerta")
      this.preencherComValoresAleatorios();
    }
  }
  
// Função para preencher a lista com valores aleatórios para teste
  preencherComValoresAleatorios(): void {
    const randomAtividades: Atividade[] = [];
    for (let i = 0; i < 5; i++) { // Preenche 5 orçamentos fictícios
      const atividadeAleatoria = new Atividade();
      atividadeAleatoria.Seminario = `Seminario${i + 1}`;
      atividadeAleatoria.Titulo = `Titulo${i + 1}`;
      atividadeAleatoria.PalavraChave = `PalavraChave${i + 1}`;
      atividadeAleatoria.Coordenacao = `Coordenacao_${i + 1}`;
      atividadeAleatoria.Situacao = `Situacao_${i + 1}`;
      atividadeAleatoria.Periodo = new Date(
        Date.now() - Math.floor(Math.random() * 10000000000)
      ); // Data aleatória
      atividadeAleatoria.Valor = (Math.random() * 10000).toString(); // Valor aleatório
      atividadeAleatoria.PossuiAnexos = `${i + 1} anexo(s)`;
      atividadeAleatoria.ObservacaoGeral = `OutrasInfo${i + 1}`;

      randomAtividades.push(atividadeAleatoria);
    }
    
    this.atividadeOriginal = randomAtividades;
    this.atividade = this.atividadeOriginal;
  }

  Edicao(p_atividade: Atividade) {
    console.log('p_atividade', p_atividade)
    const navAtividade = {
        ...p_atividade,
        DataInicio: p_atividade.DataInicio ? new Date(p_atividade.DataInicio) : null,
        DataFim: p_atividade.DataFim ? new Date(p_atividade.DataFim) : null
      };
      console.log(navAtividade)
     this.router.navigateByUrl('/atividades/edicao', { state: { atividade: navAtividade }});
   }

  Adicionar() {
    this.novaAtividade = new Atividade();
    this.novaAtividade.IdAtividade = -1;
    this.router.navigateByUrl('/atividades/edicao', {
      state: { atividade: this.novaAtividade },
    });
   } 

   gerarCertificado(atividade: Atividade) {
    console.log("Gerar certificado aqui")
  }
  //Rotinas - Detalhes dentro do ngx-datatable
  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    const temp = this.atividadeOriginal.filter(function (d: any) {
      return (
        d.DescricaoPlanoConta.toLowerCase().indexOf(val) !== -1 || d.Seminario.toLowerCase().indexOf(val) !== -1
      );
    });
    // update the rows
    this.atividade = temp;
    return true;
  }

  GetTotalLinhas() {
    if (this.atividadeOriginal){
        this.totalLinhas = Math.max(...this.atividadeOriginal.map(atividade => atividade.TotalLinhas));
    }
    return this.totalLinhas;
  };

  handleFooter(event: any) {
    this.indexPagina = event.page; 
    this.BuscaAtividade(); 
  }

  generateExcell() {
    var nomeArquivo: string = 'RelatorioAtividade';
    var Titulo: string = 'Relatório de Atividades';
    this.ColunasExcell = [];
    this.ColunasExcell.push('Seminário');
    this.ColunasExcell.push('Título');
    this.ColunasExcell.push('Palavra Chave');
    this.ColunasExcell.push('Coordenação');
    this.ColunasExcell.push('Situação');
    this.ColunasExcell.push('Período');
    this.ColunasExcell.push('Valor');
    this.ColunasExcell.push('Possui Anexos');
    this.ColunasExcell.push('Outros');
    this.DadosExcell = [];
    this.atividade.forEach((item: Atividade) => {
      const varExcell: any = [];
      varExcell.push(item.Seminario);
      varExcell.push(item.Titulo);
      varExcell.push(item.PalavraChave);
      varExcell.push(item.Coordenacao);
      varExcell.push(item.Situacao);
      varExcell.push(item.converterPeriodo());
      varExcell.push(item.converterValor());
      varExcell.push(item.PossuiAnexos);

      this.DadosExcell.push(varExcell);
    });
    this.excelService.generateExcel(
      nomeArquivo,
      Titulo,
      this.ColunasExcell,
      this.DadosExcell
    );
 }

}
