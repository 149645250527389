export class ListaSeminariosComPart {
  CodigoEnsino: number;
  TituloEnsino: string;
  NomeETituloEnsino: string;
  CodigoEnsinoNome: number;
  NomeEnsinoNome: string;

  constructor() {
    this.CodigoEnsinoNome = 0;
    this.NomeEnsinoNome = '';
    this.CodigoEnsino = 0;
    this.TituloEnsino = '';
    this.NomeETituloEnsino = '';
  }

}

export class EnsinoNomeComAssociado {
  CodigoEnsinoNome: number;
  NomeEnsinoNome: string;
  TipoEnsino: string;
  TipoEnsinoDescr: string;
  ValorEnsino: number;
  NroEnsino: number;
  CodigoSocio: number;
  NomeSocio: string;
  PeriodoEnsino: string;

  constructor() {
    this.CodigoEnsinoNome = 0;
    this.NomeEnsinoNome = '';
    this.CodigoSocio = 0;
    this.NomeSocio = '';
    this.ValorEnsino = 0;
    this.NroEnsino = 0;
    this.TipoEnsino = '';
    this.TipoEnsinoDescr = '';
    this.PeriodoEnsino = '';
  }
}
