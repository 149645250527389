import { Utils } from "src/app/Projeto/Utils/utils";

export class Inscricao {
    Nome: string;
    Situacao: string;
    

    constructor() {
        this.Nome = '';
        this.Situacao = '';
        
    }

    carregar(objeto: any): void {
        this.Nome = objeto.Nome || 0;
        this.Situacao = objeto.Situação || 0;
        
    }
    
    toJson(): any {
        return {
            Nome: this.Nome,
            Situação: this.Situacao
           
        };
    }

    static saoDiferentes(dados1: Inscricao, dados2: Inscricao): boolean {
        return dados1.Nome !== dados2.Nome ||
        dados1.Situacao !== dados2.Situacao;
              
    }
}
