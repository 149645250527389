import { Utils } from "src/app/Projeto/Utils/utils";

export class Lancamento {
    CodigoLancamento: number;
    CodigoChave: number;
    CodigoPlanoConta: number;
    DataLancamento: Date;
    ValorLancamento: string;
    DescExtraPlanoconta: string;
    DescricaoPlanoConta: string;
    TipoPlanoConta: string;
    OrigemLancamento: string;
    CodDescrPlanoConta: string;
    NroCheque: number;
    RecebeuBoleto: string;
    Estoque: boolean;
    Boleto: string;
    Compensado: string;
    CodigoTipoLancamento: number;
    NomeTipoLancamento: string;
    NomeSocioLancamento: string;
    CodigoSocioLancamento: number;
    CodigoCentroCusto: number;
    NomeCentroCusto: string;
    CodigoFornecedor: number;
    NomeFornecedor: string;
    TotalLinhas: number;

    constructor() {
        this.CodigoLancamento = 0;
        this.CodigoChave = 0;
        this.CodigoPlanoConta = 0;
        this.DataLancamento = new Date();
        this.ValorLancamento = '';
        this.DescExtraPlanoconta = '';
        this.DescricaoPlanoConta = '';
        this.TipoPlanoConta = '';
        this.OrigemLancamento = '';
        this.CodDescrPlanoConta = '';
        this.NroCheque = 0;
        this.RecebeuBoleto = '';
        this.Estoque = false;
        this.Boleto = '';
        this.Compensado = '';
        this.CodigoTipoLancamento = 0;
        this.NomeTipoLancamento = '';
        this.CodigoSocioLancamento = 0;
        this.NomeSocioLancamento = '';
        this.CodigoCentroCusto = 0;
        this.NomeCentroCusto = '';
        this.CodigoFornecedor = 0;
        this.NomeFornecedor = '';
        this.TotalLinhas = 0;
    }

    carregar(objeto: any): void {
        this.CodigoLancamento = objeto.CodigoLancamento || 0;
        this.CodigoChave = objeto.CodigoChave || 0;
        this.CodigoPlanoConta = objeto.CodigoPlanoConta || 0;
        this.DataLancamento =  Utils.getDateTimeUTC(objeto.DataLancamento) || this.DataLancamento.getDate();
        this.ValorLancamento = objeto.ValorLancamento || '';
        this.DescExtraPlanoconta =objeto.DescExtraPlanoconta || '';
        this.DescricaoPlanoConta = objeto.DescricaoPlanoConta || '';
        this.TipoPlanoConta = objeto.TipoPlanoConta || '';
        this.OrigemLancamento = objeto.OrigemLancamento || '';
        this.CodDescrPlanoConta = objeto.CodDescrPlanoConta || '';
        this.NroCheque = objeto.NroCheque || 0;
        this.RecebeuBoleto = objeto.RecebeuBoleto || '';
        this.Estoque = objeto.Estoque || false;
        this.Boleto = objeto.Boleto || '';
        this.Compensado = objeto.Compensado || '';
        this.CodigoTipoLancamento = objeto.CodigoTipoLancamento || 0;
        this.NomeTipoLancamento = objeto.NomeTipoLancamento || '';
        this.CodigoSocioLancamento = objeto.CodigoSocioLancamento || 0;
        this.NomeSocioLancamento = objeto.NomeSocioLancamento || '';
        this.CodigoCentroCusto = objeto.CodigoCentroCusto || 0;
        this.NomeCentroCusto = objeto.NomeCentroCusto || '';
        this.CodigoFornecedor = objeto.CodigoFornecedor || 0;
        this.NomeFornecedor = objeto.NomeFornecedor || '';
        this.TotalLinhas = objeto.TotalLinhas || 0;
    }

    converterDataLancamento(): string {
        const data = new Date(this.DataLancamento);
        const dia = String(data.getDate()).padStart(2, '0');
        const mes = String(data.getMonth() + 1).padStart(2, '0');
        const ano = data.getFullYear();
        return `${dia}/${mes}/${ano}`;
    }

    converterValorLancamento(): string {
        const valor = parseFloat(this.ValorLancamento);
        return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    toJson(): any {
        return {
            CodigoLancamento: this.CodigoLancamento,
            CodigoChave: this.CodigoChave,
            CodigoPlanoConta: this.CodigoPlanoConta,
            DataLancamento: this.DataLancamento.toISOString(),
            ValorLancamento: this.ValorLancamento,
            DescExtraPlanoconta: this.DescExtraPlanoconta,
            DescricaoPlanoConta: this.DescricaoPlanoConta,
            TipoPlanoConta: this.TipoPlanoConta,
            OrigemLancamento: this.OrigemLancamento,
            CodDescrPlanoConta: this.CodDescrPlanoConta,
            NroCheque: this.NroCheque,
            RecebeuBoleto: this.RecebeuBoleto,
            Estoque: this.Estoque,
            Boleto: this.Boleto,
            Compensado: this.Compensado,
            CodigoTipoLancamento: this.CodigoTipoLancamento,
            NomeTipoLancamento: this.NomeTipoLancamento,
            CodigoSocioLancamento: this.CodigoSocioLancamento,
            NomeSocioLancamento: this.NomeSocioLancamento,
            CodigoCentroCusto: this.CodigoCentroCusto,
            NomeCentroCusto: this.NomeCentroCusto,
            CodigoFornecedor: this.CodigoFornecedor,
            NomeFornecedor: this.NomeFornecedor,
            TotalLinhas: this.TotalLinhas
        };
    }

    static saoDiferentes(lancamento1: Lancamento, lancamento2: Lancamento): boolean {
        return lancamento1.CodigoLancamento !== lancamento2.CodigoLancamento ||
               lancamento1.CodigoPlanoConta !== lancamento2.CodigoPlanoConta ||
               lancamento1.DataLancamento !== lancamento2.DataLancamento ||
               lancamento1.ValorLancamento !== lancamento2.ValorLancamento ||
               lancamento1.DescExtraPlanoconta !== lancamento2.DescExtraPlanoconta ||
               lancamento1.OrigemLancamento !== lancamento2.OrigemLancamento ||
               lancamento1.NroCheque !== lancamento2.NroCheque ||
               lancamento1.RecebeuBoleto !== lancamento2.RecebeuBoleto ||
               lancamento1.Estoque !== lancamento2.Estoque ||
               lancamento1.RecebeuBoleto !== lancamento2.RecebeuBoleto ||
               lancamento1.Compensado !== lancamento2.Compensado ||
               lancamento1.CodigoFornecedor !== lancamento2.CodigoFornecedor;
    }
}
