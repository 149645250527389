import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AreaAtuacaoService } from '../../../Sistema/areaatuacao/areaatuacao.service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { take } from 'rxjs';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';

@Component({
  selector: 'app-areaatuacao-edicao',
  templateUrl: './areaatuacao-edicao.component.html',
  styleUrls: ['./areaatuacao-edicao.component.css'],
})
export class AreaAtuacaoEdicaoComponent implements OnInit {
  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  codigoAreaAtuacao: number = 0;
  form: FormGroup;
  nomeAreaAtuacao: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private areaAtuacaoService: AreaAtuacaoService,
    private utilsservice: UtilsService
  ) {

  }

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    const { codigo, nome } = window.history.state;
    this.codigoAreaAtuacao = (typeof codigo === 'number' && codigo > 0) ? codigo : -1;
    this.nomeAreaAtuacao = (typeof nome === 'string' && nome.length > 0) ? nome : "";
    this.criarForm(codigo);
  }

  SalvaAreaAtuacao(): void {
    this.nomeAreaAtuacao = this.form.get("formNomeAreaAtuacao")?.value;
    if (this.nomeAreaAtuacao == '')
        this.messageService.showAlertDanger("Nome da Area de Atuação deve ser informada.");
    else {
      this.spinner.show();
      try {
        this.areaAtuacaoService
          .SalvaAreaAtuacao(
            this.codigoAreaAtuacao,
            this.nomeAreaAtuacao,
          )
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              this.spinner.hide();
              if (response.Error) {
                this.messageService.showAlertDanger(response.ErrorMessage);
              } else {
                this.voltar();
              }
            },
            error: (error) => {
              this.spinner.hide();
              this.utilsservice.showHttpError(error);
            },
          });
      } catch (error) {
        this.spinner.hide();
        this.utilsservice.showHttpError(error);
      }
    }
  }

  criarForm(codigo: number) {
    this.form = this.formBuilder.group({
      formNomeAreaAtuacao: [this.nomeAreaAtuacao, [Validators.maxLength(100)]],
    });
  }

  voltar() {
    this.router.navigateByUrl('/administrativo/areaatuacao');
  }
}
