import { Utils } from "src/app/Projeto/Utils/utils";

export class Atividade {
    IdAtividade: number;
    NomeModulo: string;
    Seminario: string;
    Titulo: string;
    PalavraChave: string;
    Coordenacao: string;
    Situacao: string;
    Periodo: Date;
    Valor: string;
    Certificado: string;
    PossuiAnexos: string;
    ObservacaoGeral: string;
    TotalLinhas: number;
    
    DataInicio: Date;
    DataFim: Date;
    CargaHoraria: number;
    Local: string;
    ValorSocio: string;
    ValorNaoSocio: string;
    ValorEstudanteSocio: string;
    ValorEstudante: string;
    TipoPagamento: string;

    constructor() {
        this.IdAtividade = -1;
        this.NomeModulo = '';
        this.Seminario = '';
        this.Titulo = '';
        this.PalavraChave = '';
        this.Coordenacao = '';
        this.Situacao = '';
        this.Periodo = new Date();  
        this.Valor = '';
        this.Certificado = '';
        this.PossuiAnexos = '';
        this.TotalLinhas = 0;
        this.DataInicio = new Date();
        this.DataFim = new Date();

        this.DataInicio = new Date();
        this.DataFim = new Date();
        this.CargaHoraria = 0;
        this.Local = '';
        this.ObservacaoGeral = '';
        this.ValorSocio = '';
        this.ValorNaoSocio = '';
        this.ValorEstudanteSocio = '';
        this.ValorEstudante = '';
        this.TipoPagamento = '';
    }

    carregar(objeto: any): void {
        this.IdAtividade = objeto?.IdAtividade ?? -1; // Valida undefined ou null
        this.NomeModulo = objeto?.NomeModulo ?? '';
        this.Seminario = objeto?.Seminario ?? '';
        this.Titulo = objeto?.Titulo ?? '';
        this.PalavraChave = objeto?.PalavraChave ?? '';
        this.Coordenacao = objeto?.Coordenacao ?? '';
        this.Situacao = objeto?.Situacao ?? '';
        this.Periodo = objeto?.Periodo ?? new Date();
        this.Valor = objeto?.Valor ?? '0';
        this.Certificado = objeto?.Certificado ?? '';
        this.PossuiAnexos = objeto?.PossuiAnexos ?? '';
        this.TotalLinhas = objeto?.TotalLinhas ?? 0;
    
        this.DataInicio = objeto?.DataInicio ?? new Date();
        this.DataFim = objeto?.DataFim ?? new Date();
        this.CargaHoraria = objeto?.CargaHoraria ?? 0;
        this.Local = objeto?.Local ?? '';
        this.ObservacaoGeral = objeto?.ObservacaoGeral ?? '';
        this.ValorSocio = objeto?.ValorSocio ?? '';
        this.ValorNaoSocio = objeto?.ValorNaoSocio ?? '';
        this.ValorEstudanteSocio = objeto?.ValorEstudanteSocio ?? '';
        this.ValorEstudante = objeto?.ValorEstudante ?? '';
        this.TipoPagamento = objeto?.TipoPagamento ?? '';
    }

    converterPeriodo(): string {
        const data = new Date(this.Periodo);
        const dia = String(data.getDate()).padStart(2, '0');
        const mes = String(data.getMonth() + 1).padStart(2, '0');
        const ano = data.getFullYear();
        return `${dia}/${mes}/${ano}`;
    }

    converterValor(): string {
        const valor = parseFloat(this.Valor);
        return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    toJson(): any {
        return {
            IdAtividade: this.IdAtividade,
            Seminario: this.Seminario,
            Titulo: this.Titulo,
            PalavraChave: this.PalavraChave,
            Coordenacao: this.Coordenacao,
            Situacao: this.Situacao,
            Periodo: this.Periodo,
            Valor: this.Valor,
            Certificado: this.Certificado,
            PossuiAnexos: this.PossuiAnexos,
            TotalLinhas: this.TotalLinhas
        };
    }

    static saoDiferentes(atividade1: Atividade, atividade2: Atividade): boolean {
        return atividade1.Seminario !== atividade2.Seminario ||
               atividade1.Titulo !== atividade2.Titulo ||
               atividade1.PalavraChave !== atividade2.PalavraChave ||
               atividade1.Coordenacao !== atividade2.Coordenacao ||
               atividade1.Situacao !== atividade2.Situacao ||
               atividade1.Periodo !== atividade2.Periodo ||
               atividade1.Valor !== atividade2.Valor ||
               atividade1.Certificado !== atividade2.Certificado ||
               atividade1.PossuiAnexos !== atividade2.PossuiAnexos ||
               atividade1.TotalLinhas !== atividade2.TotalLinhas;
    }
}
