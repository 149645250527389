import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../../../Projeto/message/message.service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';
import { Associado } from '../models/associados';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { AssociadosService } from '../associados.service';
import { take } from 'rxjs';
import { AssociadoEdicaoService } from '../associadosedicao.service';
//import { AssociadosEdicaoPrioComponent } from "../associados-edicao-prio/associados-edicao-prio.component"

@Component({
  selector: 'app-associado-edicao',
  templateUrl: './associados-edicao.component.html',
  styleUrls: ['./associados-edicao.component.css'],
})
export class AssociadosEdicaoComponent implements OnInit {
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    public messageService: MessageService,
    private utilsservice: UtilsService,
    //private AssociadosEdicaoPrioComp: AssociadosEdicaoPrioComponent,
    private associadosService: AssociadosService,
    public associadosEdicao: AssociadoEdicaoService
  ) {}

  aba: string = 'S1';
  abaHelp: string = 'S1Help';
  CodigoAssociado: number = 0;
  Origem: string = '';
  assoc: Associado  = new Associado();
  form: FormGroup;

  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    const { associado, Origem } = window.history.state;
    this.assoc = associado;
    this.CodigoAssociado = this.assoc.CodigoSocio;
    this.Origem = Origem;
    if(this.Origem == "CATEG")
    { this.setAba("S3") }
  }

  isActiveAba(aba: string): boolean {
    return this.aba == aba;
  }

  setAba(aba: string) {
    this.aba = aba;
  }

  isActiveAbaHelp(aba: string): boolean {
    return this.abaHelp == aba;
  }

  setAbaHelp(aba: string) {
    this.abaHelp = aba;
  }

  SalvaAssociado(): void {

    try {
      if (this.associadosEdicao.EdicaoPrio.controls['NomeSocio'].value == '') {this.messageService.showAlertDanger("Nome deve ser informado."); }
      else if (this.associadosEdicao.EdicaoPrio.controls['CodigoCategoria'].value == 0) {this.messageService.showAlertDanger("Categoria deve ser informada."); }
      else if (this.Origem == "CONSULTA" && this.associadosEdicao.EdicaoPrio.controls['Telefone1Socio'].value == '' && this.associadosEdicao.EdicaoPrio.controls['Telefone2Socio'].value == ''  && this.associadosEdicao.EdicaoPrio.controls['Telefone3Socio'].value == '') {this.messageService.showAlertDanger("Fone para contato deve ser informado na marcação de consulta."); }
      else if (this.associadosEdicao.EdicaoPrio.controls['PerfilSocio'].value != 'SEMA' && this.associadosEdicao.EdicaoPrio.controls['CodigoSocio'].value == -1 && (this.associadosEdicao.EdicaoPrio.controls['UsuarioSocio'].value == '' || this.associadosEdicao.EdicaoPrio.controls['SenhaSocio'].value == '' )) { this.messageService.showAlertDanger("Na inclusão usuário e senha devem ser informados."); }
      else if (this.associadosEdicao.EdicaoPrio.controls['PerfilSocio'].value != 'SEMA' && this.associadosEdicao.EdicaoPrio.controls['CodigoSocio'].value != -1 && (this.associadosEdicao.EdicaoPrio.controls['SenhaSocioOriginal'].value != this.associadosEdicao.EdicaoPrio.controls['SenhaSocio'].value && this.associadosEdicao.EdicaoPrio.controls['SenhaSocio'].value != '' )) { this.messageService.showAlertDanger("Senha atual informada não corresponde à senha cadastrada."); }
      else if (this.associadosEdicao.EdicaoPrio.controls['PerfilSocio'].value != 'SEMA' && this.associadosEdicao.EdicaoPrio.controls['CodigoSocio'].value != -1 && (this.associadosEdicao.EdicaoPrio.controls['NovaSenhaSocio'].value != this.associadosEdicao.EdicaoPrio.controls['ConfirmaSenhaSocio'].value)) { this.messageService.showAlertDanger("Nova senha informada não corresponde à confirmação."); }
      else if (this.associadosEdicao.EdicaoPrio.controls['PerfilSocio'].value != 'SEMA' && this.associadosEdicao.EdicaoEndereco.controls['EnderecoSocio'].value == '') { this.messageService.showAlertDanger("Endereço deve ser informado."); }
      else if (this.associadosEdicao.EdicaoPrio.controls['PerfilSocio'].value != 'SEMA' && this.associadosEdicao.EdicaoEndereco.controls['BairroSocio'].value == '') { this.messageService.showAlertDanger("Bairro deve ser informado."); }
      else if (this.associadosEdicao.EdicaoPrio.controls['PerfilSocio'].value != 'SEMA' && this.associadosEdicao.EdicaoEndereco.controls['CidadeSocio'].value == '') { this.messageService.showAlertDanger("Cidade deve ser informada."); }
      else if (this.associadosEdicao.EdicaoPrio.controls['PerfilSocio'].value != 'SEMA' && this.associadosEdicao.EdicaoEndereco.controls['UFSocio'].value == '') { this.messageService.showAlertDanger("UF deve ser informada."); }
      else if (this.associadosEdicao.EdicaoPrio.controls['PerfilSocio'].value != 'SEMA' && this.associadosEdicao.EdicaoEndereco.controls['CepSocio'].value == '') { this.messageService.showAlertDanger("CEP deve ser informado."); }
      else {
        this.spinner.show();
        //this.AssociadosEdicaoPrioComp.changeImagem()
        this.assoc = new Associado();
        this.assoc.CodigoSocio = this.associadosEdicao.EdicaoPrio.controls['CodigoSocio'].value;
        this.assoc.NomeSocio = this.associadosEdicao.EdicaoPrio.controls['NomeSocio'].value;
        this.assoc.CodigoCategoria = this.associadosEdicao.EdicaoPrio.controls['CodigoCategoria'].value;
        this.assoc.CodigoCategoria2 = this.associadosEdicao.EdicaoPrio.controls['CodigoCategoria2'].value;
        this.assoc.CodigoCategoriaOri = this.associadosEdicao.EdicaoPrio.controls['CodigoCategoriaOri'].value;
        this.assoc.EnderecoSocio = this.associadosEdicao.EdicaoEndereco.controls['EnderecoSocio'].value;
        this.assoc.ComplementoSocio = this.associadosEdicao.EdicaoEndereco.controls['ComplementoSocio'].value;
        this.assoc.BairroSocio = this.associadosEdicao.EdicaoEndereco.controls['BairroSocio'].value;
        this.assoc.CidadeSocio = this.associadosEdicao.EdicaoEndereco.controls['CidadeSocio'].value;
        this.assoc.UFSocio = this.associadosEdicao.EdicaoEndereco.controls['UFSocio'].value;
        this.assoc.CepSocio = this.associadosEdicao.EdicaoEndereco.controls['CepSocio'].value;
        this.assoc.TipoEnderecoSocio = this.associadosEdicao.EdicaoEndereco.controls['TipoEnderecoSocio'].value;
        this.assoc.EnderecoSocio2 = this.associadosEdicao.EdicaoEndereco.controls['EnderecoSocio2'].value;
        this.assoc.ComplementoSocio2 = this.associadosEdicao.EdicaoEndereco.controls['ComplementoSocio2'].value;
        this.assoc.BairroSocio2 = this.associadosEdicao.EdicaoEndereco.controls['BairroSocio2'].value;
        this.assoc.CidadeSocio2 = this.associadosEdicao.EdicaoEndereco.controls['CidadeSocio2'].value;
        this.assoc.UFSocio2 = this.associadosEdicao.EdicaoEndereco.controls['UFSocio2'].value;
        this.assoc.CepSocio2 = this.associadosEdicao.EdicaoEndereco.controls['CepSocio2'].value;
        this.assoc.TipoEnderecoSocio2 = this.associadosEdicao.EdicaoEndereco.controls['TipoEnderecoSocio2'].value;
        this.assoc.Telefone1Socio = this.associadosEdicao.EdicaoPrio.controls['Telefone1Socio'].value;
        this.assoc.Telefone2Socio = this.associadosEdicao.EdicaoPrio.controls['Telefone2Socio'].value;
        this.assoc.Telefone3Socio = this.associadosEdicao.EdicaoPrio.controls['Telefone3Socio'].value;
        this.assoc.DataNascimentoSocio =  this.associadosEdicao.EdicaoPrio.controls['DataNascimentoSocio'].value;
        this.assoc.AtivoSocio = this.associadosEdicao.EdicaoPrio.controls['AtivoSocio'].value;
        this.assoc.BolsistaSocio = this.associadosEdicao.EdicaoPrio.controls['BolsistaSocio'].value;
        this.assoc.BolsaSuspensaSocio = this.associadosEdicao.EdicaoPrio.controls['BolsaSuspensaSocio'].value;
        this.assoc.AcoesAfirmativasSocio = this.associadosEdicao.EdicaoPrio.controls['AcoesAfirmativasSocio'].value;
        this.assoc.TipoSocio = this.associadosEdicao.EdicaoPrio.controls['TipoSocio'].value;
        this.assoc.DescontoSalaSocio = this.associadosEdicao.EdicaoPrio.controls['DescontoSalaSocio'].value;
        this.assoc.EmailSocio = this.associadosEdicao.EdicaoPrio.controls['EmailSocio'].value;
        this.assoc.ObservacoesSocio = this.associadosEdicao.EdicaoPrio.controls['ObservacoesSocio'].value;
        this.assoc.PerfilSocio = this.associadosEdicao.EdicaoPrio.controls['PerfilSocio'].value;
        this.assoc.SenhaSocio = this.associadosEdicao.EdicaoPrio.controls['SenhaSocio'].value;
        this.assoc.NovaSenhaSocio = this.associadosEdicao.EdicaoPrio.controls['NovaSenhaSocio'].value;
        this.assoc.ConfirmaSenhaSocio = this.associadosEdicao.EdicaoPrio.controls['ConfirmaSenhaSocio'].value;
        this.assoc.UsuarioSocio = this.associadosEdicao.EdicaoPrio.controls['UsuarioSocio'].value;
        this.assoc.CPF_CNPJSocio = this.associadosEdicao.EdicaoPrio.controls['CPF_CNPJSocio'].value;
        this.assoc.CodigoSocioEnc = this.associadosEdicao.EdicaoEndereco.controls['CodigoSocioEnc'].value;
        this.assoc.NomeSocioEnc = this.associadosEdicao.EdicaoEndereco.controls['NomeSocioEnc'].value;
        this.assoc.ArqAssinaturaSocio = this.associadosEdicao.EdicaoEndereco.controls['ArqAssinaturaSocio'].value;
        this.assoc.ArqAssinaturaSocioBase64 = this.associadosEdicao.EdicaoEndereco.controls['ArqAssinaturaSocioBase64'].value;
        this.assoc.CodigoAreaAtuacao = this.associadosEdicao.EdicaoPrio.controls['CodigoAreaAtuacao'].value;
        this.assoc.NrInscrProfSocio = this.associadosEdicao.EdicaoPrio.controls['NrInscrProfSocio'].value;
        this.assoc.RGSocio = this.associadosEdicao.EdicaoPrio.controls['RGSocio'].value;
        this.assoc.EmailAltSocio = this.associadosEdicao.EdicaoPrio.controls['EmailAltSocio'].value;
        this.assoc.NomeEmerSocio = this.associadosEdicao.EdicaoPrio.controls['NomeEmerSocio'].value;
        this.assoc.TelefoneEmerSocio = this.associadosEdicao.EdicaoPrio.controls['TelefoneEmerSocio'].value;
        this.assoc.DataIngressoSocio = this.associadosEdicao.EdicaoPrio.controls['DataIngressoSocio'].value;
        this.assoc.DataDesligamentoSocio = this.associadosEdicao.EdicaoPrio.controls['DataDesligamentoSocio'].value;
        this.assoc.DataRetornoSocio = this.associadosEdicao.EdicaoPrio.controls['DataRetornoSocio'].value;
        this.assoc.IngressoFormacaoSocio = this.associadosEdicao.EdicaoPrio.controls['IngressoFormacaoSocio'].value;

        this.associadosService
          .SalvaAssociado(this.assoc)
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              this.spinner.hide();
              if (response.Error) {
                this.messageService.showAlertDanger(response.ErrorMessage);
              } else {
                this.voltar();
              }
            },
            error: (error) => {
              this.spinner.hide();
              this.utilsservice.showHttpError(error);
            },
          });
      }
    } catch (error) {
        this.spinner.hide();
        this.utilsservice.showHttpError(error);
    }

  }

  voltar() {

    if (this.Origem == "LISTA" || this.Origem == "CATEG")
    { this.router.navigateByUrl('/cadastrogeral/lista'); }

  }

}
