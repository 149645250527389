import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RetLancamentoLista } from './models/ret-lancamento';
import { RetTipoLancamentoLista } from './models/ret-tipoLancamento';
import { Lancamento } from './models/lancamento';
import { AuthStorageService } from '../../Projeto/Autenticacao/auth-storage.service';
import { RetError } from 'src/app/Projeto/models/ret-error';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export default class LancamentoService {

  private readonly baseURL: string = '';
  constructor(private httpClient: HttpClient, private router: Router, private authStorageService: AuthStorageService) {
    this.baseURL = `${environment.LancamentoWS}`;
  }
  private origemSubTelaSource = new BehaviorSubject<string>("");
  origemSubTela$ = this.origemSubTelaSource.asObservable();

  setOrigemSubtela(value: string) {
    this.origemSubTelaSource.next(value);
  }
  GetListaGeral(p_index: number, p_id: number, p_origem: number): Observable<RetLancamentoLista> {
    console.log(this.origemSubTelaSource)
    console.log(p_origem)
    const params  = p_origem === 0 ? new HttpParams() 
    .set('p_index', p_index.toString())
    .set('p_id', p_id.toString()) : new HttpParams()
    .set('p_index', p_index.toString())
    .set('p_id', p_id.toString())
    .set('p_origem', p_origem.toString()); 

    const url = `${this.baseURL}/ListaGeral`;

    return this.httpClient.get<Lancamento[]>(url, { params }).pipe(
      take(1),
      map((response: Lancamento[]) => {
        const tempRet: RetLancamentoLista = {
          Error: false,                  // Define Error como false por padrão, ou ajuste conforme necessário.
          ErrorMessage: '',              // Mensagem de erro vazia, já que não há erro explícito.
          LancamentoLista: response || []  // Usa o array de resposta ou um array vazio.
        };
        return tempRet;
      })
    );
}

  GetTipoLancamento(): Observable<RetTipoLancamentoLista> {
    const url = `${this.baseURL}/TipoLancamento`;
    return this.httpClient.get<RetTipoLancamentoLista>(url).pipe(
      take(1),
      map((response: RetTipoLancamentoLista) => {
        return response;
    })
    );
    
  }

  SalvaLancamento(lancamento: Lancamento): Observable<RetError> {
    let bodyString = JSON.stringify(lancamento.toJson());

    const url = `${this.baseURL}/SalvaLancamento`;
    return this.httpClient.post<RetError>(url, bodyString ).pipe(
        take(1),
        map((response: RetError) => {
            return response;
        })
    );
  }   
}
