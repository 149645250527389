<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="d-flex align-items-center justify-content-center h-100">
        <div class="col-lg-8">
          <div class="card-title-form">Categoria de {{assoc.NomeSocio}}
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
              [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
              aria-controls="offcanvasExample">
              <i class="fa-solid fa-circle-question"></i>
            </span>
          </div>

          <div class="card-body-form">
            <form [formGroup]="form">
              <div class="row">
                <div class="form-outline">
                  <label class="form-label">Categoria:</label>
                  <app-categoria-pesquisa  id="formCategoria" formControlName="formCategoria" name="formCategoria" [disabled]="associadosHistorico.CodigoSocioCategoriaHist != -1"
                    (CategoriaOut)="changeCategoria($event);" [CodigoCategoriaPreench]="associadosHistorico.CodigoCategoria">
                  </app-categoria-pesquisa>
                  <app-field-error-message [control]="form.get('formCategoria')"
                    label="categoria"></app-field-error-message>
                </div>
              </div>
              <div class="row top1">
                  <span class="col-lg-6">
                    <label class="form-label" for="desligamento" style="width: 100%;">Data Inicial:</label>
                    <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text" [disabled]="associadosHistorico.CodigoSocioCategoriaHist != -1"
                    (bsValueChange)="AlteraData($event , 'DataCriacao');"
                    [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false, showTodayButton: true, todayPosition: 'center', todayButtonLabel: 'Hoje' }"
                    name="DataCriacao" id="DataCriacao" formControlName="DataCriacao" [value]="form.get('DataCriacao')">
                  </span>
                  <span class="col-lg-6">
                    <label class="form-label" for="retorno" style="width: 100%;">Data Encerramento:</label>
                    <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text"
                    (bsValueChange)="AlteraData($event , 'DataEncerramento');"
                    [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false, showTodayButton: true, todayPosition: 'center', todayButtonLabel: 'Hoje' }"
                    name="DataEncerramento" id="DataEncerramento" formControlName="DataEncerramento" [value]="form.get('DataEncerramento')">
                  </span>
              </div>
              <div class="row top1">
                <div class="form-outline">
                  <label class="form-label">Observações diversas:</label>
                  <textarea class="form-control form-control-lg" rows="4" name="Observacoes" id="Observacoes" formControlName="Observacoes" [value]="form.get('Observacoes')"
                    (change)="changeObs();" [value]="form.get('Observacoes')" maxlength="250"></textarea>
                </div>
              </div>
              <div class="top1">
                <div class="table-responsive linha-de-botoes">
                  <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
                  <button type="button" class="btn btn-primary" (click)="Salvar();">Salvar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="offcanvas offcanvas-top h-75" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="container">
    <div class="row d-flex align-items-center justify-content-center h-100">
      <div class="col-lg-6">
        <div class="offcanvas-header">
          <div class="card-title-form-menor">Categoria do Associado
            <button type="button" class="float-end btn-close text-primary customSearchButtons"
              data-bs-dismiss="offcanvas" aria-label="Fechar" containerClass="customClass" tooltip="Fechar Help"
              placement="bottom" [adaptivePosition]="false"></button>
          </div>
        </div>
        <div class="offcanvas-body">
          <div class="row">
            <div class="form-outline">
              <label class="form-label">Categoria</label>
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Categoria ao qual o associado pertence." />
            </div>
          </div>
          <div class="row">
            <span class="col-lg-6">
              <label class="form-label">Data Inicial:</label>
              <input type="text" disabled class="form-control form-control-lg"
              placeholder="Date inicial nesta categoria." />
            </span>
            <span class="col-lg-6">
              <label class="form-label">Data Encerramento:</label>
              <input type="text" disabled class="form-control form-control-lg"
              placeholder="Date final nesta categoria." />
            </span>
        </div>
          <div class="row">
            <div class="form-outline">
              <label class="form-label">Observações diversas</label>
              <textarea type="text" disabled class="form-control form-control-lg" rows="4"
                placeholder="Qualquer observação do associado que seja pertinente a esta categoria."></textarea>
            </div>
          </div>
          <div class="w-100 top1"><button type="button" class="btn btn-warning">Cancelar</button><label
              style="margin-left: 4px; margin-right: 1%;">Retorna para tela que chamou.</label></div>
          <div class="w-100 top1"><button type="button" class="btn btn-primary">Salvar</button><label
              style="margin-left: 4px; margin-right: 1%;">Salva os dados editados/incluídos.</label></div>
         </div>
      </div>
    </div>
  </div>
</div>


<app-footer></app-footer>
