import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';

import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';

import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { Orcamento } from '../models/orcamento';
import { ExcelService } from 'src/app/excel.service';
import OrcamentoService from '../orcamento-service';


@Component({
  selector: 'app-categoria-lista',
  templateUrl: './orcamento-lista.component.html',
  styleUrls: ['./orcamento-lista.component.css'],
})
export class OrcamentoListaComponent implements OnInit {
  constructor(
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private OrcamentoService: OrcamentoService,
    private utilsservice: UtilsService,
    private excelService: ExcelService,
  ) {}

  orcamento: Orcamento[] = [];
  orcamentoOriginal: Orcamento[] = [];
  novoOrcamento: Orcamento = new Orcamento();
  DadosExcell: any = [];
  ColunasExcell: any = [];

  //Variaveis - Detalhes dentro do ngx-datatable
  @ViewChild('myTable') table: any;

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.',
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.BuscaOrcamento();
  }

  BuscaOrcamento(): void {
    this.spinner.show();
    try {
      this.OrcamentoService
        .GetListaGeral()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                response.OrcamentoLista.forEach((item: any) => {
                    const tempList = new Orcamento();
                    tempList.carregar(item);
                    this.orcamentoOriginal.push(tempList);
                  });
            }
            this.orcamento.sort((a: Orcamento, b: Orcamento) => {
                return new Date(b.Data).getTime() - new Date(a.Data).getTime();
            });
            this.orcamento = this.orcamentoOriginal;
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
            this.preencherComValoresAleatorios();
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
      this.preencherComValoresAleatorios();
    }
  }

  // Função para preencher a lista com valores aleatórios para teste
  preencherComValoresAleatorios(): void {
    const randomOrcamentos: Orcamento[] = [];
    for (let i = 0; i < 5; i++) { // Preenche 5 orçamentos fictícios
      const orcamentoAleatorio = new Orcamento();
      orcamentoAleatorio.PlanoConta.DescricaoPlanoConta = `PlanoConta${i + 1}`;
      orcamentoAleatorio.Complemento = `Complemento${i + 1}`;
      orcamentoAleatorio.Fornecedor1.NomeSocio = `99 Pop`;
      orcamentoAleatorio.Fornecedor2.NomeSocio = `Fornecedor2_${i + 1}`;
      orcamentoAleatorio.Fornecedor3.NomeSocio = `Fornecedor3_${i + 1}`;
      orcamentoAleatorio.Data = new Date(
        Date.now() - Math.floor(Math.random() * 10000000000)
      ); // Data aleatória
      orcamentoAleatorio.Valor = (Math.random() * 10000).toString(); // Valor aleatório

      randomOrcamentos.push(orcamentoAleatorio);
    }
    
    this.orcamentoOriginal = randomOrcamentos;
    this.orcamento = this.orcamentoOriginal;
  }
  Edicao(orcamento: Orcamento) {
     this.router.navigateByUrl('/administrativo/orcamento/edicao', { state: { orcamento: orcamento }});
   }

  Adicionar() {
    this.spinner.show();
    const dataAtual = new Date();
    this.novoOrcamento = new Orcamento();
    //this.novaPrevisao.DataPrevisao = dataAtual.getDate();
    //this.novaPrevisao.carregar(dataAtual);
    this.novoOrcamento.IdOrcamento = -1;
    this.spinner.hide();
    this.router.navigateByUrl('/administrativo/orcamento/edicao', {
      state: { orcamento: this.novoOrcamento },
    });
   } 

  //Rotinas - Detalhes dentro do ngx-datatable
  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    // filter our data
    const temp = this.orcamentoOriginal.filter(function (d: any) {
      return (
        d.PlanoConta.DescricaoPlanoConta.toLowerCase().indexOf(val) !== -1 || d.Complemento.toLowerCase().indexOf(val) !== -1
      );
    });
    // update the rows
    this.orcamento = temp;
    return true;
  }

  generateExcell() {
    var nomeArquivo: string = 'RelatorioOrcamento';
    var Titulo: string = 'Relatório Orçamentos';
    this.ColunasExcell = [];
    this.ColunasExcell.push('Plano Conta');
    this.ColunasExcell.push('Complemento');
    this.ColunasExcell.push('Fornecedor 1');
    this.ColunasExcell.push('Fornecedor 2');
    this.ColunasExcell.push('Fornecedor 3');
    this.ColunasExcell.push('Data');
    this.ColunasExcell.push('Valor');
    this.DadosExcell = [];
    this.orcamento.forEach((item: Orcamento) => {
      const varExcell: any = [];
      varExcell.push(item.PlanoConta.DescricaoPlanoConta);
      varExcell.push(item.Complemento);
      varExcell.push(item.Fornecedor1.NomeSocio);
      varExcell.push(item.Fornecedor2.NomeSocio);
      varExcell.push(item.Fornecedor3.NomeSocio);
      varExcell.push(item.Data);
      varExcell.push(item.converterValor(item.Valor));
      this.DadosExcell.push(varExcell);
    });
    this.excelService.generateExcel(
      nomeArquivo,
      Titulo,
      this.ColunasExcell,
      this.DadosExcell
    );
 }
// Função de imprimir
Imprimir(row: Orcamento): void {
    // Formate o conteúdo a ser impresso
    const printContent = `
    <div style="font-family: Arial, sans-serif; width: 100%; text-align: left;">
        <h3 style="text-align: center;">Orçamento</h3>
        <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
            <tr>
                <td><strong>Plano de Contas:</strong></td>
                <td>${row.PlanoConta.DescricaoPlanoConta}</td>
            </tr>
            <tr>
                <td><strong>Complemento:</strong></td>
                <td>${row.Complemento}</td>
            </tr>
            <tr>
                <td><strong>Data Compra:</strong></td>
                <td>${row.converterData()}</td>
            </tr>
            <tr>
                <td><strong>Valor Compra:</strong></td>
                <td>${row.converterValor(row.Valor)}</td>
            </tr>
            <tr>
                <td><strong>Código:</strong></td>
                <td>${row.IdOrcamento}</td>
            </tr>
        </table>
        
        <table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
            <thead>
                <tr style="background-color: #f0f0f0;">
                    <th style="border: 1px solid black; padding: 5px;">Fornecedor</th>
                    <th style="border: 1px solid black; padding: 5px;">Pessoa</th>
                    <th style="border: 1px solid black; padding: 5px;">Fone</th>
                    <th style="border: 1px solid black; padding: 5px;">Valor</th>
                    <th style="border: 1px solid black; padding: 5px;">Forma Pagamento</th>
                    <th style="border: 1px solid black; padding: 5px;">Observações</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="border: 1px solid black; padding: 5px;">${row.Fornecedor1.NomeSocio}</td>
                    <td style="border: 1px solid black; padding: 5px;">${row.Pessoa1}</td>
                    <td style="border: 1px solid black; padding: 5px;">${row.Fone1}</td>
                    <td style="border: 1px solid black; padding: 5px;">${row.converterValor(row.Valor1)}</td>
                    <td style="border: 1px solid black; padding: 5px;"></td>
                    <td style="border: 1px solid black; padding: 5px;">${row.Observacao1}</td>
                </tr>
                <tr>
                    <td style="border: 1px solid black; padding: 5px;">${row.Fornecedor2.NomeSocio}</td>
                    <td style="border: 1px solid black; padding: 5px;">${row.Pessoa2}</td>
                    <td style="border: 1px solid black; padding: 5px;">${row.Fone2}</td>
                    <td style="border: 1px solid black; padding: 5px;">${row.converterValor(row.Valor2)}</td>
                    <td style="border: 1px solid black; padding: 5px;"></td>
                    <td style="border: 1px solid black; padding: 5px;">${row.Observacao2}</td>
                </tr>
                <tr>
                    <td style="border: 1px solid black; padding: 5px;">${row.Fornecedor3.NomeSocio}</td>
                    <td style="border: 1px solid black; padding: 5px;">${row.Pessoa3}</td>
                    <td style="border: 1px solid black; padding: 5px;">${row.Fone3}</td>
                    <td style="border: 1px solid black; padding: 5px;">${row.converterValor(row.Valor3)}</td>
                    <td style="border: 1px solid black; padding: 5px;"></td>
                    <td style="border: 1px solid black; padding: 5px;">${row.Observacao3}</td>
                </tr>
            </tbody>
        </table>
    </div>
    `;

    // Criar um iframe invisível para imprimir
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';

    document.body.appendChild(iframe);

    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
    if (iframeDoc) {
        iframeDoc.open();
        iframeDoc.write(`
        <html>
            <head>
                <title>Imprimir Orçamento</title>
            </head>
            <body>${printContent}</body>
        </html>
        `);
        iframeDoc.close();
        
        // Esperar o conteúdo carregar no iframe e chamar a impressão
        iframe.contentWindow?.focus();
        iframe.contentWindow?.print();
    }

    // Remover o iframe após a impressão
    setTimeout(() => {
        document.body.removeChild(iframe);
    }, 1000);
}
}
