import { Utils } from "src/app/Projeto/Utils/utils";
import { SocioResumo } from "../../associados/models/associados";
import { PlanoConta } from "../../planoconta/models/planoconta";

export class Orcamento {
    IdOrcamento: number;
    PlanoConta: PlanoConta;
    Complemento: string;
    Fornecedor1: SocioResumo;
    Condicao1: string;
    Pessoa1: string;
    Fone1: string;
    Observacao1: string;
    Valor1: string;
    Fornecedor2: SocioResumo;
    Condicao2: string;
    Pessoa2: string;
    Fone2: string;
    Observacao2: string;
    Valor2: string;
    Fornecedor3: SocioResumo  
    Condicao3: string;
    Pessoa3: string;
    Fone3: string;
    Observacao3: string;
    Valor3: string;
    Data: Date;
    Valor: string;
    Situacao: string;

    constructor() {
        this.IdOrcamento = 0;
        this.PlanoConta = new PlanoConta();
        this.Complemento = '';
        this.Fornecedor1 = new SocioResumo();
        this.Fornecedor2 = new SocioResumo();
        this.Fornecedor3 = new SocioResumo();
        this.Data = new Date(); // Inicializa com a data atual
        this.Valor = '';
        this.Situacao = ''; // Inicializa o campo Situacao
        this.Valor1 = '';
        this.Condicao1 = '';
        this.Pessoa1 = '';
        this.Fone1 = '';
        this.Observacao1 = '';
        this.Valor2 = '';
        this.Condicao2 = '';
        this.Pessoa2 = '';
        this.Fone2 = '';
        this.Observacao2 = '';
        this.Valor3 = '';
        this.Condicao3 = '';
        this.Pessoa3 = '';
        this.Fone3 = '';
        this.Observacao3 = '';
    }
    
    carregar(objeto: any): void {
        this.IdOrcamento = objeto.CodigoOrcamento || 0;
        this.PlanoConta = objeto.PlanoConta || new PlanoConta(); 
        this.Complemento = objeto.Complemento || '';
        
        this.Fornecedor1 = objeto.Fornecedor1 || new SocioResumo();
        this.Pessoa1 = objeto.Pessoa1 || '';
        this.Fone1 = objeto.Fone1 || '';
        this.Observacao1 = objeto.Observacao1 || '';
        this.Valor1 = objeto.Valor1 || '';
        this.Condicao1 = objeto.Condicao1 || '';
        
        this.Fornecedor2 = objeto.Fornecedor2 || new SocioResumo();
        this.Pessoa2 = objeto.Pessoa2 || '';
        this.Fone2 = objeto.Fone2 || '';
        this.Observacao2 = objeto.Observacao2 || '';
        this.Valor2 = objeto.Valor2 || '';
        this.Condicao2 = objeto.Condicao2 || '';
        
        this.Fornecedor3 = objeto.Fornecedor3 || new SocioResumo();
        this.Pessoa3 = objeto.Pessoa3 || '';
        this.Fone3 = objeto.Fone3 || '';
        this.Observacao3 = objeto.Observacao3 || '';
        this.Valor3 = objeto.Valor3 || '';
        this.Condicao3 = objeto.Condicao3 || '';
        
        this.Data = objeto.Data ? Utils.getDateTimeUTC(objeto.Data) : new Date(); 
        this.Valor = objeto.Valor || '';
        this.Situacao = objeto.Situacao || 'A'; 
    }
    

    static saoDiferentes(dados1: Orcamento, dados2: Orcamento): boolean {
        return dados1.IdOrcamento !== dados2.IdOrcamento ||
               dados1.PlanoConta.IdPlanoConta !== dados2.PlanoConta.IdPlanoConta ||
               dados1.Complemento !== dados2.Complemento ||
               dados1.Fornecedor1.CodigoSocio !== dados2.Fornecedor1.CodigoSocio ||
               dados1.Pessoa1 !== dados2.Pessoa1 ||
               dados1.Fone1 !== dados2.Fone1 ||
               dados1.Observacao1 !== dados2.Observacao1 ||
               dados1.Valor1 !== dados2.Valor1 ||
               dados1.Condicao1 !== dados2.Condicao1 ||
               dados1.Fornecedor2.CodigoSocio !== dados2.Fornecedor2.CodigoSocio ||
               dados1.Pessoa2 !== dados2.Pessoa2 ||
               dados1.Fone2 !== dados2.Fone2 ||
               dados1.Observacao2 !== dados2.Observacao2 ||
               dados1.Valor2 !== dados2.Valor2 ||
               dados1.Condicao2 !== dados2.Condicao2 ||
               dados1.Fornecedor3.CodigoSocio !== dados2.Fornecedor3.CodigoSocio ||
               dados1.Pessoa3 !== dados2.Pessoa3 ||
               dados1.Fone3 !== dados2.Fone3 ||
               dados1.Observacao3 !== dados2.Observacao3 ||
               dados1.Valor3 !== dados2.Valor3 ||
               dados1.Condicao3 !== dados2.Condicao3 ||
               dados1.Data.getTime() !== dados2.Data.getTime() ||  // Usando getTime para comparar datas
               dados1.Valor !== dados2.Valor ||
               dados1.Situacao !== dados2.Situacao;
    }
    

    converterData(): string {
        const data = new Date(this.Data);
        const dia = String(data.getDate()).padStart(2, '0');
        const mes = String(data.getMonth() + 1).padStart(2, '0');
        const ano = data.getFullYear();
        return `${dia}/${mes}/${ano}`;
    } 
    converterValor(PValor: string) {
        const valor = parseFloat(PValor);
        return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    toJson(): any {
        return {
            IdOrcamento: this.IdOrcamento,
            PlanoConta: this.PlanoConta,
            Complemento: this.Complemento,
            Fornecedor1: this.Fornecedor1,
            Fornecedor2: this.Fornecedor2,
            Fornecedor3: this.Fornecedor3,
            Data: this.Data,
            Valor: this.Valor,
            Situacao: this.Situacao,
            Valor1: this.Valor1,
            Condicao1: this.Condicao1,
            Pessoa1: this.Pessoa1,
            Fone1: this.Fone1,
            Observacao1: this.Observacao1,
            Valor2: this.Valor2,
            Condicao2: this.Condicao2,
            Pessoa2: this.Pessoa2,
            Fone2: this.Fone2,
            Observacao2: this.Observacao2,
            Valor3: this.Valor3,
            Condicao3: this.Condicao3,
            Pessoa3: this.Pessoa3,
            Fone3: this.Fone3,
            Observacao3: this.Observacao3,
        };
    }
    
    toDict(orcamentoOriginal: Orcamento[]): any {
        return orcamentoOriginal.map(orcamento => ({
            IdOrcamento: orcamento.IdOrcamento,
            PlanoConta: orcamento.PlanoConta,
            Complemento: orcamento.Complemento,
            Fornecedor1: orcamento.Fornecedor1,
            Fornecedor2: orcamento.Fornecedor2,
            Fornecedor3: orcamento.Fornecedor3,
            Data: orcamento.Data,
            Valor: orcamento.Valor,
            Situacao: orcamento.Situacao,
            Valor1: orcamento.Valor1,
            Condicao1: orcamento.Condicao1,
            Pessoa1: orcamento.Pessoa1,
            Fone1: orcamento.Fone1,
            Observacao1: orcamento.Observacao1,
            Valor2: orcamento.Valor2,
            Condicao2: orcamento.Condicao2,
            Pessoa2: orcamento.Pessoa2,
            Fone2: orcamento.Fone2,
            Observacao2: orcamento.Observacao2,
            Valor3: orcamento.Valor3,
            Condicao3: orcamento.Condicao3,
            Pessoa3: orcamento.Pessoa3,
            Fone3: orcamento.Fone3,
            Observacao3: orcamento.Observacao3,
        }));
    }
 
}  
