<app-header></app-header>

<div class="content-wrapper">
    <div class="container-fluid">
        <div class="container">
            <div class="row d-flex align-items-center justify-content-center h-100">
                <div class="col-lg-12">
                    <div class="card-title-form">{{ tipoAtividade }}
                        <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Adicionar"
                            placement="top" [adaptivePosition]="false">                            
                            <button type="button" class="btn btn-sm btn-warning float-end" (click)="Adicionar();">
                                <i class="fa-solid fa-plus icone-para-botao"></i>Adicionar</button>
                        </span>
                    </div>

                    <div style="margin-top: 0.5%;margin-bottom: 0.5%;" class="row w-100">
                        <span class="col-lg-8">
                          <input type="text" #search id="search" class="form-control form-control-lg "
                            placeholder="Pesquisar Seminário na listagem abaixo..."
                            (keyup)="updateFilter($event)" />
                        </span>

                        <span style="margin-top: 0.5%;margin-bottom: 0.5%;" class="col-lg-4">
                          <button type="button" class="btn btn-sm btn-warning float-end" containerClass="customClass" tooltip="Exporta para o Excel os dados consultados abaixo." placement="top"
                          [adaptivePosition]="false" (click)="generateExcell();"><i class="fa-solid fa-file-excel icone-para-botao" ></i>Exportar Excel</button>
                        </span>
                      </div>
                    <div class="card-body-form">
                        <ngx-datatable #myTable class="w-100 bootstrap ngx-datatable" [rows]="atividade"
                            [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                            [scrollbarH]="false" [scrollbarV]="false" [limit]="5"  [messages]="my_messages"
                            [sorts]="[{prop: 'Ordenacao', dir: 'desc'}]">

                            <ngx-datatable-column prop="Seminario" [draggable]="false" [flexGrow]="2">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Atividade</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.Seminario}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="Titulo" [draggable]="false" [flexGrow]="1.8">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Título</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.Titulo}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="PalavraChave" [draggable]="false" [flexGrow]="2.4">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Palavra Chave</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.PalavraChave}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="Coordenacao" [draggable]="false" [flexGrow]="2.2">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Coordenação</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.Coordenacao}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="Situacao" [draggable]="false" [flexGrow]="1.8">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Situação</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.Situacao}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="Periodo" [draggable]="false" [flexGrow]="1.8">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Período</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.converterPeriodo()}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="Valor" [draggable]="false" [flexGrow]="2">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Valor</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.converterValor()}}</span>
                                </ng-template>
                            </ngx-datatable-column>
                           <!--
                            <ngx-datatable-column prop="Certificado" [draggable]="false" [flexGrow]="2">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Certificado</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.Certificado}}</span>
                                </ng-template>
                            </ngx-datatable-column>
                            -->         
                            <ngx-datatable-column prop="PossuiAnexos" [draggable]="false" [flexGrow]="2.2">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Possui Anexos</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.PossuiAnexos}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="Outros" [draggable]="false" [flexGrow]="1.5">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Obs.:</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span>{{row.ObservacaoGeral}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Ações -->
                            <ngx-datatable-column name="Ações" [draggable]="false" [flexGrow]="1.5" [sortable]="false"
                                [cellClass]="'ngx-direita'" [headerClass]="'ngx-direita'">
                                <ng-template ngx-datatable-header-template>
                                    <span class="ngx-datatable-cabecalho">Ações</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    
                                    <span class="iconeTamanho" containerClass="customClass" tooltip="Gerar Certificado" placement="top right"
                                        [adaptivePosition]="false" (click)="gerarCertificado(row);">
                                        <i class="fa-solid fa-certificate"></i>
                                    </span>

                                    <span class="iconeTamanho" containerClass="customClass" tooltip="Edição" placement="top right"
                                    [adaptivePosition]="false" (click)="Edicao(row);">
                                    <i class="fa-solid fa-pen-to-square"></i>
                                </span>

                                </ng-template>
                            </ngx-datatable-column>
<!--                            <ngx-datatable-footer #tFooter (onFooterPage)="handleFooter($event)">
                                <ng-template ngx-datatable-footer-template let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
                                  <span>
                                     {{totalLinhas}} linhas encontradas
                                  </span>
                                  <datatable-pager
                                    (onFooterPage)="handleFooter($event)"
                                    (change)="handleFooter($event)"
                                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                                    [pagerRightArrowIcon]="'datatable-icon-right'"
                                    [pagerPreviousIcon]="'datatable-icon-prev'"
                                    [pagerNextIcon]="'datatable-icon-skip'"
                                    [page]="curPage"
                                    [size]="pageSize"
                                    [count]="totalLinhas"
                                  ></datatable-pager>
                                </ng-template>
                              </ngx-datatable-footer> -->
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>  
    </div>
    </div>
    

<app-footer></app-footer>
