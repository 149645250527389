
export class TipoLancamento {
    CodigoTipoLancamento: number;
    NomeTipoLancamento: string;
    TaxaTipoLancamento: number;
    TaxaFixaTipoLancamento: number;
    
    constructor() {
        this.CodigoTipoLancamento = 0;
        this.NomeTipoLancamento = '';
        this.TaxaTipoLancamento = 0;
        this.TaxaFixaTipoLancamento = 0;
    }

    carregar(objeto: any): void {
        this.CodigoTipoLancamento = objeto.CodigoTipoLancamento || 0;
        this.NomeTipoLancamento = objeto.NomeTipoLancamento || '';
        this.TaxaTipoLancamento = objeto.TaxaTipoLancamento || 0;
        this.TaxaFixaTipoLancamento = objeto.TaxaFixaTipoLancamento || 0;
    }

    toJson(): any {
        return {
            CodigoTipoLancamento: this.CodigoTipoLancamento,
            NomeTipoLancamento: this.NomeTipoLancamento,
            TaxaTipoLancamento: this.TaxaTipoLancamento,
            TaxaFixaTipoLancamento: this.TaxaFixaTipoLancamento,
        };
    }

    static saoDiferentes(objeto1: TipoLancamento, objeto2: TipoLancamento): boolean {
        return objeto1.CodigoTipoLancamento !== objeto2.CodigoTipoLancamento ||
               objeto1.NomeTipoLancamento !== objeto2.NomeTipoLancamento ||
               objeto1.TaxaTipoLancamento !== objeto2.TaxaTipoLancamento ||
               objeto1.TaxaFixaTipoLancamento !== objeto2.TaxaFixaTipoLancamento;
    }
}
