import { Component, OnInit, ViewChild, Input, ChangeDetectorRef  } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';

import { MessageService } from '../../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';

import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { Inscricao } from '../models/inscricao';
import { ExcelService } from 'src/app/excel.service';
import InscricaoService from '../inscricao-service';
import { DataTableFooterComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-inscricao-lista',
  templateUrl: './inscricao-lista.component.html',
  styleUrls: ['./inscricao-lista.component.css'],
})

export class InscricaoListaComponent implements OnInit {
  constructor(
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private InscricaoService: InscricaoService,
    private utilsservice: UtilsService,
    private excelService: ExcelService,
    private cdRef: ChangeDetectorRef
  ) {}
  
  @Input() origemSubTela: number = 0;
  inscricao: Inscricao[] = [];
  inscricaoOriginal: Inscricao[] = [];
  novaInscricao: Inscricao = new Inscricao();
  indexPagina: number = 1;
  totalLinhas: number = 0;
  @ViewChild('tFooter', { static: false }) tFooter: DataTableFooterComponent;
  //Variaveis - Detalhes dentro do ngx-datatable
  @ViewChild('myTable') table: any;

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.'
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.BuscaInscricao();
        
  }
  preencherComValoresAleatorios(): void {
    const randomInscricoes: Inscricao[] = [];
    for (let i = 0; i < 5; i++) { // Preenche 5 orçamentos fictícios
      const inscricaoAleatoria = new Inscricao();
      inscricaoAleatoria.Nome = `Nome${i + 1}`;
      inscricaoAleatoria.Situacao = `Situacao${i + 1}`;
      randomInscricoes.push(inscricaoAleatoria);
    }
    
    this.inscricaoOriginal = randomInscricoes;
    this.inscricao = this.inscricaoOriginal;
  }
  BuscaInscricao(): void {
    this.spinner.show();
    this.inscricaoOriginal = [];
    try {
      this.InscricaoService
        .GetListaGeral(this.indexPagina, 0, 0)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                console.log(response)
                console.log(response)
                
                response.InscricaoLista.forEach((item: any) => {
                    const tempList = new Inscricao();
                    tempList.carregar(item);
                    this.inscricaoOriginal.push(tempList);
                  });
            }
            this.inscricao = this.inscricaoOriginal;
            //this.my_messages.totalMessage = `${this.GetTotalLinhas()} linhas encontradas.`
            this.my_messages.totalMessage = ` linhas encontradas.`
          },
          error: (error) => {
            this.spinner.hide();
            //this.utilsservice.showHttpError(error);
            console.log("retirar o comentario do alerta")
            this.preencherComValoresAleatorios();
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }
  
  Edicao(inscricao: Inscricao) {
     this.router.navigateByUrl('/atividade/inscricao/edicao', { state: { inscricao: inscricao }});
   }

  Adicionar() {
    this.novaInscricao = new Inscricao();
    this.novaInscricao.Nome = '';
    this.router.navigateByUrl('/atividade/inscricao/edicao', {
      state: { inscricao: this.novaInscricao },
    });
   } 
   marcarFalta(inscricao: Inscricao) {
    console.log("Marcar falta  aqui")
  }
   registrarDesistencia(inscricao: Inscricao) {
    console.log("Registra desistencia aqui")
  }
   adicionarParecer(inscricao: Inscricao) {
    console.log("Adicionar parecer aqui")
  }
   gerarCertificado(inscricao: Inscricao) {
    console.log("Gerar certificado aqui")
  }

  //Rotinas - Detalhes dentro do ngx-datatable
  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    const temp = this.inscricaoOriginal.filter(function (d: any) {
      return (
        d.Nome.toLowerCase().indexOf(val) !== -1 || d.Nome.toLowerCase().indexOf(val) !== -1
      );
    });
    // update the rows
    this.inscricao = temp;
    return true;
  }
  

}
