<div class="content-wrapper">
  <div class="container-fluid">
    <div class="card-body-form">
      <div class="table-responsive table-responsive-overflow-450">
        <form [formGroup]="form">

          <div class="row">
            <span class="card-title-form-menor-FB" style="padding-left: 14px;">Endereço de Cobrança/Principal</span>
            <div class="row">
              <span class="col-lg-1">
                <label class="form-label">CEP:</label>
                <input type="text" class="form-control form-control-lg" mask="00000-000" id="CepSocio" name="CepSocio" formControlName="CepSocio" [value]="form.get('CepSocio')"
                (change)="changeCEP();" maxlength="8"
                (focusout)="onFocusOutCEP($event,'P')">
              </span>
              <span class="col-lg-3">
                <label class="form-label">Endereço:</label>
                <input type="text" class="form-control form-control-lg" id="EnderecoSocio" name="EnderecoSocio" formControlName="EnderecoSocio" [value]="form.get('EnderecoSocio')"
                (change)="changeEndereco();" maxlength="120">
              </span>
              <span class="col-lg-1">
                <label class="form-label">Complemento:</label>
                <input type="text" class="form-control form-control-lg" id="ComplementoSocio" name="ComplementoSocio" formControlName="ComplementoSocio" [value]="form.get('ComplementoSocio')"
                (change)="changeCompl();" maxlength="40">
              </span>
              <span class="col-lg-3">
                <label class="form-label">Bairro:</label>
                <input type="text" class="form-control form-control-lg" id="BairroSocio" name="BairroSocio" formControlName="BairroSocio" [value]="form.get('BairroSocio')"
                (change)="changeBairro();" maxlength="40">
              </span>
              <span class="col-lg-2">
                <label class="form-label">Cidade:</label>
                <input type="text" class="form-control form-control-lg" id="CidadeSocio" name="CidadeSocio" formControlName="CidadeSocio" [value]="form.get('CidadeSocio')"
                (change)="changeCidade();" maxlength="40">
              </span>
              <span class="col-lg-1">
                <label class="form-label">UF:</label>
                <input type="text" class="form-control form-control-lg" id="UFSocio" name="UFSocio" formControlName="UFSocio" [value]="form.get('UFSocio')"
                (change)="changeUF();" maxlength="2">
              </span>
              <span class="col-lg-1">
                <label class="form-label">Tipo:</label>
                <ngx-select-dropdown name="TipoEnderecoSocioDescr" id="TipoEnderecoSocioDescr" formControlName="TipoEnderecoSocioDescr"
                  (change)="changeTipoEnd($event)" maxlength="1"
                  [config]="configTipo" [options]="TipoResidencia" class="form-control form-control-lg form_control_ngx_select_dropdown">
                </ngx-select-dropdown>
              </span>
            </div>
          </div>

          <div class="row top02">
            <span class="card-title-form-menor-FB" style="padding-left: 14px;">Endereço Secundário</span>
            <div class="row">
              <span class="col-lg-1">
                <label class="form-label">CEP:</label>
                <input type="text" class="form-control form-control-lg" mask="00000-000" id="CepSocio2" name="CepSocio2" formControlName="CepSocio2" [value]="form.get('CepSocio2')"
                  (change)="changeCEP2();" maxlength="8"
                  (focusout)="onFocusOutCEP($event,'S')">
              </span>
              <span class="col-lg-3">
                <label class="form-label">Endereço:</label>
                <input type="text" class="form-control form-control-lg" id="EnderecoSocio2" name="EnderecoSocio2" formControlName="EnderecoSocio2" [value]="form.get('EnderecoSocio2')"
                (change)="changeEndereco2();" maxlength="120">
              </span>
              <span class="col-lg-1">
                <label class="form-label">Complemento:</label>
                <input type="text" class="form-control form-control-lg" id="ComplementoSocio2" name="ComplementoSocio2" formControlName="ComplementoSocio2" [value]="form.get('ComplementoSocio2')"
                (change)="changeCompl2();" maxlength="40">
              </span>
              <span class="col-lg-3">
                <label class="form-label">Bairro:</label>
                <input type="text" class="form-control form-control-lg" id="BairroSocio2" name="BairroSocio2" formControlName="BairroSocio2" [value]="form.get('BairroSocio2')"
                (change)="changeBairro2();" maxlength="40">
              </span>
              <span class="col-lg-2">
                <label class="form-label">Cidade:</label>
                <input type="text" class="form-control form-control-lg" id="CidadeSocio2" name="CidadeSocio2" formControlName="CidadeSocio2" [value]="form.get('CidadeSocio2')"
                (change)="changeCidade2();" maxlength="40">
              </span>
              <span class="col-lg-1">
                <label class="form-label">UF:</label>
                <input type="text" class="form-control form-control-lg" id="UFSocio2" name="UFSocio2" formControlName="UFSocio2" [value]="form.get('UFSocio2')"
                (change)="changeUF2();" maxlength="2">
              </span>
              <span class="col-lg-1">
                <label class="form-label">Tipo:</label>
                <ngx-select-dropdown name="TipoEnderecoSocioDescr2" id="TipoEnderecoSocioDescr2" formControlName="TipoEnderecoSocioDescr2"
                  (change)="changeTipoEnd2($event)" maxlength="1"
                  [config]="configTipo" [options]="TipoResidencia" class="form_control_ngx_select_dropdown">
                </ngx-select-dropdown>
              </span>
            </div>
          </div>

          <div class="row top02">
            <span class="card-title-form-menor-FB" style="padding-left: 14px;">Informações Adicionais</span>
            <span class="col-lg-6">
              <label class="form-label">Encaminhado Por:</label>
              <app-associadotodos-pesquisa name="CodigoSocioEnc" id="CodigoSocioEnc" formControlName="CodigoSocioEnc" [CodigoSocioPreench]="this.associado.CodigoSocioEnc" (AssociadoOut)="changeAssociado($event);">
              </app-associadotodos-pesquisa>
            </span>
            <span class="col-lg-6">
              <div class="w-100">
                <div class="form-label">Arquivo Assinatura</div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <file-upload [control]="control">
                    <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
                        <div *ngIf="control.size === 0">
                            <div class="icon">
                              <i class="fa-solid fa-image"></i>
                            </div>
                            <div class="upload-text">
                                <ng-container *ngIf="isFileDragDropAvailable; else isNotDragDropAvailable">
                                  <b>Arraste e solte a imagem da assinatura ou...</b><br> clique aqui e selecione
                                </ng-container>
                                <ng-template #isNotDragDropAvailable>
                                    <b>Click aqui</b> para<br> escolher a imagem da assinatura
                                </ng-template>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template let-i="index" let-file="file" let-control="control" #item >
                        <div class="overlay">
                            <span class="delete-button" (click)="control.removeFile(file)">
                              <i class="fa-solid fa-trash"></i>
                            </span>
                            <img *ngIf="control.valid" [src]="uploadedFile | async" >
                        </div>
                    </ng-template>
                  </file-upload>
                  <div>
                    <label class="form-label">
                      <input type="radio" name="tipo" value="PR"  (change)="ChangeTipoSocio($event)" [checked]="this.associado.TipoSocio == 'PR'"> Presidente
                    </label>
                    <label class="form-label" style="padding-left: 5%;">
                      <input type="radio" name="tipo" value="EN"  (change)="ChangeTipoSocio($event)" [checked]="this.associado.TipoSocio == 'EN'"> Diretora Ensino
                    </label>
                    <label class="form-label" style="padding-left: 5%;">
                      <input type="radio" name="tipo" value="CI"  (change)="ChangeTipoSocio($event)" [checked]="this.associado.TipoSocio == 'CI'"> Diretora Científico
                    </label>
                  </div>
                </div>
              </div>
            </span>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
